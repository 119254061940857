import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { PrintLeaguesRes, League, Market, Match } from 'src/app/source/modules/common/printfeedleagues';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
//import {MarketTitle, MatchHeadder, Headder, Datum } from 'src/app/source/modules/common/printfeedodds';
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import { LoginService } from 'src/app/source/services/login/login.service';
declare var $: any;

$('#container table thead tr th:gt(3)').wrapAll('<table>').parent().appendTo('#container table');
@Component({
  selector: 'app-printfeed',
  templateUrl: './printfeed.component.html',
  styleUrls: ['./printfeed.component.css'],
  providers: [
    { provide: 'Window',  useValue: window }
  ]
})
export class PrintfeedComponent implements OnInit {
  leaguestoreSub: Subscription;
  marketsstoreSub: Subscription;
  errorMessage: string;
  leaguesdata: League[];
  marketsdata: Market[];
  leagueForm: FormGroup;
  marketForm: FormGroup;
  oddtype: object;
  selectedId: any;
  oddserrorMessage: string;
  showleagues: boolean = true;
  showmarkets: boolean = false;
  printfeedData: any;
  printmatchdata: any;
  sportname: any;
  //headerdata: Headder[];
  //marketsdata_titles: MarketTitle[];
  //matchheader: MatchHeadder;
  //matchtitile: any;
  // matchdatedata: Datum[];
  loaderdata: boolean = true;
  sendloader: boolean = false;
  printbuttonshow: boolean = false;
  printShowMessage:string;
  // itemNew = [    
  //   { id: 'Case Number', name : '101111111' },
  //   { id: 'Patient Name', name : 'UAT DR' },
  //   { id: 'Hospital Name', name: 'Dr Abcd' }    
  //   ]

  constructor(private loginservice: LoginService, private fb: FormBuilder, private router: Router, private store: Store<appState.AppState>) {
    // this.sportname = sessionStorage.getItem('SportName');
    // if (this.sportname) {
    //   this.store.dispatch(new loginActions.PrintFeedOfleagues(this.sportname));
    // } else {
      this.store.dispatch(new loginActions.PrintFeedOfleagues('Soccer'));
    //}
  }

  ngOnInit() {

    $(document).ready(function(){
      $('.league_top').on('change', function() {     
        $('.checkbox').prop('checked', $(this).prop("checked"));              
});
    });
    this.leagueForm = this.fb.group({
      matchId: this.fb.array([])
    })
    this.marketForm = this.fb.group({
      market: this.fb.array([])
    })
    this.leaguestoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.printleaguesRes) {
          if (loginState.printleaguesRes.Status == 200) {
            this.loaderdata=false;
            this.leaguesdata = loginState.printleaguesRes.data.leagues;
            this.marketsdata = loginState.printleaguesRes.data.markets;
            this.errorMessage = "";
          } else {
            this.loaderdata=false;
            this.errorMessage = loginState.printleaguesRes.message;
          }
        }
        if (loginState.printleaguesErrorRes) {
          this.loaderdata=false;
          this.errorMessage = loginState.printleaguesErrorRes.message;
        }
      }
    )
  //   this.marketsstoreSub = this.store.select("loginState").subscribe(
  //     (loginState: LoginState) => {
  //       if (loginState.printfeedoddsres) {
  //         if (loginState.printfeedoddsres.Status == 200) {
  //           this.sendloader=false;
  //           this.printbuttonshow=true;
  //           this.headerdata = loginState.printfeedoddsres.headder;
  //           this.sportname = loginState.printfeedoddsres.sport;
  //           this.marketsdata_titles = loginState.printfeedoddsres.market_titles;
  //           this.matchheader = loginState.printfeedoddsres.match_headder;
  //           this.matchtitile = this.matchheader.title;
  //           this.matchdatedata = loginState.printfeedoddsres.data;
  //           this.oddserrorMessage = "";
  //         } else {
  //           this.sendloader=false;
  //           this.oddserrorMessage = loginState.printfeedoddsres.message;
  //         }
  //       }
  //       if (loginState.printfeedoddsErrorRes) {
  //         this.sendloader=false;
  //         this.oddserrorMessage = loginState.printleaguesErrorRes.message;
  //       }
  //     }
  //   )
  }


  leagueonChange(matchId: any, isChecked: boolean) {
    const leagueIdFormArray = <FormArray>this.leagueForm.controls.matchId;
    if (isChecked) {
      leagueIdFormArray.push(new FormControl(matchId));

    } else {
      let index = leagueIdFormArray.controls.findIndex(x => x.value == matchId)
      leagueIdFormArray.removeAt(index);
    }
    this.selectedId = this.leagueForm.value.matchId;
     //let selecteddata = this.leagueForm.value.length;
    // this.selectedId = Object.assign({}, selecteddata)
  }

  marketOnChange(market: any, isChecked: boolean) {
    const typeFormArray = <FormArray>this.marketForm.controls.market;
    if (isChecked) {
      typeFormArray.push(new FormControl(market));
    } else {
      let index = typeFormArray.controls.findIndex(x => x.value == market)
      typeFormArray.removeAt(index);
    }
    // let selectedoddtype = this.marketForm.value;
    // this.oddtype = Object.assign({}, selectedoddtype)
    // console.log( this.oddtype);
    this.oddtype = this.marketForm.value.market;
    console.log(this.oddtype);
  }
  showmarketsdata() {
      this.showleagues = false;
      this.showmarkets = true;
  }
  generatePdf() {
    //this.printbuttonshow=false;
    this.sendloader=true;
    const body = {
      matchIds: this.selectedId,
      markets: this.oddtype
    }
    this.loginservice.printFeedOddsData(body).subscribe(pdffileopen => this.printpdf(pdffileopen),
    err => {
      this.printShowMessage = "No Data Found";
     
    this.sendloader=false; 
    });
    //this.store.dispatch(new loginActions.PrintFeedOfOdds(body));
  }
  printpdf(pdffileopen){
    this.sendloader=false;
    if(pdffileopen.status == 500){
      this.printShowMessage =  "No Data Found";
    }else{
      
      this.printShowMessage = null;
    const blob = new Blob([pdffileopen], { type: 'application/pdf'});
    const url = window.URL.createObjectURL(blob);
    var link = document.createElement('a');
    link.href = url;
    // link.download = "help.pdf";
    // link.click();
     window.open(link.href);
    }
  }
}
