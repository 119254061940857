import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '@app/env';
import {BetHistory} from '../modules/BetHistory/bet-history';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class BethistoryService {
  constructor(private httpClient:HttpClient) { }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'siteid': environment.skinId,
      'Authorization': "Bearer " + sessionStorage.getItem('accessToken'),
    })
  };
  // getBetHistory(betData) : Observable<BetHistory>{
  //   return this.httpClient.post<BetHistory>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.history}`,betData, this.httpOptions);
  // }
}
