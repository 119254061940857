import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as resultActions from 'src/app/source/appstates/betresult/betresultAction';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import {betResultState} from 'src/app/source/appstates/betresult/betresultState'; 
import {Results, Result, resultForm} from 'src/app/source/modules/betresults/results';
import * as moment from 'moment';
import { CommondataService } from 'src/app/source/services/sports/commondata.service';
import { SportsMenu, Menu } from 'src/app/source/modules/specific/sportsmenu';
import { sideSportService } from 'src/app/source/services/sports/sportMenu.service';
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.css']
})
export class ResultsComponent implements OnInit {
  form: FormGroup;
  sdate:Date ;
  today:Date;
  edate:Date ;
  sport : SportsMenu;
  sportName : Array<Menu>;
  selecteddata:any;
  undefiend:any;
  afteraddweek:any;
  p:number = 1;
  startdateweek:any;
  resultForm:resultForm;
  enddateweek:any;
  defaultSport:string ="soccer";
  selectnum:number = 5;
  descending:boolean;
  resultData:boolean=false;
  private Results: Results;
  result : Array<Result>;
  errorMessage : string;
  private resulttoreSub: Subscription;
  // sportName = [
  //   { sport : 'Soccer' },
  //   { sport : 'Tennis'},
  //   { sport : 'Basketball'}
  // ];
  
  itemsperpagecount = [
    {num :5},
    {num :10},
    {num :15},
    {num :20},
    {num :25}
  ];
  myDateValue: Date;
  constructor(private leagueService: sideSportService, private commonSevice: CommondataService, private store: Store<appState.AppState>) { 
    // defineLocale('bb', itLocale);
    // this.localeService.use('bb');
    //this.dpConfig.containerClass = 'theme-dark-white'; 
    //this.commonSevice.Issportsget.subscribe(value => { this.sportsdata(value) });
    leagueService.getNoOfMatches().subscribe(data => this.sportsdata(data));
  }
  ngOnInit() {
    
    this.myDateValue = new Date();
  this.today = new Date();
  this.sdate = new Date();
  this.edate = new Date();
    this.form = new FormGroup({
      "sdate": new FormControl('', Validators.required ),
      "edate": new FormControl('', Validators.required),
      "sport": new FormControl('',  Validators.required),
  })
  this.resulttoreSub = this.store.select("betResultState").subscribe(
    (betResultState: betResultState) => {
      if (betResultState.Results) {
        this.Results = betResultState.Results;
        this.result =  this.Results.data;
        if(this.Results.status == 200)
        {
        this.resultData=true;
        }else{
          this.resultData=false;
          this.errorMessage = this.Results.Message;

        }
      }
    }
  )
  const body: resultForm = {
    sdate:  this.sdate,
    edate: this.edate,
    sport:this.defaultSport
  }
  
  //this.store.dispatch(new resultActions.sportBookGetResults(body));
  }
  sportsdata(data){
    this.sport = data;
    console.log(this.sport)
    this.sportName = this.sport.data;
  }
  sdateupdate(startdate){
    this.afteraddweek = moment(startdate).add(+7, 'days').format('DD-MM-YYYY');
    if(this.afteraddweek > this.today){
          this.startdateweek = this.today;
    }else{
      this.startdateweek = this.afteraddweek;
    } 
  }
  //'YYYY-MM-DD'

  //DD/MM/YYYY
  edateupdate(enddate){
    this.enddateweek = moment().format('DD-MM-YYYY');
    
  }

  onChange(data){
    this.selectnum = data;
  }
  OnSubmit() {
    
    this.form.value.sdate = moment(this.form.value.sdate).format('YYYY-MM-DD');
    this.form.value.edate= moment(this.form.value.edate).format('YYYY-MM-DD');
      this.store.dispatch(new resultActions.sportBookGetResults(this.form.value));
    }
}
