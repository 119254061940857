import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { PlayerGetGameHistory, PlayerGetRemoteGameHistory } from 'src/app/source/appstates/playerstates/playerActions';
import * as moment from 'moment';
//import { GameHistory } from 'src/app/source/modules/player/gameHistory';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { RemoteGamesHistory } from 'src/app/source/modules/remotegamehistory/remotegamehistory';
import { SlotsService } from 'src/app/source/services/slots.service';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';

@Component({
  selector: 'app-game-history',
  templateUrl: './game-history.component.html',
  styleUrls: ['./game-history.component.css'],
})
export class GameHistoryComponent implements OnInit {
  private profilestoreSub: Subscription;
  private profileRemotestoreSub: Subscription;
  gamehistory: any;
 //gameTypes = ["POKER_HOLDEMNOLIMIT_GHS,HOLDEM,NOLIMIT", "POKER_HOLDEMLIMIT_GHS,HOLDEM,LIMIT"]
  form: FormGroup;
  remoteform:FormGroup;
 gameHistoryErrorMessage:string="";
  profile:string;
  startDate:Date;
  endDate:Date;
  RemoteGamesHistory:RemoteGamesHistory;
  remotehistory:any;
  totaldata:number;
  description:string;
  allSlotsList: RootObject;
  topSlotsList: RootObject;
  tableSlotsList: RootObject;
  allSlotsGames: Array<Game>;
  topSlotsGames: Array<Game>;
  tableSlotsGames: Array<Game>;
  p:number = 1;
  n:number = 1;
  itemsperpagecount = [
    {num :5},
    {num :10},
    {num :15},
    {num :20},
    {num :25}
  ];
  totalrecords:number;
  remoteGameHistoryErrorMessage:string;
  descriptiondata:string;
  gameName=["BLACKJACK", "BACCARAT", "EUROPEANROULETTE", "OASISPOKER"];
  constructor(private slotsservice: SlotsService, private store: Store<appState.AppState>) {
    
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.slotsservice.AllSlotsJson().subscribe(allSlots => { this.allSlotsGamesList(allSlots) });
    this.slotsservice.TopSlotsJson().subscribe(topSlots => {this.topSlotsGamesList(topSlots)});
    this.slotsservice.TableGamesJson().subscribe(tableSlots => {this.tableSlotsGamesList(tableSlots)});
   }

  ngOnInit() {
    
    this.profile = sessionStorage.getItem('profile');
    this.endDate = new Date();
    this.startDate =  new Date();
    this.form = new FormGroup({
      "currency": new FormControl(sessionStorage.getItem('PlayerCurrency'), Validators.required),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "name": new FormControl(null, Validators.required),
      "limit": new FormControl("20", Validators.required),
      "index": new FormControl("0", Validators.required),
      // "types": new FormControl(null) 
    });
    this.remoteform = new FormGroup({
      "currency": new FormControl(sessionStorage.getItem('PlayerCurrency'), Validators.required),
      "startDate": new FormControl(null, Validators.required),
      "endDate": new FormControl(null, Validators.required),
      "name": new FormControl(null, Validators.required),
      "limit": new FormControl("20", Validators.required),
      "index": new FormControl("0", Validators.required),
      // "types": new FormControl(null) 
    });

    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.GameHistory) {
          if (playerState.GameHistory.success == true) {
            this.totaldata = playerState.GameHistory.total;
            this.gamehistory = playerState.GameHistory.values;
            this.description = "No Records Found";
            this.gameHistoryErrorMessage=null;
          }else if(playerState.GameHistory.success==false){
            this.gamehistory=null;
            this.description = null;
            this.gameHistoryErrorMessage = playerState.GameHistory.description;
          }
        }
        if(playerState.GameHistoryError){
          this.gamehistory=null;
          this.description = null;
          this.gameHistoryErrorMessage = playerState.GameHistoryError.message;
        }
      }
    );
    this.profileRemotestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.remotegameshistory) {
          if (playerState.remotegameshistory.success == true) {
            this.totalrecords= playerState.remotegameshistory.total;
            this.remotehistory = playerState.remotegameshistory.values;
            this.description = "No Records Found";
            this.remoteGameHistoryErrorMessage=null;
          }else if(playerState.remotegameshistory.success==false){
            this.remotehistory=null;
            this.description = null;
            this.remoteGameHistoryErrorMessage = playerState.remotegameshistory.description;
          }
        }
        if(playerState.remotegameshistoryError){
          this.remotehistory=null;
          this.description = null;
          this.remoteGameHistoryErrorMessage = playerState.remotegameshistoryError.message;
        }
      }
    )
  }
  allSlotsGamesList(allSlots){
    this.allSlotsList = allSlots;
    if (this.allSlotsList) {
      this.allSlotsGames = this.allSlotsList.Games;
    }
  }
  topSlotsGamesList(topSlots){
    this.topSlotsList = topSlots;
    if (this.topSlotsList) {
      this.topSlotsGames = this.topSlotsList.Games;
    }
  }
  tableSlotsGamesList(tableSlots){
    this.tableSlotsList = tableSlots;
    if (this.tableSlotsList) {
      this.tableSlotsGames = this.tableSlotsList.Games;
    }
  }
  onFormSubmit() {
    this.form.value.startDate = moment(this.form.value.startDate).format('DD-MM-YYYY');
   this.form.value.endDate = moment(this.form.value.endDate).format('DD-MM-YYYY'); 
    this.store.dispatch(new PlayerGetGameHistory(this.form.value));
  }
  onremoteFormSubmit(){
     this.remoteform.value.startDate = moment(this.remoteform.value.startDate).format('DD-MM-YYYY');
     this.remoteform.value.endDate = moment(this.remoteform.value.endDate).format('DD-MM-YYYY'); 
    this.store.dispatch(new PlayerGetRemoteGameHistory(this.remoteform.value));
  }
  ngOnDestroy(){
    if(this.profilestoreSub){
      this.profilestoreSub.unsubscribe();
    }
    if(this.profileRemotestoreSub){
      this.profileRemotestoreSub.unsubscribe();
    }
  }

}
