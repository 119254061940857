import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import {environment} from '@app/env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MeightService {

  constructor(private http: HttpClient) {
  }

 meightServiceData(data):Observable<any>{
   return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.meight}/${data}`)
 }
}
