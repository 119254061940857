import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CounterHandle } from 'src/app/source/services/common/CounterHandle.service';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';

@Component({
  selector: 'app-betsoft',
  templateUrl: './betsoft.component.html',
  styleUrls: ['./betsoft.component.css']
})
export class BetsoftComponent implements OnInit {
  private storeSub: Subscription;
  gamelunchurl: any;
  urlSafe: SafeResourceUrl;
  casinovivoerror: string;
  constructor(private counterHandler: CounterHandle, public sanitizer: DomSanitizer, private store: Store<appState.AppState>) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.store.dispatch(new playerActions.PlayerGetCasinoPlayVivo('betSoft'));
  }

  ngOnInit() {
    this.storeSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.Casinovivoplayer) {
          if (playerState.Casinovivoplayer['status'] == true) {
            this.casinovivoerror = '';
            this.gamelunchurl = playerState.Casinovivoplayer['url'];
            this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
          } else if (playerState.Casinovivoplayer['success'] == false) {
            this.urlSafe = "";
            if(playerState.Casinovivoplayer['description'] == 'INVALID_SESSION_TOKEN'){
              this.store.dispatch(new loginActions.LogoutStart());
            }else{
              this.casinovivoerror = playerState.Casinovivoplayer['description'];
            }
          }
        }
        if (playerState.CasinovivoplayerError) {
          this.urlSafe = "";
          this.casinovivoerror = playerState.CasinovivoplayerError.message;
        }
      }
    );
    this.store.dispatch(new playerActions.PlayerGetCasinoPlayVivo('betSoft'));
  }
  gameStarted() {
    this.counterHandler.stopCounter();
  }
}
