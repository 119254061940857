import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '@app/env';
import { Observable } from 'rxjs';
import {Results} from '../../modules/betresults/results';
@Injectable({
  providedIn: 'root'
})
export class BetresultService {

  constructor(private httpClient:HttpClient) { }
  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
      'siteid': environment.skinId
    })
  };
  getResults(data) : Observable<Results> {
    return this.httpClient.post<Results>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.result}`,data, this.httpOptions);
  }
}
