import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import {environment} from '@app/env';
import { Observable } from 'rxjs';
import { slotsData } from '../modules/slotsmodel/slotsdata';
import { Store } from '@ngrx/store';
import { AppState } from '../appstates/appState';
import { SlotsGamesLunch } from '../appstates/sblogin/sbloginAction';
import { PlayerService } from './player/player.service';
@Injectable({
  providedIn: 'root'
})
export class SlotsService {
  private _CasinojsonURL = 'assets/casinogames.json';
  private _AllSlots = 'assets/allslots.json';
  private _TopSlots = 'assets/topslots.json';
  private _TableGames = 'assets/tablegames.json';
  private _Alljsonfawk = 'assets/fawkGames-1.json';
  private evoTableregularjson = "assets/evolution_regular_table.json";
  private evoTablePremiumjson = "assets/evolution_premium_table.json";
  private EzugiStandedjson = "assets/ezugi_standard.json";
  private EzugiPremiumjson = "assets/ezugi_premium.json";
  private _livecasinoJson = 'assets/liveCasino.json';

  constructor(
    private playerService: PlayerService, private http: HttpClient,private store:Store<AppState>) {

   }
  //  private httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type':  'application/json',
  //     'siteid': environment.skinId,
  //     'wsession':sessionStorage.getItem('wsession'),
  //     'Authorization': "Bearer " + sessionStorage.getItem('accessToken')
  //   })
  // };
  public AllSlotsJson(): Observable<any> {
    return this.http.get(this._AllSlots);
  }
  public TopSlotsJson(): Observable<any> {
    return this.http.get(this._TopSlots);
  }
  public TableGamesJson(): Observable<any> {
    return this.http.get(this._TableGames);
  }
  public CasinogetJSON(): Observable<any> {
    return this.http.get(this._CasinojsonURL);
  }
  getfawkGames(){
    return this.http.get<any>(this._Alljsonfawk)
  }
  public evoTableregular(): Observable<any> {
    return this.http.get(this.evoTableregularjson);
  }
  liveCasinoGames(): Observable<any> {
    return this.http.get(this._livecasinoJson);
  }
  public evoTablePremium(): Observable<any> {
    return this.http.get(this.evoTablePremiumjson);
  }
  public EzugiStanded(): Observable<any> {
    return this.http.get(this.EzugiStandedjson);
  }
  public EzugiPremium(): Observable<any> {
    return this.http.get(this.EzugiPremiumjson);
  }
  public getlottery(): Observable<any> {
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.lotteryUrl
      }${sessionStorage.getItem("profile")}`,
      this.playerService.httpOptions()
    );
  }
  getpragmatichit(data) {
    let body = {
    }
    return this.http.post(`${environment.appApi.baseUrl}${environment.appApi.pragmatictoken}/${data}`,body, this.playerService.httpOptions());
  }
   getEzugi(data){
      return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.ezugi}/${data}`,this.playerService.httpOptions());
  }
  httpClientH(data){
    return this.http.get<slotsData>(`${environment.appApi.baseUrl}${environment.appApi.heblounch}/${data}`, this.playerService.httpOptions());
  }
  casinoBlackJackLunch(){
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_blackajack}`, this.playerService.httpOptions());
  }
  casinoBaccaratLunch(){
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_baccarat}`, this.playerService.httpOptions());
  }
  casinoEuropeanrouletteLunch(){ 
    try{
     return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_europeanroulette}`, this.playerService.httpOptions());
    }catch(e){
console.log(e);
    }
  }
  liveDealerServiceData(data):Observable<any>{
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.ezugi}/${data}`)
  }
  casinoOasispokerLunch(){
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.casino_Oasispoker}`, this.playerService.httpOptions());
  }
  public getlivedealergames(){
    // let wsession = sessionStorage.getItem("wsession");
    // return this.httpClient.get<any>(`${environment.appApi.baseUrl}${environment.appApi.livedealer}}`,body,this.httpOptions() );
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.livedealer}`,this.playerService.httpOptions());
  }
  vivogame() {
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.vivourl}`,this.playerService.httpOptions());
  }
  getSlotsService(data){

    this.store.dispatch(new SlotsGamesLunch(data));
  }
}
