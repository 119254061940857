import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { CommondataService } from '../../source/services/sports/commondata.service';
import { SportsData, GameData, LeagueData, MatchData, HomeoddsData } from '../../source/modules/specific/matches.model';
import { oddMatch, oddData, Selection } from '../../source/modules/specific/oddMatches.model';
import { Betslip, OddsBetslip } from '../../source/modules/specific/odds.model';
import { sideSportService } from '../../source/services/sports/sportMenu.service';
import { sportMenuModel, Datum } from '../../source/modules/specific/sportMenu.model';
import { SportsMenuComponent } from '../sports-menu/sports-menu.component';
import { PasswordsComponent } from '../passwords/passwords.component';
import { topTenLeagues } from 'src/app/source/modules/specific/topleagues';
declare var $: any;

@Component({
  selector: 'app-side-sport-matches',
  templateUrl: './side-sport-matches.component.html',
  styleUrls: ['./side-sport-matches.component.css']
})
export class SideSportMatchesComponent implements OnInit {
  leagueDetails: boolean = false;
  allSports: boolean = true;
  status: boolean = false;
  sportsData: SportsData;
  gameData: Array<GameData>;
  leagueData: LeagueData;
  matchData: Array<MatchData>;
  homeoddsData: HomeoddsData;
  CountryName: string;
  SportName: string;
  comingFromSportMenu: boolean = false;
  SportMenuOddsMatches: boolean = false;
  OddMatch: oddMatch;
  OddData: Array<oddData>;
  odds: Array<Selection>;
  oddType: string;
  matchid: number;
  matid: number;
  betSlip: Array<Betslip>;
  bet: Betslip;
  sportMenu: sportMenuModel;
  DataRes: Array<Datum>;
  sportMenuErrorMessage: string;
  sName: string;
  errorMessageResponse: string;
  leagueName: string;
  toleaguecountry:string;
  mchNM: string;
  date_a: any;
  time_a: any;
    dateTime: any;
  innerWidth: number;
  toptenLeaguesError: string;
  toptenLeagues: topTenLeagues;
  loaderdata:boolean= true;
  selectedleagueid: string;
  days: string = "all";
  loaderOddData: boolean = false;
  
  SelectedOddTypeName:any={};
  oddtypename:string;
  OddDataerrormessage: string; 
  searchText;

  @ViewChild(SportsMenuComponent, { static: false }) sideM: SportsMenuComponent;
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;


  constructor(private CommondataService: CommondataService, private _mainServe: sideSportService) { 
    this.innerWidth = (window.innerWidth);
    if (this.innerWidth > 760) {
      console.log("above 760")
    } else {
      console.log("bellow 760")
    }
    
    sessionStorage.setItem("SportName", this.sName);
    
    this.CommondataService.matchData.subscribe(value => { this.getSportData(value) });
    //this._mainServe.sendSportCountryLeague(sessionStorage.getItem("sportName"), sessionStorage.getItem("Country"), sessionStorage.getItem("League")).subscribe(spCountryLeague => this.getSportData(spCountryLeague));
    //this._mainServe.getTodayMatches("Soccer", "all").subscribe(matches => this.getMatches(matches));
    this._mainServe.sportMenuData().subscribe(sportData => this.sportMenuList(sportData));

  }
  ngOnInit() {
    
    $(document).ready(function () {
      $('.sportsULlist li').click(function () {
        // $('.sportsULlist li').removeClass('active');
        $(this).addClass('active');
        $('.sportsULlist li').removeClass('active');
      });
      $('.tab_match .match_ch').click(function () {
        $('.match_ch').removeClass('active');
        $(this).addClass('active')
      });
    });
  }
  sportMenuList(sportData) {
    this.sportMenu = sportData;
    if (this.sportMenu.Status == 200) {
      this.DataRes = this.sportMenu.data;
    } else {
      this.sportMenuErrorMessage = this.sportMenu.message;
    }
  }



  oddMarkets(oddId) {
    this.OddMatch = oddId;
    if (this.OddMatch.Status == 200) {
      this.OddDataerrormessage = null;
      this.loaderOddData = false;
      this.OddData = this.OddMatch.data;  
      // for(let data of this.OddData){
      //   this.SelectedOddTypeName[data.oddstype]=data.oddstype;
      // }
    } else {
      this.OddData = null;
      this.loaderOddData = false;
      this.OddDataerrormessage = this.OddMatch.Message;
    }
  }

  getSportData(value) {
    this.sportsData = value;
    if (this.sportsData.Status == 200) {
      this.loaderdata= false;
      this.gameData = this.sportsData.data;
      this.leagueData = this.gameData[0].league;
      this.leagueName = this.gameData[0].league.league;
      this.SportName = this.gameData[0].league.sport;
      this.CountryName = this.gameData[0].league.country;
      this.matchData = this.gameData[0].league.matches;
      this.comingFromSportMenu = true;

      sessionStorage.setItem("SportName", this.SportName);
      this._mainServe.getTopTenLeagues(this.SportName).subscribe(topleagues => this.tenLeagues(topleagues));
    }
  }
  tenLeagues(topleagues) {
    if (topleagues.Status == 400) {
      this.toptenLeaguesError = topleagues.Message;
      this.toptenLeagues = null;
    } else {
      this.toptenLeaguesError = null;
      this.toptenLeagues = topleagues;
    }
    console.log(topleagues);

  }
  topleaguematches(id:any, country:string) {
    this.onclicksportdata();
    this.status = !this.status;
    this.selectedleagueid = id;
    this.toleaguecountry=country;
    this._mainServe.sendSportCountryLeague(this.SportName, this.toleaguecountry, this.selectedleagueid).subscribe(spCountryLeague => this.toleaguegetMatches(spCountryLeague));
  }
  toleaguegetMatches(matches) {
    this.sportsData = matches;
    if (this.sportsData.Status == 200) {
      this.loaderdata = false;
      this.errorMessageResponse = null;
      this.gameData = this.sportsData.data;
      this.leagueData = this.gameData[0].league;
      this.leagueName = this.gameData[0].league.league;
      this.SportName = this.gameData[0].league.sport;
      this.CountryName = this.gameData[0].league.country;
      this.matchData = this.gameData[0].league.matches;
    }else {
      this.gameData = null;
      this.loaderdata = false;
      this.errorMessageResponse = this.sportsData.Message;
    }
  }


  j_hideMtch() {
    $(".j_markets").toggleClass('wid_50');
    $(".j_matches").toggle();
  }

  show_league(id, matchNM, date, time, mtid) {
    $(window).scrollTop(350);
    if (this.innerWidth > 767) {
      $(document).ready(function () {
        $('.j_matches, .j_markets').addClass("wid_50");
        $('.match_12').removeClass('col-sm-6').addClass("col-sm-12");
      });
    } else {
      $(document).ready(function () {
        $('.j_matches').hide();
        // $('.j_markets').show();
        $('.match_12').removeClass('col-sm-6').addClass("col-sm-12");
      });
    };
     this.OddMatch = null;
    this.OddData=null;
    this.OddDataerrormessage=null;
    this.loaderOddData = true;
    this.leagueDetails = false;
    this.allSports = false;
    this.SportMenuOddsMatches = true;
    this._mainServe.sendOddsMatchId(id).subscribe(oddId => this.oddMarkets(oddId));
    this.mchNM = matchNM;
    this.matchid = id;
    this.matid = mtid;
    this.date_a = date;
    this.time_a = time;
     this.dateTime = date + "-" + time;
    this.sideM.sideMenuClose();
    this.SideMenuMarkets();
  }
  closeSportMenuOddsMatches() {
    this.SportMenuOddsMatches = false;
    this.leagueDetails = false;
    this.sideM.sideMenuOpen();
    if (this.innerWidth > 767) {
      $(".j_matches").removeClass('wid_50').removeClass('d-none');
      $('.match_12').addClass('col-sm-6').removeClass("col-sm-12");
      $(".j_matches").show();
    } else {
      $(".j_matches").show();
    }
  }

  SideMenuMarkets() {
    this.leagueDetails = true;
  }

  showAll() {
    this.leagueDetails = false;
    this.allSports = true;

    this._mainServe.getTodayMatches("Soccer", "all").subscribe(matches => this.getMatches(matches));
  }
  showToday() {
    this._mainServe.getTodayMatches("Soccer", "td").subscribe(matches => this.getMatches(matches));
  }
  showAllTomorrow() {
    this._mainServe.getTodayMatches("Soccer", "tt").subscribe(matches => this.getMatches(matches));
  }
  selectedIndex: number = null;

  //  setIndex(index: number) {
  //     this.selectedIndex = index;
  //   }
  sportName(sportName, index: number) {
    this.sName = sportName;
    this.selectedIndex = index;
    console.log(this.sName)
    this._mainServe.getTodayMatches(sportName, "all").subscribe(matches => this.getMatches(matches));
    if ($('.match_12').hasClass('col-sm-12')) {
      $('.match_12').removeClass('col-sm-12').addClass('fd');
      console.log("class available")
    } else {
      $('.match_12').removeClass('col-sm-6').addClass('fd');
      console.log("no class")
    }
  }
  getMatches(matches) {

    this.sportsData = matches;
    this.gameData = this.sportsData.data;
    this.matchData = this.gameData[0].league.matches;
    // console.log( this.matchData );
    /*if( this.sportsData.Status == 200){
   } else {
     this.errorMessageResponse = "No data found"
   }*/
  }

  // homeodd(data, sdd) {
  //   //this.passwords.comingSoonPopOpen()
  //   this.bet = new Betslip(data, sdd);
  //   this.CommondataService.OddData(this.bet);
  // }

  // selectedodd(ods, type, title, matcnm, matchid) {
  //   //this.passwords.comingSoonPopOpen()
  //   this.bet = new OddsBetslip(ods, type, title, matcnm, matchid);
  //   this.CommondataService.OddData(this.bet);
  // }
  homeodd(data, sdd) {
    //this.passwords.comingSoonPopOpen()

    console.log(data, this.CountryName);
    this.bet = new Betslip(data, sdd, this.CountryName);
    this.CommondataService.OddData(this.bet);
  }

  selectedodd(ods, type, title, matcnm, matchid) {
    //this.passwords.comingSoonPopOpen()
    console.log(this.CountryName);
    this.bet = new OddsBetslip(ods, type, title, matcnm, matchid, this.dateTime, this.CountryName);
    this.CommondataService.OddData(this.bet);
  }
	  
  onclicksportdata(){
    this.errorMessageResponse =null;
    this.gameData=null;
    this.matchData=null;
    this.OddMatch = null;
    this.OddData=null;
    this.OddDataerrormessage=null;
    this.closeSportMenuOddsMatches();
    this.loaderdata= true;
  }
  oddinfo(oddtype){
    this.oddtypename = oddtype;
    if(oddtype in this.SelectedOddTypeName){
      delete this.SelectedOddTypeName[oddtype];
    }else{
      this.SelectedOddTypeName[oddtype]=this.oddtypename;
     
    }
  }
}
