import * as tokenactions from './sbloginAction';
import { sBookLogin } from '../../modules/sblogin/sblogin';
import { HttpClientError } from '../../modules/login/httpClientError';
import { slotsData } from '../../modules/slotsmodel/slotsdata';
import { GoldenRaceGameLaunch } from '../../modules/goldenraceinterface/goldenrace';

export interface tokenState {
    sBookLogin: sBookLogin;
    sBookLoginErrorResponse:HttpClientError;
    slotsData:slotsData;
    slotsDataError:HttpClientError;
    GrGameLaunch:GoldenRaceGameLaunch;
    GrDataError:HttpClientError;
}

const initialState: tokenState = {
    sBookLogin: null,
    sBookLoginErrorResponse:null,
    slotsData:null,
    slotsDataError:null,
    GrGameLaunch:null,
    GrDataError:null,
}
export function tokenStateReducer(state: tokenState = initialState, action: tokenactions.tokenActions) {
    switch (action.type) {
        case tokenactions.RESET_STATE:
            return {
                ...state,
                sBookLogin: null,
                sBookLoginErrorResponse:null,
                slotsData:null,
                slotsDataError:null,
                GrGameLaunch:null,
                GrDataError:null,
            }
            break;
        case tokenactions.PLAYER_GET_TOKEN:
            return {
                ...state,
                sBookLogin: null,
                sBookLoginErrorResponse:null
            }
            break;
        case tokenactions.PLAYER_GET_TOKEN_SUCCESS:
            return {
                ...state,
                sBookLogin: { ...state.sBookLogin, ...action.payload },
                sBookLoginErrorResponse: null
            }
            break;
        case tokenactions.PLAYER_GET_TOKEN_FAIL:
            return {
                ...state,
                sBookLogin: null,
                sBookLoginErrorResponse: { ...state.sBookLoginErrorResponse, ...action.payload },
            }
            break;
        case tokenactions.PLAYER_GET_TOKEN:
            return {
                ...state,
                slotsData: null,
                slotsDataError:null
            }
            break;
        case tokenactions.PLAYER_GET_TOKEN_SUCCESS:
            return {
                ...state,
                slotsData: { ...state.slotsData, ...action.payload },
                slotsDataError: null
            }
            break;
        case tokenactions.PLAYER_GET_TOKEN_FAIL:
            return {
                ...state,
                slotsData: null,
                slotsDataError: { ...state.slotsDataError, ...action.payload },
            }
            break;
            case tokenactions.GR_GAME_LAUNCH_START:
                return {
                    ...state,
                    GrGameLaunch: null,
                    GrDataError:null
                }
                break;
            case tokenactions.GR_GAME_LAUNCH_SUCCESS:
                return {
                    ...state,
                    GrGameLaunch: { ...state.GrGameLaunch, ...action.payload },
                    GrDataError: null
                }
                break;
            case tokenactions.GR_GAME_LAUNCH_FAIL:
                return {
                    ...state,
                    GrGameLaunch: null,
                    GrDataError: { ...state.GrDataError, ...action.payload },
                }
                break;
        default:
            return state;
            break;
    }
}