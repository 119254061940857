import { liveOdds } from './liveodds';
import { league } from './live';
import { Participant } from './participants';

export class LiveBetslip {

    name: string;
    matchId: number;
    type: String;
  //  oddData: liveOdds;
    oddTitle: string;
    oddstype: string;
    betString: any;
    odd: any;
    oddId: number;
    decimalOdd: string;
    betAmount: number;
    mktId: any;
    flag: boolean;
    
    datendtime:any;
    //selectionId : number;


    constructor(oddData: league, title: string, odds: liveOdds, part: Participant) {

        this.flag =true;
        this.matchId = oddData.id;
        this.name = oddData.name;
        this.oddstype = odds.name;
        if(oddData.startTime != '-'){
            this.datendtime = oddData.startTime;
        }else{
            this.datendtime = "";
        }
        if (title == part.value_eu) {
            this.oddTitle = part.name + " " + part.handicap;
            this.odd = part.value_eu;
            this.decimalOdd = part.value_eu;
            this.mktId = odds.id;
            this.oddId = part.id;
            if(oddData.startTime != '-'){
                this.betString = this.datendtime + " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
            }else{
                this.betString = this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
            }
        } else if (title == part.value_us) {
            this.oddTitle = part.name + " " + part.handicap;
            this.odd = part.value_eu;
            this.decimalOdd = part.value_eu;
            this.mktId = odds.id;
            this.oddId = part.id;
            if(oddData.startTime != '-'){
                this.betString = this.datendtime + " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
            }else{
                this.betString = this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
            }
            //this.betString =  this.datendtime + " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
        } else if (title == part.value_na) {
            this.oddTitle = part.name + " " + part.handicap;
            this.odd = part.value_eu;
            this.decimalOdd = part.value_eu;
            this.mktId = odds.id;
            this.oddId = part.id;
            if(oddData.startTime != '-'){
                this.betString = this.datendtime + " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
            }else{
                this.betString = this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
            }
           // this.betString =  this.datendtime + " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
        }





    }


}
