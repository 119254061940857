import * as PlayerBetHistoryActions from './bethistoryActions';
import { BetHistory } from '../../modules/BetHistory/bet-history';

export interface playerBetState {
    BetHistory:BetHistory;
}
const initialState: playerBetState = {
    BetHistory:null,
}

export function betHistoryReducer(playerBetState: playerBetState = initialState, action: PlayerBetHistoryActions.PlayerBetHistoryActions) {
    switch (action.type) {
        case PlayerBetHistoryActions.PLAYER_GET_HISTORY:
            return {
                ...playerBetState,
                BetHistory: null
            }
            break;
        case PlayerBetHistoryActions.PLAYER_GET_HISTORY_SUCCESS:
            return {
                ...playerBetState,
                BetHistory: { ...playerBetState, ...action.payload }
            }
            break;
        case PlayerBetHistoryActions.PLAYER_GET_HISTORY_FAIL:
            return {
                ...playerBetState,
                BetHistory: null
            }
            break;
        default:
            return playerBetState;
            break;
    }
}