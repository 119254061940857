import { Component, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { UpDatePasswordModel } from 'src/app/source/modules/player/updatepassword';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  @Output() close = new EventEmitter<void>();
  updatePassword: FormGroup;
  private updatepasswordsub: Subscription;
  private updatepasswordmodel: UpDatePasswordModel;
  changePassword: boolean = false;
  updatepassworderror: string = "";
  ChangePasswordsuccess: boolean = false;
  constructor(private store: Store<appState.AppState>,private router: Router) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }

  ngOnInit() {
    this.store.dispatch(new playerActions.ResetState());
    this.updatePassword = new FormGroup({
      "oldPassword": new FormControl(null, [Validators.required, Validators.minLength(6),Validators.maxLength(15),Validators.pattern(/^\S*$/)]),
      "newPassword": new FormControl(null, [Validators.required, Validators.minLength(6),Validators.maxLength(15),Validators.pattern(/^\S*$/)])
    })

    this.updatepasswordsub = this.store.select('playerState').subscribe(
      (playerState: ProfileState) => {
        if (playerState.updatepasswordmodel) {
          if (playerState.updatepasswordmodel.success == true) {
            this.updatePassword.reset();
            this.changePassword = false;
            this.ChangePasswordsuccess = true;
            this.updatepassworderror = "";
          } else if (playerState.updatepasswordmodel.success == false) {
            // this.updatepassworderror = 'Invalid Old Password';
            this.updatepassworderror = playerState.updatepasswordmodel.description;
          }
        }
        if(playerState.updatepasswordmodelError){
          this.updatepassworderror = playerState.updatepasswordmodelError.message;
        }
      }
    )
  }
  onUpdatePasswordSubmit() { 
    this.store.dispatch(new playerActions.PlayerUpdatePassword(this.updatePassword.value));
  }
  ngOnDestroy() {
    if (this.updatepasswordsub) {
      this.updatepasswordsub.unsubscribe();
    }
  }
  openPasswordPop() {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.changePassword = true;
  }
  closePasswordPop() {
    this.updatePassword.reset();
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.changePassword = false;
  }
  ChangePsdPopClose() {
    this.changePassword = false;
    this.ChangePasswordsuccess = false;
    this.store.dispatch(new playerActions.ResetState());
    this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new loginActions.LogoutStart()); 
  }
  newpasswordValid(event) {
    var k;
    k = event.charCode;  
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k >= 33 && k <= 47 || k >= 60 && k <= 64 || (k >= 48 && k <= 57) || k >= 91 && k <= 96 || k >= 123 && k <= 126);
  }
  oldpasswordValid(event) {
    var k;
    k = event.charCode;  
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k >= 33 && k <= 47 || k >= 60 && k <= 64 || (k >= 48 && k <= 57) || k >= 91 && k <= 96 || k >= 123 && k <= 126);
  }
}
