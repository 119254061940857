import { Component, OnInit, ViewChild } from '@angular/core';
import { PasswordsComponent } from '../passwords/passwords.component';
declare var $:any;
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;
  status:boolean=true;
  bottomText:boolean = false;
  constructor() { }

  ngOnInit() {
    $(document).ready(function () {
      $(".scrollTop").click(function () {
        $(window).scrollTop(0);
      });
    });

  }
  onStatus(){
    this.status=false;
  }
  comingsoon(){
    this.passwords.comingSoonPopOpen()

  }
  bottomHIde(){
    this.bottomText = false;
  }
}
