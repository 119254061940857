import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NavigationComponent } from './skins/navigation/navigation.component';
import { SportsMenuComponent } from './skins/sports-menu/sports-menu.component';
import { CarouselComponent } from './skins/carousel/carousel.component';
import { BetslipComponent } from './skins/betslip/betslip.component';
import { LiveMatchComponent } from './skins/live-match/live-match.component';
import { PreMatchComponent } from './skins/pre-match/pre-match.component';
import { CasinoComponent } from './skins/casino/casino.component';
import { FooterComponent } from './skins/footer/footer.component';
import { PromotionsComponent } from './skins/footer/promotions/promotions.component';
import { ContactComponent } from './skins/footer/contact/contact.component';
import { PokerComponent } from './skins/poker/poker.component';
import { UpdateProfileComponent } from './skins/update-profile/update-profile.component';
import { ChangePasswordComponent } from './skins/change-password/change-password.component';
import { WithdrawalComponent } from './skins/withdrawal/withdrawal.component';
import { GameHistoryComponent } from './skins/game-history/game-history.component';
import { CashOutHistoryComponent } from './skins/cash-out-history/cash-out-history.component';
import { TransactionHistoryComponent } from './skins/transaction-history/transaction-history.component';
import { DepositComponent } from './skins/deposit/deposit.component';
import { BalanceComponent } from './skins/balance/balance.component';
import { PasswordsComponent } from './skins/passwords/passwords.component';
import { SportsComponent } from './skins/sports/sports.component';
import { LiveaddsComponent } from './skins/liveadds/liveadds.component';
import { BetemailComponent } from './skins/betslip/betemail/betemail.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { LoginEffects } from './source/appstates/loginstates/loginEffects';
import { PlayerEffects } from './source/appstates/playerstates/playerEffects';
import { CashierEffects } from './source/appstates/cashierstates/cashierEffects';
import { PlayerHistoryEffects } from './source/appstates/bethistory/betHistoryEffects';
import { resultEffects } from './source/appstates/betresult/betresultEffects';
import { tokenEffects } from './source/appstates/sblogin/sbloginEffects';
import * as appState from 'src/app/source/appstates/appState';
import { GoldenraceComponent } from './skins/goldenrace/goldenrace.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { RegisterComponent } from './skins/register/register.component';
import { DepositresponseComponent } from './skins/depositresponse/depositresponse.component';
import { ResultsComponent } from './skins/results/results.component';
import { HistoryComponent } from './skins/history/history.component';
import { PagenotfoundComponent } from './skins/pagenotfound/pagenotfound.component';
import { PlaceholderDirectiveDirective } from 'src/app/source/directives/placeholder-directive.directive';

import {NgxPrintModule} from 'ngx-print';
import { ResetPasswordComponent } from "./skins/reset-password/reset-password.component";
import { BankaccountComponent } from './skins/bankaccount/bankaccount.component';
import { SideSportMatchesComponent } from './skins/side-sport-matches/side-sport-matches.component';
import { PrintfeedComponent } from './skins/printfeed/printfeed.component';
import { RouteauthGuard } from './routeauth.guard';
import { EsoccerComponent } from './skins/esoccer/esoccer.component';
import { EsportsComponent } from './skins/esports/esports.component';
import { FilterpipePipe } from './source/pipes/filterpipe.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { BetslipadsComponent } from './skins/betslip/betslipads/betslipads.component';
import { BottomSlotsComponent } from './skins/bottom-slots/bottom-slots.component';
import { BetsoftComponent } from './skins/casino/betsoft/betsoft.component';
import { NucleusComponent } from './skins/casino/nucleus/nucleus.component';
import { SpinomenalComponent } from './skins/casino/spinomenal/spinomenal.component';
import { TomhornComponent } from './skins/casino/tomhorn/tomhorn.component';
import { PlatipusComponent } from './skins/casino/platipus/platipus.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { AboutusComponent } from './skins/aboutus/aboutus.component';
import { TermsAndConditionsComponent } from './skins/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './skins/privacy-policy/privacy-policy.component';
import { livedealerComponent } from './skins/livedealer/livedealer.component';
export function HttpLoaderFactory(httpClient: HttpClient) {

  return new TranslateHttpLoader(httpClient, '/assets/Language/', '.json');

}
@NgModule({
  declarations: [
    AppComponent,
    ResultsComponent,
    PagenotfoundComponent,
    HistoryComponent,
    ResetPasswordComponent,
    NavigationComponent,
    SportsMenuComponent,
    CarouselComponent,
    PlaceholderDirectiveDirective,
    BetslipComponent,
    LiveMatchComponent,
    PreMatchComponent,
    CasinoComponent,
    FooterComponent,
    PromotionsComponent,
    ContactComponent,
    PokerComponent,
    UpdateProfileComponent,
    ChangePasswordComponent,
    WithdrawalComponent,
    GameHistoryComponent,
    CashOutHistoryComponent,
    TransactionHistoryComponent,
    DepositComponent,
    BalanceComponent,
    PasswordsComponent,
    SportsComponent,
    LiveaddsComponent,
    BetemailComponent,
    GoldenraceComponent,
    RegisterComponent,
    DepositresponseComponent,
    PrintfeedComponent,  
    BankaccountComponent, 
    SideSportMatchesComponent,
    EsoccerComponent, 
    EsportsComponent, 
    FilterpipePipe, 
    BetslipadsComponent, 
    BottomSlotsComponent, 
    livedealerComponent,
    BetsoftComponent, 
    NucleusComponent, 
    SpinomenalComponent, 
    TomhornComponent, 
    PlatipusComponent, AboutusComponent, TermsAndConditionsComponent, PrivacyPolicyComponent
    
  ],
  imports: [  
    Ng2SearchPipeModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxPaginationModule,
    NgxPrintModule,
    ReactiveFormsModule,
    BrowserAnimationsModule, 
     TranslateModule.forRoot({  loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    } }),
    StoreModule.forRoot(appState.AppReducer),
    EffectsModule.forRoot([LoginEffects, PlayerEffects, CashierEffects, PlayerHistoryEffects, resultEffects, tokenEffects])
  ],
  providers: [RouteauthGuard],
  bootstrap: [AppComponent],
  entryComponents: [
    BetemailComponent
  ],
})
export class AppModule { }
