import { Action } from '@ngrx/store';
import { sBookLogin } from '../../modules/sblogin/sblogin';
import { HttpClientError } from '../../modules/login/httpClientError';
import { slotsData } from '../../modules/slotsmodel/slotsdata';
import { GoldenRaceGameLaunch } from '../../modules/goldenraceinterface/goldenrace';

export const RESET_STATE = "[sBookLogin] RESET_STATE";

export const PLAYER_GET_TOKEN = "[sBookLogin]  PLAYER_GET_TOKEN";
export const PLAYER_GET_TOKEN_SUCCESS = "[sBookLogin]  PLAYER_GET_TOKEN_SUCCESS";
export const PLAYER_GET_TOKEN_FAIL = "[sBookLogin]  PLAYER_GET_TOKEN_FAIL";

export const SLOTS_GAME_LAUNCH_START = "[sBookLogin] SLOTS_GAME_LAUNCH_START";
export const SLOTS_GAME_LAUNCH_SUCCESS = "[sBookLogin] SLOTS_GAME_LAUNCH_SUCCESS";
export const SLOTS_GAME_LAUNCH_FAIL = "[sBookLogin] SLOTS_GAME_LAUNCH_FAIL";


export const GR_GAME_LAUNCH_START = "[sBookLogin] GR_GAME_LAUNCH_START";
export const GR_GAME_LAUNCH_SUCCESS = "[sBookLogin] GR_GAME_LAUNCH_SUCCESS";
export const GR_GAME_LAUNCH_FAIL = "[sBookLogin] GR_GAME_LAUNCH_FAIL";
 
export class ResetState implements Action{
    readonly type = RESET_STATE;
    constructor(){ }
}
export class PlayerGetToken implements Action{
    readonly type = PLAYER_GET_TOKEN;
    constructor(public payload:object){
     }
}
export class PlayerGetTokenSuccess implements Action{
    readonly type = PLAYER_GET_TOKEN_SUCCESS;
    constructor(public payload:sBookLogin){ }
}
export class PlayerGetTokenFail implements Action{
    readonly type = PLAYER_GET_TOKEN_FAIL;
    constructor(public payload:object){ }
}
export class SlotsGamesLunch implements Action{
    readonly type = SLOTS_GAME_LAUNCH_START;
    constructor(public payload:Object){ }
}
export class SlotsGamesLunchSuccess implements Action{
    readonly type = SLOTS_GAME_LAUNCH_SUCCESS;
    constructor(public payload:slotsData){ }
}
export class SlotsGamesLunchFail implements Action{
    readonly type = SLOTS_GAME_LAUNCH_FAIL;
    constructor(public payload:Object){ }
}
export class GrGamesLunch implements Action{
    readonly type = GR_GAME_LAUNCH_START;
    constructor(){ }
}
export class GrGamesLunchSuccess implements Action{
    readonly type = GR_GAME_LAUNCH_SUCCESS;
    constructor(public payload:GoldenRaceGameLaunch){ }
}
export class GrGamesLunchFail implements Action{
    readonly type = GR_GAME_LAUNCH_FAIL;
    constructor(public payload:Object){  }
}


export type tokenActions =  ResetState|PlayerGetToken|PlayerGetTokenSuccess|PlayerGetTokenFail|
SlotsGamesLunch|SlotsGamesLunchSuccess|SlotsGamesLunchFail
|GrGamesLunch|GrGamesLunchSuccess|GrGamesLunchFail;