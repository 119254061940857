import { Component, OnInit } from '@angular/core';
import { Sports } from '../../source/modules/common/livedata/sports';
import { CommondataService } from '../../source/services/sports/commondata.service';
import { WebsocketService } from '../../source/services/sports/websocket.service';
import { WebService } from '../../source/services/sports/web.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { LiveBetslip } from '../../source/modules/common/livedata/live-betslip';
import { BindingFlags } from '@angular/compiler/src/core';
import { environment } from '../../../environments/environment';
declare var $: any;
@Component({
  selector: 'app-live-match',
  templateUrl: './live-match.component.html',
  styleUrls: ['./live-match.component.css']
})
export class LiveMatchComponent implements OnInit {
  livedata: Sports;
  leaguesdata: any;
  odds: any;
  matchName: string;
  allsports: string;
  sportName: String;
  zeroIndexMarkets = true;
  allMarkets = false;
  oddType: string = 'value_eu';
  matchId: number;
  bet: LiveBetslip;
  leagueIndex = 0;
  selectOdd: any;
  innerWidth: number;
  matchindex = true;
  withoutmatchindex = false;
  sideView_F = false;
  sideView_T = true;
  leaguevalue: string;
  selectedIndex: number = -1;
  objectKeys = Object.keys;
  constructor(private CommondataService: CommondataService, private socketService: WebsocketService, private webservice: WebService) {
    this.CommondataService.live$.subscribe(data => {
      this.handleSuccessfulResponse(data);
    });
    webservice.send();
    this.CommondataService.Isselectedoddtype.subscribe(data => this.handleOddType(data));
  }
  ngOnInit(): void {
    this.allfun();
    // throw new Error("Method not implemented.");
  }

  types = [{ 'name': 'EU', 'value': "value_eu" }, { 'name': 'NA', 'value': "value_na" }, { 'name': 'US', 'value': "value_us" }]
  forallmarkets(data) {
    console.log("---------------------------------click------------------------------")
    //this.zeroIndexMarkets = false;
    this.allMarkets = true;
    this.matchName = data;
	$(window).scrollTop(150);
    this.innerWidth = (window.innerWidth);
    if (this.innerWidth > 760) {
      this.sideView_T = false;
      this.sideView_F = true;
      $(document).ready(function () {
        $('.j_matches, .j_markets').addClass("wid_50");
        $('.match_12').removeClass('col-sm-6').addClass("col-sm-12");
      });
    } else {
      this.sideView_T = false;
      this.sideView_F = false;
    }
  }
  closeSportMenuOddsMatches() {
    this.sideView_T = true;
    this.sideView_F = false;
    this.allMarkets = false;
  }
   
  getLeagues(value: any, i: any) {
    //   this.zeroIndexMarkets = true;
    //this.allMarkets = false;
    this.leagueIndex = i;
    this.leaguevalue = value;
  }
  handleSuccessfulResponse(data) {
    this.livedata = data;
    this.leaguesdata = this.livedata.data;
  }
  handleOddType(value) {
    if (value == 'EU') {
      this.oddType = 'value_eu';
    } else if (value == 'NA') {

      this.oddType = 'value_na';
    } else if (value == 'US') {

      this.oddType = 'value_us';
    } else {

      this.oddType = 'value_eu';
    }
  }
  setMoreMarkets(id) {
    this.matchId = id;
  }
  keepOrder = (a, b) => { return a; }


  clickLiveSport(name: string, index: number) {
    this.sportName = name;
    this.selectedIndex = index;
    this.allsports = ''
    this.sideView_T = true;
    this.sideView_F = false;
    this.allMarkets = false;
  }
  allfun() {
    this.selectedIndex = -1
    this.allsports = 'all'
  }
  clickBet(oddData, title, type, participants, $event) {
    let div = $event.currentTarget;
    let isActive = div.classList.contains('active');
    if (isActive) {
      div.classList.remove("active");
    } else {
      div.classList.add("active");
    }
    this.bet = new LiveBetslip(oddData, title, type, participants);
    this.CommondataService.OddData(this.bet);

  }



}
