import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavigationComponent } from './skins/navigation/navigation.component';
import { CasinoComponent } from './skins/casino/casino.component';
import { SportsMenuComponent } from './skins/sports-menu/sports-menu.component';
import { BetslipComponent } from './skins/betslip/betslip.component';
import { LiveMatchComponent } from './skins/live-match/live-match.component';
import { PreMatchComponent } from './skins/pre-match/pre-match.component';
import { PromotionsComponent } from './skins/footer/promotions/promotions.component';
import { ContactComponent } from './skins/footer/contact/contact.component';
import { PokerComponent } from './skins/poker/poker.component';
import { WithdrawalComponent } from './skins/withdrawal/withdrawal.component';
import { GameHistoryComponent } from './skins/game-history/game-history.component';
import { CashOutHistoryComponent } from './skins/cash-out-history/cash-out-history.component';
import { TransactionHistoryComponent } from './skins/transaction-history/transaction-history.component';
import { DepositComponent } from './skins/deposit/deposit.component';
import { BalanceComponent } from './skins/balance/balance.component';
import { SportsComponent } from './skins/sports/sports.component';
import { LiveaddsComponent } from './skins/liveadds/liveadds.component';
import { GoldenraceComponent } from './skins/goldenrace/goldenrace.component';
import { HistoryComponent } from './skins/history/history.component';
import { ResultsComponent } from './skins/results/results.component';
import { PagenotfoundComponent } from './skins/pagenotfound/pagenotfound.component';
import { DepositresponseComponent } from './skins/depositresponse/depositresponse.component';

import { BankaccountComponent } from './skins/bankaccount/bankaccount.component';
import { SideSportMatchesComponent } from './skins/side-sport-matches/side-sport-matches.component';
import { RouteauthGuard } from './routeauth.guard';
import { EsoccerComponent } from './skins/esoccer/esoccer.component';
import { RegisterComponent } from './skins/register/register.component';
import { EsportsComponent } from './skins/esports/esports.component';
import {PrintfeedComponent} from './skins/printfeed/printfeed.component';
import { BetsoftComponent } from './skins/casino/betsoft/betsoft.component';
import { NucleusComponent } from './skins/casino/nucleus/nucleus.component';
import { SpinomenalComponent } from './skins/casino/spinomenal/spinomenal.component';
import { TomhornComponent } from './skins/casino/tomhorn/tomhorn.component';
import { PlatipusComponent } from './skins/casino/platipus/platipus.component';
import { AboutusComponent } from './skins/aboutus/aboutus.component';
import { TermsAndConditionsComponent } from './skins/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './skins/privacy-policy/privacy-policy.component';
import { livedealerComponent } from './skins/livedealer/livedealer.component';
const routes: Routes = [
  {
    path: "", component: NavigationComponent,

    children: [
      {
        path: "", component: SportsComponent,
        children: [
          {
            path: "", component: SportsMenuComponent,
            children: [
              { path: "", component: PreMatchComponent, pathMatch: "full" },
              { path: "preMatch", component: PreMatchComponent },
              { path: "oddsprint", component: PrintfeedComponent},
              { path: "register", component: PreMatchComponent },
              { path: "sports", component: SideSportMatchesComponent },
              { path: "esoccer", component: EsoccerComponent },
              { path: "esport", component: EsportsComponent },
            ]
          },
          {
            path: "", component: LiveaddsComponent,
            children: [
              { path: "liveMatch", component: LiveMatchComponent },
            ]
          },
        ]
      },

      { path: "promotions", component: PromotionsComponent },
      { path: "contact", component: ContactComponent },
      { path: 'results', component: ResultsComponent },
      { path: "poker", component: PokerComponent },
      { path: "betSoft", component: BetsoftComponent },
      { path: "nucleus", component: NucleusComponent },
      { path: "spinomenal", component: SpinomenalComponent},
      { path: "tomHorn", component: TomhornComponent},
      { path: "platipus", component: PlatipusComponent},
      { path: "casino", component: CasinoComponent },
      { path: "livecasino", component: livedealerComponent },
      { path: 'virtuals', component: GoldenraceComponent },
      { path: 'history', component: HistoryComponent, canActivate:[RouteauthGuard]},
      { path: "Withdrawal", component: WithdrawalComponent, canActivate:[RouteauthGuard] },
      { path: "gameHistory", component: GameHistoryComponent, canActivate:[RouteauthGuard] },
      { path: "cashOut", component: CashOutHistoryComponent, canActivate:[RouteauthGuard] },
      { path: "transaction", component: TransactionHistoryComponent, canActivate:[RouteauthGuard] },
      { path: "deposit", component: DepositComponent, canActivate:[RouteauthGuard] },
      { path: "balance", component: BalanceComponent, canActivate:[RouteauthGuard]},
      { path: "bankaccount", component: BankaccountComponent, canActivate:[RouteauthGuard]},
      { path: "betsoft", component: BetsoftComponent},
      { path: "nucleus", component: NucleusComponent},
      { path: "spinomenal", component: SpinomenalComponent},
      { path: "tomhorn", component: TomhornComponent},
       { path: "platipus", component: PlatipusComponent},
       { path: "aboutus", component: AboutusComponent},
       { path: "PrivacyPolicy", component: PrivacyPolicyComponent},
       { path: "TermsAndConditions", component: TermsAndConditionsComponent},
]
  },


  { path: "depositresponse", component: DepositresponseComponent, canActivate:[RouteauthGuard] },
  { path: '**', component: PagenotfoundComponent }
]


@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }