import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Sports } from '../../modules/common/livedata/sports';
import { CommondataService } from './commondata.service';
@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
   private _connection : WebSocket;
   private network_Connected = new Subject<string>();
   private network_error = new Subject<object>();
  constructor(private CommondataService : CommondataService) { 
  }
   connect(url:string){
    this._connection = new WebSocket(url);
    this._connection.onopen = (res) => {
      this.network_Connected.next('connected');
    };
    this._connection.onmessage  = (event)=> {
     let data= JSON.parse(event['data']);
    this.returnData(data);
    };
    this._connection.onerror = (error) => {
      this.network_error.next(error);
    };
  }
  send(object){
      this._connection.send(object);
  }
  returnData (event){
    let  json;
    try{
        json = event;
    }catch(error){

    } 
     this.CommondataService.setLive(json);
  }

  get onConnection():Subject<string>{
    return this.network_Connected;
}
get onError():Subject<object>{
    return this.network_error;
}
}
