import { Component, OnInit } from '@angular/core';
import { SlotsService } from 'src/app/source/services/slots.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { slotsData, casinogmaelunch, KeyVsValue } from 'src/app/source/modules/slotsmodel/slotsdata';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import { buildRootObject, buildGame } from 'src/app/source/modules/slotsmodel/builsslots';
import { environment } from '@app/env';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { CasinoGames, Casino } from 'src/app/source/modules/goldenraceinterface/goldenrace';

import { Router, ActivatedRoute } from '@angular/router';
import { SafeResourceUrl, DomSanitizer } from '@angular/platform-browser';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { CounterHandle } from 'src/app/source/services/common/CounterHandle.service';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { Balance, WalletInfo } from 'src/app/source/modules/cashier/balance';
@Component({
  selector: 'app-casino',
  templateUrl: './casino.component.html',
  styleUrls: ['./casino.component.css']
})
export class CasinoComponent implements OnInit {
  private profilestoreSubslots: Subscription;
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  private storeProfileSub: Subscription;
  casinogames: CasinoGames;
  slotsdata: slotsData;
  buildRootObject: buildRootObject;
  buildGame: buildGame;
  allSlotsList: RootObject;
  topSlotsList: RootObject;
  tableSlotsList: RootObject;
  allSlotsGames: Array<Game>;
  topSlotsGames: Array<Game>;
  tableSlotsGames: Array<Game>;
  Casinresponse: Array<Casino>;
  KeyVsValue: KeyVsValue;
  balackjackplayerContextId: string;
  baccaratplayerContextId: string;
  europeanrouletteplayerContextId: string;
  OasispokerplayerContextId: string;
  sessionid: any;
  playerName:string;
  casinogmaelunch: casinogmaelunch;
  imagespath = environment.slotsiamgepath;
  casinogamessuccessPop:boolean=false;
  casinomessage:string="";
  keyname:string;
  tokendata:any=null;
  private storeSub: Subscription;
  loginloader: boolean = false;
  cashier: Balance;
  walleteInfo: WalletInfo[];
  balance: any;
  playertype: any;
  playerbonus: any;
  
  gamelunchurl:any;
  urlSafe:SafeResourceUrl;  
  loginSub: Subscription;
  playerLOggedIn: boolean = false;
  gamesId:string = 'Mrslotty';
  constructor(public sanitizer: DomSanitizer,  private counterHandler: CounterHandle, private route: ActivatedRoute, private router: Router, private slotsservice: SlotsService, private store: Store<appState.AppState>) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.slotsservice.CasinogetJSON().subscribe(data => { this.Casinoimages(data) });
  }


  ngOnInit() {
    this.gameId("Mrslotty")
   
   this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    
    this.playerLOggedIn = loginState.playerLoggedIn.loggedIn;
    if( this.playerLOggedIn ){
          this.hitService(sessionStorage.getItem('wsession'));

        }
        this.balance1()
      }
    });

    this.storeProfileSub = this.store.select("playerState").subscribe(
      (profileState: ProfileState) => {
        if (profileState.profile) {
          if (profileState.profile.success === true) {
            if (profileState.profile.login) {
              this.playerName = profileState.profile.login;
              // this.hitService(sessionStorage.getItem('wsession'));
            }
          }else if (profileState.profile.success === false) {
            
          }
        }
      })
  
  }

  gameId(data){ 
    this.gamesId = ''
    this.gamesId = data
    if(this.gamesId == "Mrslotty"){ 
    this.slotsservice.TopSlotsJson().subscribe(topSlots => {this.topSlotsGamesList(topSlots)});
    }else if(this.gamesId == 'AllSlots'){ 
      this.slotsservice.AllSlotsJson().subscribe(allSlots => { this.allSlotsGamesList(allSlots) });
    }else{
      this.slotsservice.TableGamesJson().subscribe(tableSlots => {this.tableSlotsGamesList(tableSlots)
        if(tableSlots){
          this.tableSlotsList = tableSlots;
          if (this.tableSlotsList) {
            this.tableSlotsGames = this.tableSlotsList.Games;
          }
        }});

    }
  }
  balance1(){
    this.storeSub = this.store.select("cashierState").subscribe(
      (cashierState: CashierState) => {
        if (cashierState.balance) {
          if (cashierState.balance.success == true) {
            this.loginloader = false;
            this.cashier = cashierState.balance;
            this.walleteInfo = cashierState.balance.values;
            for (let wallet of this.walleteInfo) {
              if (wallet.wallet.name != 'PLAYMONEY') {
                this.balance = wallet.cash.value + wallet.bonus.value;
                this.playertype = wallet.wallet.name;
                sessionStorage.setItem("PlayerCurrency", this.playertype);
                this.playerbonus = wallet.bonus.value;
              } else {
              }
            }
          } else if (cashierState.balance.success == false) {
  
            this.loginloader = false;
          }
        }
  
      }
    )
  }
    allSlotsGamesList(allSlots){
    this.allSlotsList = allSlots;
    if (this.allSlotsList) {
      this.allSlotsGames = this.allSlotsList.Games;
    }
  }
  subtabClose(){
    this.urlSafe = false; 
  }
  topSlotsGamesList(topSlots){
    console.log(topSlots)
    this.topSlotsList = topSlots;
    if (this.topSlotsList) {
      this.topSlotsGames = this.topSlotsList.Games;
    }
  }
  tableSlotsGamesList(tableSlots){
    if(tableSlots){
      this.tableSlotsList = tableSlots;
      if (this.tableSlotsList) {
        this.tableSlotsGames = this.tableSlotsList.Games;
      }
    }
  }
  Casinoimages(data) {
    this.casinogames = data;
    if (this.casinogames) {
      this.Casinresponse = this.casinogames.Casino;
    }
  }
  casinoGamesSubmit(id: any) {
    if (this.playerLOggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.sessionid = sessionStorage.getItem('wsession').substr(sessionStorage.getItem('wsession').indexOf(".") + 1);
      if (id == 101) {
        this.slotsservice.casinoEuropeanrouletteLunch().subscribe(data => { this.casinoEuropeanroulette(data) });
      } else if (id == 102) {
        this.slotsservice.casinoBaccaratLunch().subscribe(data => { this.casinoBaccarat(data) });
      } else if (id == 103) {
        this.slotsservice.casinoBlackJackLunch().subscribe(data => { this.casinoBlackJack(data) });
      } else if (id == 104) {
        this.slotsservice.casinoOasispokerLunch().subscribe(data => { this.casinoOasispoker(data) });
      }
    } else if (this.playerLOggedIn == false) {
      this.casinogamessuccessPop=true;
    }

  } 
  casinoBlackJack(data) {
    this.casinogmaelunch = data;
    if (this.casinogmaelunch.success == true) {
      if (this.casinogmaelunch.keyVsValue.playerContextId != null) {
        this.balackjackplayerContextId = this.casinogmaelunch.keyVsValue.playerContextId;
        return window.open("http://baghabetp4m.czargaming.com/htmlgames/blackjack/index.html?gameName=BLACKJACK&language=EN&wallet=NGN&sessionId=" + this.sessionid + "&gameSession=" + this.balackjackplayerContextId, '_blank');
      }
    } else {
      this.casinomessage=this.casinogmaelunch.description;
    }
  }
  casinoBaccarat(data) {
    this.casinogmaelunch = data;
    if (this.casinogmaelunch.success == true) {
      if (this.casinogmaelunch.keyVsValue.playerContextId != null) {
        this.baccaratplayerContextId = this.casinogmaelunch.keyVsValue.playerContextId;
        return window.open("http://baghabetp4m.czargaming.com/htmlgames/baccarat/index.html?gameName=BACCARAT&language=EN&wallet=NGN&sessionId=" + this.sessionid + "&gameSession=" + this.baccaratplayerContextId, '_blank');
      }
    } else {
      this.casinomessage= this.casinogmaelunch.description;
    }
  }
  casinoEuropeanroulette(data) {
    this.casinogmaelunch = data;
    if (this.casinogmaelunch.success == true) {
      if (this.casinogmaelunch.keyVsValue.playerContextId != null) {
        this.europeanrouletteplayerContextId = this.casinogmaelunch.keyVsValue.playerContextId;
        return window.open("http://baghabetp4m.czargaming.com/htmlgames/europeanroulette/index.html?gameName=EUROPEANROULETTE&language=EN&wallet=NGN&sessionId=" + this.sessionid + "&gameSession=" + this.europeanrouletteplayerContextId, '_blank');
      }
    } else {
      this.casinomessage= this.casinogmaelunch.description;
    }
  }
  casinoOasispoker(data) {
    this.casinogmaelunch = data;
    if (this.casinogmaelunch.success == true) {
      if (this.casinogmaelunch.keyVsValue.playerContextId != null) {
        this.OasispokerplayerContextId = this.casinogmaelunch.keyVsValue.playerContextId;
        return window.open("http://baghabetp4m.czargaming.com/htmlgames/oasispoker/index.html?gameName=OASISPOKER&language=EN&wallet=NGN&sessionId=" + this.sessionid + "&gameSession=" + this.OasispokerplayerContextId, '_blank');
      }
    } else {
      this.casinomessage= this.casinogmaelunch.description;
    }
  }
  hitService(profile) {
    //let profile = sessionStorage.getItem('profile');
    this.slotsservice.httpClientH(profile).subscribe(data => { this.slotslunch(data) });
  };
  // slotsinlogout(data) {
  //   if (this.playerLOggedIn == true) {
  //     this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  //     this.keyname = data;
  //     if (this.tokendata == null) {
  //       this.hitService();
  //     } else if(this.tokendata != null){
  //       this.gamelunchurl= this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+data+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en';
  //       this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
  //       window.scroll(0,450);
  //       return;
  //     }
  //   } else if (this.playerLOggedIn == false) {
  //     this.casinomessage="Please login";
  //     this.casinogamessuccessPop=true;
  //   }
  // }
  slotsreal(data) {
    if (this.playerLOggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      if (this.tokendata == null) {
        let profile = sessionStorage.getItem('wsession');
        this.hitService(profile);
      } else if(this.tokendata != null){
        this.gamelunchurl= this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en';
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
        window.scroll(0,450);
        return;
      }
    } else if (this.playerLOggedIn == false) {
      
      this.casinomessage="Please login";
      this.casinogamessuccessPop=true;
    }
  }
  slotsfun(data) {
    if (this.playerLOggedIn == true) {
      this.keyname = data;
      if (this.tokendata == null) {
        let profile = sessionStorage.getItem('wsession');
        this.hitService(profile);
      } else if(this.tokendata != null){
        this.gamelunchurl= this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=fun&locale=en';
        this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
        window.scroll(0,450);
        return;
      }
    } else if (this.playerLOggedIn == false) {
      
      this.casinomessage="Please login";
      this.casinogamessuccessPop=true;
    }
  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
    // this.gamelunchurl= this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en';
    // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.gamelunchurl);
    // window.scroll(0,450);
    // return;
    }
  }
  casinogamessuccessPopClose(){
      this.casinomessage="";
      this.casinogamessuccessPop=false;
  }
  gameStarted() {
    this.counterHandler.stopCounter();
  }
}
