import {ActionReducerMap } from '@ngrx/store';
import * as loginState from './loginstates/loginState';
import * as playerState from './playerstates/playerState';
import * as cashierState from './cashierstates/cashierState';
import * as playerBetState  from './bethistory/betHistoryState';
import * as betResultState from './betresult/betresultState';
import * as tokenState from './sblogin/sbloginState';

export interface AppState{
    loginState:loginState.LoginState;
    playerState:playerState.ProfileState;
    cashierState:cashierState.CashierState;
    playerBetState:playerBetState.playerBetState;
    betResultState:betResultState.betResultState;
    tokenState:tokenState.tokenState;
}

export const AppReducer:ActionReducerMap<AppState> = {
    loginState:loginState.loginReducer,
    playerState:playerState.playerReducer,
    cashierState:cashierState.cashierReducer,
    playerBetState:playerBetState.betHistoryReducer,
    betResultState:betResultState.betResultReducer,
    tokenState:tokenState.tokenStateReducer,
}
