import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as PlayerBetHistoryActions from './bethistoryActions';
import { exhaustMap, map, catchError, tap } from 'rxjs/internal/operators';
import { BethistoryService} from '../../services/bethistory.service';
import { HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';

import { BetHistory } from '../../modules/BetHistory/bet-history';
import { Router } from '@angular/router';
import { isBoolean } from 'util';

@Injectable()
export class PlayerHistoryEffects{
    constructor(private actions$:Actions,
        private Bethistoryservice:BethistoryService,
        private router:Router){}
    @Effect()
    // PlayerGetHistory = this.actions$.pipe(
    //     ofType(PlayerBetHistoryActions.PLAYER_GET_HISTORY),
    //     exhaustMap((getBetHistory:PlayerBetHistoryActions.PlayerGetHistory) => 
    //     this.Bethistoryservice.getBetHistory(getBetHistory.payload)
    //     .pipe(
    //         map((response:BetHistory) => {
          
    //             return new PlayerBetHistoryActions.PlayerGetHistorySuccess(response);
                
    //         }),
    //         catchError((error:HttpErrorResponse)=>{
    //             if (error.error instanceof Error) {
    //                 return of(new PlayerBetHistoryActions.PlayerGetHistoryFail(error.error.message));
    //               } else {
    //                 try{
    //                     if(error.error.message){
    //                         return of(new PlayerBetHistoryActions.PlayerGetHistoryFail(error.error.message));
    //                     }else if(error.message){
    //                         return of(new PlayerBetHistoryActions.PlayerGetHistoryFail(error.message));
    //                     }
    //                     return of(new PlayerBetHistoryActions.PlayerGetHistoryFail("Something went wrong please contact admin"));
    //                 }catch(error){
                         
    //                 }
    //               }
    //               return of(new PlayerBetHistoryActions.PlayerGetHistoryFail("Something went wrong please contact admin"));
    //         })
    //     )
    //     )
    // )

    @Effect({dispatch:false})
    PlayerGetHistorySuccess = this.actions$.pipe(
        ofType(PlayerBetHistoryActions.PLAYER_GET_HISTORY_SUCCESS),
        tap((getBetHistory:PlayerBetHistoryActions.PlayerGetHistoryFail)=>{
             if(getBetHistory.payload){
            }
        })
    )
    }