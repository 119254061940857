import { Component, ViewChild, HostListener } from '@angular/core';
import { AppState } from './source/appstates/appState';
import { Store } from '@ngrx/store';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { interval, Subscription } from 'rxjs';
import { LoginState } from './source/appstates/loginstates/loginState';
import { tokenState } from './source/appstates/sblogin/sbloginState';
import { NavigationEnd, Router } from '@angular/router';
import { CounterHandle } from './source/services/common/CounterHandle.service';
//import { NavigationComponent } from './skins/navigation/navigation.component';
//import { ResetPasswordComponent } from './skins/reset-password/reset-password.component';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  private profilestoreSubslots: Subscription;
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  modelpopup: boolean = false;
  sessionoutmessage: string;
  counter: any;
  logoutCounter: any = null;
  loginSub: Subscription;
  playerLoggedIn: boolean = false;
  isExecuteCounter: boolean = true;
  constructor(private router: Router, private store: Store<AppState>, private counterService: CounterHandle) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    interval(5000 * 60).subscribe(x => {
      if (this.playerLoggedIn == true) {
        this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      }
    });
    this.initializeApp();
    this.counterService.counterStatus.subscribe((data) => {
      if (data)
        this.setCounterStatus(data.status);
    });
    router.events.subscribe((val) => {
      // see also 
      if (val instanceof NavigationEnd) {
        this.restartCounter();
      }
    });
  }
  //@ViewChild(ResetPasswordComponent, { static: false }) resetPasswordcomp: ResetPasswordComponent;
  ngAfterViewInit() {
    this.jqueryResetTimer();
  }
  ngOnInit() {
    this.loginSub = this.store
      .select("loginState")
      .subscribe((loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }
      });

  }
  @HostListener('window:mousemove') refreshUserState() {
    this.normalImg();
  }
  @HostListener('window:scroll') refreshUserStateOnScroll() {
    this.normalImg("SCROLL");
  }

  @HostListener('mouseover')
  onmousehover() {
    this.normalImg("mouseover");
  }
  @HostListener('click') onclick() {
    this.normalImg("click");
  }
  @HostListener('mouseleave') onMouseLeave() {
    this.normalImg("mouseleave");
  }

  closemodelpopup() {
    this.modelpopup = false;
    this.sessionoutmessage = "";
    this.clearCounters();
    this.store.dispatch(new loginActions.LogoutStart());
  }
  normalImg(eventName = "") {
    this.clearCounters();
    if (this.playerLoggedIn == true && this.isExecuteCounter) {
      this.counterService.setCurrentCounterStatus(true);
      // console.log("********** " + eventName + " TRIGGERING EVENTS ********")
      this.counter = setTimeout(() => {
        this.modelpopup = true;
        this.sessionoutmessage = "Your session has expired. Please relogin";
      }, 1140000);
      this.logoutCounter = setTimeout(() => {
        this.clearCounters();
        this.closemodelpopup();
      }, 1260000);

    }

  }
  clearCounters() {
    if (this.counter) {
      clearTimeout(this.counter);
    }
    if (this.logoutCounter) {
      clearTimeout(this.logoutCounter);
    }
    this.counterService.setCurrentCounterStatus(false);
  }
  initializeApp() {
    try {
      document.getElementById("preloader").style.display = "none"
    } catch (e) {
      console.log(e);
    }

  }
  jqueryResetTimer() {


    $("*").trigger("mousemove");

    $('#iframeTest').bind('mousemove keydown scroll', function () {
      console.log("**********iframe TRIGGERING EVENTS ********")
      if (this.isExecuteCounter) {
        this.normalImg();
      }

    }.bind(this));


  }
  setCounterStatus(status) {
    this.isExecuteCounter = status;
    this.clearCounters();
    if (this.isExecuteCounter) {
      this.normalImg();
    }
    else {
      this.counterService.setCurrentCounterStatus(false);
    }
  }

  restartCounter() {
    this.clearCounters();
    this.isExecuteCounter = true;
    this.normalImg();
  }
}
