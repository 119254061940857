import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import {environment} from '@app/env';
import { Observable, of } from 'rxjs';
import {PlayerService} from '../player/player.service';
import { HttpClientError } from '../../modules/login/httpClientError';
import { catchError, map } from 'rxjs/internal/operators';
const handleError = (error: any, actions:any) => {
  if (error.error instanceof Error) {
      return of(new actions({"message":error.error.message}));
    } else {
      try{
          if(error.error.message){
              return of(new actions({"message":error.error.message}));
          }else if(error.message){
              return of(new actions({"message":error.message}));
          }
          return of(new actions({"message":"Something went wrong please contact admin"}));
      }catch(error){
      }
    }
    return of(new actions({"message":"Something went wrong please contact admin"}));
}
@Injectable({
  providedIn: 'root'
})
export class GoldenraceService {
  errorText : HttpClientError;
  private _GoldenRacejsonURL = 'assets/goldenrace.json';
  public authHeader = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'siteId': environment.skinId
            })
}
  constructor(private http: HttpClient, private PlayerService:PlayerService) {
    
   }
  public GoldengetJSON(): Observable<any> {
    return this.http.get(this._GoldenRacejsonURL);
  }
  httpClient(data, id, device):Observable<any>{
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.goldenrace}/${data}/${id}/${device}`, this.PlayerService.httpOptions()).pipe(
      map(response => {
          return response;
      }),
      catchError((error:HttpErrorResponse)=>{
         //alert(error.message);
           return handleError(error, this.errorText);
      })
  );
  }
  httpClientfun(id, device):Observable<any>{
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.goldenrace}/${id}/${device}`, this.PlayerService.httpBeforLoginOptions).pipe(
      map(response => {
          return response;
      }),
      catchError((error:HttpErrorResponse)=>{
         //alert(error.message);
           return handleError(error, this.errorText);
      })
  );
  }
  httpClient1(){
    return this.http.get(`${environment.appApi.baseUrl}${environment.appApi.goldenrace}`, this.PlayerService.httpBeforLoginOptions).pipe(
      map(response => {
          return response;
      }),
      catchError((error:HttpErrorResponse)=>{
         //alert(error.message);
           return handleError(error, this.errorText);
      })
  );
  }



}
