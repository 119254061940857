import { Component, OnInit } from '@angular/core';
//import { formatDate } from '@angular/common';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { BetHisData } from 'src/app/source/modules/BetHistory/BetHisData';
import { BetHistory, History, Betline } from 'src/app/source/modules/BetHistory/bet-history';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
//import * as bethistoryActions from 'src/app/source/appstates/bethistory/bethistoryActions';
//import { playerBetState } from 'src/app/source/appstates/bethistory/betHistoryState';
//import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
//import { tokenState } from '../../source/appstates/sblogin/sbloginState';
//import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { sideSportService } from 'src/app/source/services/sports/sportMenu.service';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  now = '';
  undefiend;
  selecteddata;
  private HistoryStoreSub: Subscription;
  today = new Date;
  loginSub: Subscription;
  historyloader:boolean=false;
  p: number = 1;
  statustype=['All', 'Pending', 'WINS', 'Cancelled', 'VOID', 'LOST'];
  selectnum: number = 5;
  logoutstore: Subscription;
  bettokenstoreSub: Subscription;
  descending: boolean;
  betHisData: BetHisData;
  betHistory: BetHistory;
  betHisResponse: Array<History>;
  betSlipId: number;
  bethistoryform: FormGroup;
  StartDate: Date;
  EndDate: Date;
  errormessage: string;
  accbetslipid:any;
  itemsperpagecount = [
    { num: 5 },
    { num: 10 },
    { num: 15 },
    { num: 20 },
    { num: 25 }
  ];
  
  playerLOggedIn: boolean = false;
  constructor(private store: Store<appState.AppState>, private historyService: sideSportService) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
  }


  ngOnInit() {

    this.EndDate = new Date();
    this.StartDate = new Date();
    this.bethistoryform = new FormGroup({
      "StartDate": new FormControl(this.StartDate, Validators.required),
      "EndDate": new FormControl(this.EndDate, Validators.required),
      "status": new FormControl(null, Validators.required)
    });
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLOggedIn = loginState.playerLoggedIn.loggedIn;
      }
    });
 
    //   this.store.dispatch(new loginActions.ResetState());
    //   this.bettokenstoreSub = this.store.select("tokenState").subscribe(
    //     (tokenState: tokenState) => {
    //         if (tokenState.sBookLogin) {
    //             if (tokenState.sBookLogin.accessToken != null) {
    //                 this.isLoggedIn = true;
    //                 this.now = formatDate(this.today, 'yyyy-MM-dd', 'en-US', '+0530');
    //                 this.betHisData = new BetHisData(this.now);
    //                 this.historyService.getBetHistory(this.betHisData).subscribe(data => this.betHistoryResponse(data));
    //             } else if (tokenState.sBookLogin.accessToken == null) {
    //                 this.isLoggedIn = false;
    //             }
    //         }
    //     }
    // )
    // this.logoutstore =  this.store.select("loginState").subscribe(
    //   (loginState: LoginState) => {
    //     if (loginState.logoutResponse) {
    //       if (loginState.logoutResponse.success === true) {
    //               this.isLoggedIn = false;
    //       } else if (loginState.logoutResponse.success === false) {

    //       }
    //     }
    //   }
    // )
  }

  onChange(data) {
    this.selectnum = data;
  }


  betHistoryResponse(data) {
    this.betHistory = data;
    if (this.betHistory.Status == 1) {
    this.historyloader =false;
      this.errormessage=null;
      this.betHisResponse = this.betHistory.history;
    } else{
      this.historyloader =false;
      this.betHisResponse=null;
      this.errormessage = this.betHistory.Message;
    }
  }
  // tranHistory(value: any) {
  //   this.betSlipId = value;
  // }

  onBetHistoryFormSubmit() {
    this.historyloader =true;
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.historyService.getBetHistory(this.bethistoryform.value).subscribe(data => this.betHistoryResponse(data),
    err => {this.errormessage = err.message;
      this.historyloader =false;}
    );

  }

  accordian(id){
this.accbetslipid = id;
  }
}
