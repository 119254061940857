import { Action } from '@ngrx/store';
import { Results } from '../../modules/betresults/results';

export const SPORTBOOK_GET_RESULT = "[Results] SPORTBOOK_GET_RESULT";
export const SPORTBOOK_GET_RESULT_SUCCESS = "[Results] SPORTBOOK_GET_RESULT_SUCCESS";
export const SPORTBOOK_GET_RESULT_FAIL = "[Results] SPORTBOOK_GET_RESULT_FAIL";

export class sportBookGetResults implements Action{
    readonly type = SPORTBOOK_GET_RESULT;
    constructor(public payload:Object){ }
}
export class sportBookGetResultsSuccess implements Action{
    readonly type = SPORTBOOK_GET_RESULT_SUCCESS;
    constructor(public payload:Results){ }
}
export class sportBookGetResultsFail implements Action{
    readonly type = SPORTBOOK_GET_RESULT_FAIL;
    constructor(public payload:Object){ }
}

export type resultActions = sportBookGetResults|sportBookGetResultsSuccess|sportBookGetResultsFail