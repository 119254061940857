import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { CommondataService } from '../../source/services/sports/commondata.service';
import { SportsData, GameData, LeagueData, MatchData, HomeoddsData } from '../../source/modules/specific/matches.model';
import { oddMatch, oddData, Selection } from '../../source/modules/specific/oddMatches.model';
import { Betslip, OddsBetslip } from '../../source/modules/specific/odds.model';
import { sideSportService } from '../../source/services/sports/sportMenu.service';
import { sportMenuModel, Datum } from '../../source/modules/specific/sportMenu.model';
import { SportsMenuComponent } from '../sports-menu/sports-menu.component';
import { PasswordsComponent } from '../passwords/passwords.component';
import { topTenLeagues } from 'src/app/source/modules/specific/topleagues';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { PrintFeedOfleagues } from 'src/app/source/appstates/loginstates/loginActions';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';

import { Router, ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-pre-match',
  templateUrl: './pre-match.component.html',
  styleUrls: ['./pre-match.component.css']
})
export class PreMatchComponent implements OnInit {
  minseletedValue;
  maxseletedValue;
  undefiend;
  minodderrorMessage: string;
  minodd = [0, 0.5, 1, 1.5, 2, 2.5, 3];
  maxvaluetrue: boolean = false;
  minmaxloader: boolean = false;
  leagueDetails: boolean = false;
  allSports: boolean = true;
  sportsData: SportsData;
  gameData: Array<GameData>;
  leagueData: LeagueData;
  matchData: Array<MatchData>;
  homeoddsData: HomeoddsData;
  CountryName: string;
  SportName: string;
  toptenLeagues: topTenLeagues;
  comingFromSportMenu: boolean = false;
  SportMenuOddsMatches: boolean = false;
  OddMatch: oddMatch;
  OddData: Array<oddData>;
  odds: Array<Selection>;
  oddType: string;
  matchid: number;
  matid: number;
  betSlip: Array<Betslip>;
  bet: Betslip;
  sportMenu: sportMenuModel;
  DataRes: Array<Datum>;
  sportMenuErrorMessage: string;
  errorMessageResponse: string;
  leagueName: string;
  toptenLeaguesError: string;
  mchNM: string;
  date_a: any;
  time_a: any;
  dateTime: any;
  searchText;
  selectcountry: string;
  selectedIndex: number = 0;
  innerWidth: number;
  topleagueshow: boolean = false;
  normalleaguesshow: boolean = true;
  selectedleagueid: any;
  toleaguecountry: string;
  leaguename: string;
  countryname: string;
  loaderdata: boolean = true;
  status: boolean = false;
  sName: string = "Soccer";
  days: string = "all";
  loaderOddData: boolean = false;
  OddDataerrormessage: string;
  SelectedOddTypeName: any = {};
  oddtypename: string;
  selectnum: number = 5;

  selectedbetslipmaps: any = {};
  betMatchId: any = {};
  betOddType: any = {};
  betOddTittle: any = {};
  betArray: Betslip;
  itemsperpagecount = [
    {num :10},
    {num :20},
    {num :30},
    {num :40},
    {num :50}
  ];
  p: number = 1;
  @ViewChild(SportsMenuComponent, { static: false }) sideM: SportsMenuComponent;
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;


  constructor(private router: Router, private store: Store<appState.AppState>, private CommondataService: CommondataService, private _mainServe: sideSportService) {
    // this.CommondataService.odd.subscribe(value => { this.assignToBetData(value); });
    this.innerWidth = (window.innerWidth);
    if (this.innerWidth > 760) {
    } else {
    }

    sessionStorage.setItem("SportName", this.sName);
    sessionStorage.setItem("timescope", this.days);
    sessionStorage.setItem("topleaguecountry", this.toleaguecountry);
    sessionStorage.setItem("selectleagueid", this.selectedleagueid);

    this.CommondataService.data$.subscribe(matches => this.getMatches(matches));
    this.CommondataService.topdata$.subscribe(topleagues => this.tenLeagues(topleagues));
    this.CommondataService.sportslist$.subscribe(sportData => this.sportMenuList(sportData));
    // this._mainServe.sportMenuData().subscribe(sportData => this.sportMenuList(sportData));
    this._mainServe.getTodayMatches(this.sName, this.days).subscribe(matches => this.getMatches(matches));

    this._mainServe.getTopTenLeagues(this.sName).subscribe(topleagues => this.tenLeagues(topleagues));
    // this.CommondataService.matchData.subscribe(value => { this.getSportData(value) });
    if (router.url == '/preMatch') {
      interval(2000 * 60).subscribe(x => {
        if (this.topleagueshow == true) {
          this._mainServe.sendSportCountryLeague(this.sName, this.toleaguecountry, this.selectedleagueid).subscribe(spCountryLeague => this.toleaguegetMatches(spCountryLeague));
        } else if (this.normalleaguesshow == true) {
          if (this.maxvaluetrue == true) {
            this._mainServe.getTodayoddMatches(this.sName, this.days, this.minseletedValue, this.maxseletedValue).subscribe(matches => this.getMatches(matches));
          } else {

            this._mainServe.getTodayMatches(this.sName, this.days).subscribe(matches => this.getMatches(matches));
          }
        }
      });
      if (this.topleagueshow == true) {
        let data = '123';
        sessionStorage.setItem("topleagueshow", data);
      } else if (this.normalleaguesshow == true) {
        let data = '456';
        sessionStorage.setItem("normalleaguesshow", data);
      }
    }
  }
  sportMenuList(sportData) {
    this.sportMenu = sportData;
    if (this.sportMenu.Status == 200) {
      this.DataRes = this.sportMenu.data;
    } else {
      this.sportMenuErrorMessage = this.sportMenu.message;
    }
  }

  // assignToBetData(data) {
  //   this.selectedbetslipmaps = {};
  //   this.betMatchId = {};
  //   this.betOddType = {};
  //   this.betOddTittle = {};
  //   this.betArray = data;
  //       this.selectedbetslipmaps[this.betArray.mktId] = true;
  //       this.betMatchId[this.betArray.matchId] = true;
  //       this.betOddType[this.betArray.oddstype] = true;
  //       this.betOddTittle[this.betArray.oddTitle] = true;
      
  
  // }

  oddMarkets(oddId) {
    this.OddMatch = oddId;
    if (this.OddMatch.Status == 200) {
      this.OddDataerrormessage = null;
      this.loaderOddData = false;
      this.OddData = this.OddMatch.data;
      // for(let data of this.OddData){
      //   this.SelectedOddTypeName[data.oddstype]=data.oddstype;
      // }
    } else {
      this.OddData = null;
      this.loaderOddData = false;
      this.OddDataerrormessage = this.OddMatch.Message;
    }
  }
  ngOnInit() {

    $(document).ready(function () {
      $('.sportsULlist li').click(function () {
        // $('.sportsULlist li').removeClass('active');
        $(this).addClass('active');
        $('.sportsULlist li').removeClass('active');
      });
      $('.tab_match .match_ch').click(function () {
        $('.match_ch').removeClass('active');
        $(this).addClass('active')
      });
      $('.info_icon').click(function () {
        console.log(" sdfasd fasd fasdf asdf asd")
        $('.info_spn').show();
      });
    });

  }

  j_hideMtch() {
    $(".j_markets").toggleClass('wid_50');
    $(".j_matches").toggle();
  }

  show_league(id, matchNM, date, time, country, mtid) {
    $(window).scrollTop(350);
    if (this.innerWidth > 767) {
      $(document).ready(function () {
        $('.j_matches, .j_markets').addClass("wid_50");
        $('.match_12').removeClass('col-sm-6').addClass("col-sm-12");
      });
    } else {
      $(document).ready(function () {
        $('.j_matches').hide();
        // $('.j_markets').show();
        $('.match_12').removeClass('col-sm-6').addClass("col-sm-12");
      });
    };
    this.OddMatch = null;
    this.OddData = null;
    this.OddDataerrormessage = null;
    this.loaderOddData = true;
    this.leagueDetails = false;
    this.allSports = false;
    this.SportMenuOddsMatches = true;
    if (this.maxvaluetrue == true) {
      this._mainServe.minandmaxsendOddsMatchId(id, this.minseletedValue, this.maxseletedValue).subscribe(oddId => this.oddMarkets(oddId));
    } else {
      this._mainServe.sendOddsMatchId(id).subscribe(oddId => this.oddMarkets(oddId));
    }
    this.mchNM = matchNM;
    this.matchid = id;
    this.matid = mtid;
    this.date_a = date;
    this.time_a = time;
    this.dateTime = date + "-" + time;
    this.selectcountry = country;
    this.sideM.sideMenuClose();
  }
  closeSportMenuOddsMatches() {
    this.SportMenuOddsMatches = false;
    this.leagueDetails = false;
    this.sideM.sideMenuOpen();
    if (this.innerWidth > 767) {
      $(".j_matches").removeClass('wid_50').removeClass('d-none');
      $('.match_12').addClass('col-sm-6').removeClass("col-sm-12");
      $(".j_matches").show();
    } else {
      $(".j_matches").show();
    }
  }

  SideMenuMarkets() {
    this.leagueDetails = true;
  }

  showAll(data) {
    this.onclicksportdata();
    this.leagueDetails = false;
    this.allSports = true;
    this.days = data;
    if (this.maxvaluetrue == true) {
      this._mainServe.getTodayoddMatches(this.sName, this.days, this.minseletedValue, this.maxseletedValue).subscribe(matches => this.getMatches(matches));
    } else {
      this._mainServe.getTodayMatches(this.sName, this.days).subscribe(matches => this.getMatches(matches));
    }
  }
  showToday(data) {
    this.onclicksportdata();
    this.days = data;
    if (this.maxvaluetrue == true) {
      this._mainServe.getTodayoddMatches(this.sName, this.days, this.minseletedValue, this.maxseletedValue).subscribe(matches => this.getMatches(matches));
    } else {
      this._mainServe.getTodayMatches(this.sName, this.days).subscribe(matches => this.getMatches(matches));
    }
  }
  showAllTomorrow(data) {
    this.onclicksportdata();
    this.days = data;
    if (this.maxvaluetrue == true) {
      this._mainServe.getTodayoddMatches(this.sName, this.days, this.minseletedValue, this.maxseletedValue).subscribe(matches => this.getMatches(matches));
    } else {
      this._mainServe.getTodayMatches(this.sName, this.days).subscribe(matches => this.getMatches(matches));
    }
  }

  //  setIndex(index: number) {
  //     this.selectedIndex = index;
  //   }
  sportName(sportName, index: number) {
    this.onclicksportdata();
    this.closeSportMenuOddsMatches();
    this.sName = sportName;
    sessionStorage.setItem("SportName", this.sName);
    this.selectedIndex = index;
    if (this.maxvaluetrue == true) {
      this._mainServe.getTodayoddMatches(this.sName, this.days, this.minseletedValue, this.maxseletedValue).subscribe(matches => this.getMatches(matches));
    } else {
      this._mainServe.getTodayMatches(this.sName, this.days).subscribe(matches => this.getMatches(matches));
    }
    this._mainServe.getTopTenLeagues(sportName).subscribe(topleagues => this.tenLeagues(topleagues));
    $(".j_matches").show();
  }
  tenLeagues(topleagues) {
    if (topleagues.Status == 400) {
      this.toptenLeaguesError = topleagues.Message;
      this.toptenLeagues = null;
    } else {
      this.toptenLeaguesError = null;
      this.toptenLeagues = topleagues;
    }
    console.log(topleagues);

  }
  topleaguematches(id: any, country: string) {
    this.maxvaluetrue == false;
    this.onclicksportdata();
    this.status = !this.status;
    this.selectedleagueid = id;
    this.toleaguecountry = country;

    sessionStorage.setItem("SportName", this.sName);
    sessionStorage.setItem("topleaguecountry", this.toleaguecountry);
    sessionStorage.setItem("selectleagueid", this.selectedleagueid);
    this._mainServe.sendSportCountryLeague(this.sName, this.toleaguecountry, this.selectedleagueid).subscribe(spCountryLeague => this.toleaguegetMatches(spCountryLeague));


  }
  getSportData(value) {
    this.sportsData = value;
    if (this.sportsData.Status == 200) {
      this.gameData = this.sportsData.data;
      this.leagueData = this.gameData[0].league;
      this.leagueName = this.gameData[0].league.league;
      this.SportName = this.gameData[0].league.sport;
      this.CountryName = this.gameData[0].league.country;
      this.matchData = this.gameData[0].league.matches;
      this.comingFromSportMenu = true;
    }
  }
  toleaguegetMatches(matches) {
    this.normalleaguesshow = false;
    this.topleagueshow = true;
    sessionStorage.removeItem("normalleaguesshow");
    let data = '123';
    sessionStorage.setItem("topleagueshow", data);
    this.sportsData = matches;
    if (this.sportsData.Status == 200) {
      this.loaderdata = false;
      this.errorMessageResponse = null;
      this.gameData = this.sportsData.data;
      this.leagueData = this.gameData[0].league;
      this.leaguename = this.gameData[0].league.league;
      this.SportName = this.gameData[0].league.sport;
      this.CountryName = this.gameData[0].league.country;
      this.matchData = this.gameData[0].league.matches;
      this.comingFromSportMenu = true;
    } else {
      this.gameData = null;
      this.loaderdata = false;
      this.errorMessageResponse = this.sportsData.Message;
    }
  }
  getMatches(matches) {
    this.minmaxloader = false;
    this.normalleaguesshow = true;
    this.topleagueshow = false;
    sessionStorage.removeItem("topleagueshow");
    let data = '456';
    sessionStorage.setItem("normalleaguesshow", data);
    this.sportsData = matches;
    if (this.sportsData.Status == 200) {
      this.errorMessageResponse = null;
      this.loaderdata = false;
      this.gameData = this.sportsData.data;
      this.matchData = this.gameData[0].league.matches;

    } else {
      this.gameData = null;
      this.loaderdata = false;
      this.errorMessageResponse = this.sportsData.Message;
    }
  }

  homeodd(data, sdd, country) {
    //this.passwords.comingSoonPopOpen()
    console.log(data, country);
    this.bet = new Betslip(data, sdd, country);
    this.CommondataService.OddData(this.bet);
  }

  selectedodd(ods, type, title, matcnm, matchid) {
    //this.passwords.comingSoonPopOpen()
    console.log(this.selectcountry);
    this.bet = new OddsBetslip(ods, type, title, matcnm, matchid, this.dateTime, this.selectcountry);
    this.CommondataService.OddData(this.bet);
  }
  onclicksportdata() {
    this.errorMessageResponse = null;
    this.gameData = null;
    this.OddMatch = null;
    this.OddData = null;
    this.OddDataerrormessage = null;
    this.closeSportMenuOddsMatches();
    this.loaderdata = true;
  }
  printfeedleagues() {
    sessionStorage.setItem('SportName', this.sName);
    this.router.navigate(['/oddsprint']);
    //this.store.dispatch(new loginActions.PrintFeedOfleagues(this.sName));
    //this._mainServe.printFeedLeaguesData(this.sName).subscribe(leagues => this.printfeedres(leagues));
  }
  minChanged(min) {
    this.minodderrorMessage = null;
    this.minseletedValue = min;
  }
  maxChanged(max) {
    this.minodderrorMessage = null;
    if (this.minseletedValue <= max) {
      this.maxseletedValue = max;
    } else {
      this.minodderrorMessage = 'Please Select Correct Value Based Min Odd Value';
    };
  }
  minandmax() {
    this.minmaxloader = true;
    this.minodderrorMessage = null;
    if (this.minseletedValue != null && this.maxseletedValue != null) {
      if (this.minseletedValue <= this.maxseletedValue) {
        this.maxvaluetrue = true;
        this._mainServe.getTodayoddMatches(this.sName, this.days, this.minseletedValue, this.maxseletedValue).subscribe(matches => this.getMatches(matches),
          err => {

            this.minmaxloader = false;
            this.minodderrorMessage = "No Data Found";
          });
      } else if (this.minseletedValue > this.maxseletedValue) {

        this.minmaxloader = false;
        this.minodderrorMessage = 'Please Select Correct Value Based Min Odd Value';
      }
    } else {
      this.minmaxloader = false;
      this.minodderrorMessage = 'Please Select Min and Max Odd Value';
    };
  }
  onChange(data) {
    this.selectnum = data;
  }
  oddinfo(oddtype) {
    this.oddtypename = oddtype;
    if (oddtype in this.SelectedOddTypeName) {
      delete this.SelectedOddTypeName[oddtype];
    } else {
      this.SelectedOddTypeName[oddtype] = this.oddtypename;

    }
  }
}
