import * as resultActions from './betresultAction';
import { Results } from '../../modules/betresults/results';

export interface betResultState {
    Results:Results;
}
const initialState: betResultState = {
    Results:null,
}

export function betResultReducer(betResultState: betResultState = initialState, action: resultActions.resultActions) {
    switch (action.type) {
        case resultActions.SPORTBOOK_GET_RESULT:
            return {
                ...betResultState,
                Results: null
            }
            break;
        case resultActions.SPORTBOOK_GET_RESULT_SUCCESS:
            return {
                ...betResultState,
                Results: { ...betResultState, ...action.payload }
            }
            break;
        case resultActions.SPORTBOOK_GET_RESULT_FAIL:
            return {
                ...betResultState,
                Results: null
            }
            break;
        default:
            return betResultState;
            break;
    }
}