import { Component, OnInit, HostListener, Input, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { SportsMenuComponent } from '../sports-menu/sports-menu.component';
import { UpdateProfileComponent } from '../update-profile/update-profile.component';
import { PasswordsComponent } from '../passwords/passwords.component';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';
import { LivedealerService } from 'src/app/source/services/livedealer/livedealer.service';
import { MeightService } from 'src/app/source/services/meightservice/meight.service';
import { meightObeject } from 'src/app/source/modules/meight/meightmodal';
import { CockfightService } from 'src/app/source/services/cockfight/cockfight.service';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { Subscription } from 'rxjs';
import { RegisterComponent } from '../register/register.component';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';
import { Profile } from 'src/app/source/modules/player/profile';
import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import { CashierState } from 'src/app/source/appstates/cashierstates/cashierState';
import { Balance, WalletInfo } from 'src/app/source/modules/cashier/balance';
import { ResetPasswordComponent } from '../reset-password/reset-password.component';
import { Router, ActivatedRoute } from '@angular/router';

import { CommondataService } from '../../source/services/sports/commondata.service';
import { LoginService } from 'src/app/source/services/login/login.service';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
  private loginstoreSub: Subscription;
  private tokenstoreSub: Subscription;
  private profilestoreSub: Subscription;
  private storeSub: Subscription;
  Login: boolean = true;
  dropMenu: boolean = false;
  openUpdate: boolean = false;
  fieldTextType: boolean;
  text: any;
  reID: any;
  playerLoggedIn: boolean = false;
  reType: any;
  balance: any;
  playertype: any;
  cashier: Balance;
  playerbonus: any;
  walleteInfo: WalletInfo[];
  profile: Profile;
  errorMessage: string;
  loginloader: boolean = false;
  form: FormGroup;
  profileName: string;
  private closeSub: Subscription;
  meightObeject: meightObeject;
  urlof: string;
  navigationsuccessPop: boolean = false;
  gamelunchmessage: string = "";
  @ViewChild(SportsMenuComponent, { static: false })
  sportComp: SportsMenuComponent;
  selectedlanguege: any;
  @ViewChild(UpdateProfileComponent, { static: false }) updatePro: UpdateProfileComponent;
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;
  @ViewChild(ChangePasswordComponent, { static: false }) changepasswords: ChangePasswordComponent;
  @ViewChild(RegisterComponent, { static: false }) register: RegisterComponent;
  @ViewChild(ResetPasswordComponent, { static: false }) resetPasswordcomp: ResetPasswordComponent;

  constructor(public translate: TranslateService, private route: ActivatedRoute, private router: Router,
    private CommondataService: CommondataService,
    private loginservice: LoginService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private Cockfightservice: CockfightService, private meightservice: MeightService,
    private store: Store<appState.AppState>, private Livedealerservice: LivedealerService,) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    //this.generateCaptchaReset();
    // translate.addLangs(['EN', 'ES', 'DE', 'EL', 'FR', 'RU', 'TR', 'PT']);
    translate.addLangs(['EN', 'ES', 'PT']);
    //translate.setDefaultLang('es');
    let lang = localStorage.getItem('locale');
    if (lang === 'EN' || lang === "ES" || lang == 'PT') {
      const browserLang = localStorage.getItem('locale');
      //translate.use(browserLang.match(/ES|EN||DE|EL|FR|RU|TR|PT/) ? browserLang : 'ES');
      translate.use(browserLang.match(/ES|EN||PT/) ? browserLang : 'EN');
    } else {
      localStorage.setItem('locale', 'EN');
      translate.setDefaultLang('EN');
    }
    this.selectedlanguege = localStorage.getItem('locale');
  }

  ngOnInit() {
    this.router.navigate(['/casino']);

    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
this.balance1()
          this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
        }
      }
    )
    this.profileName = sessionStorage.getItem('profile');
    this.store.dispatch(new loginActions.ResetState());
    this.form = new FormGroup({
      "login": new FormControl('', [Validators.required,  Validators.minLength(4), Validators.maxLength(15) , Validators.pattern('[a-zA-Z0-9]*')]),
      "password": new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(15) , Validators.pattern(/^\S*$/)]),
      // "captcha": new FormControl(null, Validators.required)
    })
    /************************** Jquery start *****************************/
    $(document).ready(function () {
      // if ($(window).width() < 992) {

      // }

      /************************** Menu open close code start *****************************/
      var docHeight = $(document).height();
      if ($(docHeight).height < 100) {
      }
      /*if ($(window).width() < 767) {
        $('.j_menuBtn').click(function () {
          $("#mySidenav").css("width", "200");
        });

        $(document).click(function () {
          popups_close();
        })
        $(".close_Menu").click(function () {
          popups_close();
        })

        $('#mySidenav, .j_menuBtn').click(function (e) {
          e.stopPropagation();
        })

        function popups_close() {
          $("#mySidenav").css("width", "0");
        }

        $(".navUL li").click(function () {
          popups_close();
        });
      }*/

      // $('.test').click(function () {
      //   console.log("------------------------------logpop------------------------------");
      //   $('.popup_md, .logCover').show();
      // })
      // $('.close_log, .logCover').click(function () {
      //   $('.popup_md, .logCover').hide();
      // })

      /************************** Menu open close code end *****************************/

      $('.logInPop').click(function () {
        console.log('check')
        $('.popup_md, .logCover').show();
      })
      $('.close_log, .logCover').click(function () {
        $('.popup_md, .logCover').hide();
      })
      $('.drop_Menu').click(function () {
        $('.drop_Menu').hide();
      });

      $(".navUL li").click(function () {
        $(".navUL li").removeClass("active");
        $(this).addClass("active");
      });
      if ($(window).width() < 1200) {
        $('.close_log_box').click(function () {
          $('.popup_md, .logCover').hide();
        })
      }

    });
    /************************** Jquery end *****************************/
    // this.storeSub = this.store.select("playerState").subscribe(
    //   (playerState: ProfileState) => {
    //     if (playerState.Casinovivoplayer) {
    //       if (playerState.Casinovivoplayer['status'] == true) {

    //       } else if (playerState.Casinovivoplayer['success'] == false) {
    //         this.gamelunchmessage = playerState.Casinovivoplayer['description'];
    //         this.navigationsuccessPop = true;
    //       }
    //     }
    //     if (playerState.CasinovivoplayerError) {
    //       this.gamelunchmessage = playerState.CasinovivoplayerError.message;
    //       this.navigationsuccessPop = true;
    //     }
    //   })
    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.loginResponse) {
          if (loginState.loginResponse.success === true) {
            this.balance1()

            this.loginloader = false;
            if (loginState.loginResponse.description == "LOGIN_TMP_PASSWORD") {
              this.changePassword();
            }
          } else if (loginState.loginResponse.success === false) {
            this.errorMessage = loginState.loginResponse.description;
            this.loginloader = false;
            //this.generateCaptchaReset();
            //this.toastr.error(loginState.loginResponse.description);
          }
        }
        if (loginState.loginErrorResponse) {
          this.errorMessage = loginState.loginErrorResponse.message;
          this.loginloader = false;
        }
      }
    )

    this.profilestoreSub = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.profile) {
          this.profile = playerState.profile;
          this.profileName = this.profile.login;
        }
      }
    )
    this.tokenstoreSub = this.store.select("tokenState").subscribe(
      (tokenState: tokenState) => {
        if (tokenState.sBookLogin) {
          if (tokenState.sBookLogin.accessToken != null) {
            this.form.reset();
            //this.store.dispatch(new cashierActions.CashierGetBalanceStart());
            //this.store.dispatch(new loginActions.ResetState());
          } else if (tokenState.sBookLogin.accessToken == null) {
            this.loginloader = false;
            this.errorMessage = tokenState.sBookLogin.message;
          }
        }

        if (tokenState.sBookLoginErrorResponse) {

          this.loginloader = false;
          this.errorMessage = tokenState.sBookLoginErrorResponse.message;
        }
      }
    )

    this.loginstoreSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.logoutResponse) {
          if (loginState.logoutResponse.success === true) {
            this.loginloader = false;
          }
          if (loginState.logoutResponse.success === false) {
            this.loginloader = false;
          }
        }

        if (loginState.logoutResponseError) {
          this.loginloader = false;
        }
      }


    )

  }
balance1(){
  this.storeSub = this.store.select("cashierState").subscribe(
    (cashierState: CashierState) => {
      if (cashierState.balance) {
        if (cashierState.balance.success == true) {
          this.loginloader = false;
          this.cashier = cashierState.balance;
          this.walleteInfo = cashierState.balance.values;
          for (let wallet of this.walleteInfo) {
            if (wallet.wallet.name != 'PLAYMONEY') {
              this.balance = wallet.cash.value + wallet.bonus.value;
              this.playertype = wallet.wallet.name;
              sessionStorage.setItem("PlayerCurrency", this.playertype);
              this.playerbonus = wallet.bonus.value;
            } else {
            }
          }
        } else if (cashierState.balance.success == false) {

          this.loginloader = false;
        }
      }

    }
  )
}
  proDrop() {
    this.dropMenu = true;
  }
  dropHide() {
    this.dropMenu = false;
  }
  onClickLogout() {
    this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new loginActions.LogoutStart());
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  updateProfile() {
    this.updatePro.openUpdatePop();
  }
  changePassword() {
    this.changepasswords.openPasswordPop();
  }
  forgotPopup() {
    this.passwords.openForgotPasswordPop();
  }
  registrationPopup() {
 
    console.log(this.errorMessage)
    if ($(window).width() < 992) {
      $('.popup_md').hide();
    }
    this.store.dispatch(new loginActions.ResetState());
    this.register.openRegisterPop()
  }
  resetPassword() {
    this.resetPasswordcomp.openrestpassword();
  }
  onSubmit() {
    sessionStorage.clear();
    localStorage.clear();
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('wsession');
    sessionStorage.removeItem('profile');
    sessionStorage.removeItem('playermobilenumber');
    this.loginloader = true;
    this.store.dispatch(new loginActions.ResetState());
    this.store.dispatch(new loginActions.LoginStart(this.form.value));

  }
  @HostListener("click", ["$event"])
  public onClick(event: any) {
    this.dropMenu = false;
    // console.log('body click');
    $('.CountriesOpen').hide();
    $('.country_List').hide();
    $('.LeaguesOpen').hide();
  }


  // @HostListener('window:scroll', ['$event'])
  // onWindowScroll(e) {
  //   if (window.pageYOffset > 150) {
  //     let Fixit = document.getElementById('mySidenav');
  //     Fixit.classList.add('fixMenu');
  //   } else {
  //     let Fixit = document.getElementById('mySidenav');
  //     Fixit.classList.remove('fixMenu');
  //   }
  // }
  liveDealer() {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    if (this.playerLoggedIn == true) {
      let logiName = sessionStorage.getItem('profile');
      this.Livedealerservice.liveDealerServiceData(logiName).subscribe(liveDealerdata => {
        this.liveDealerunch(liveDealerdata);
      });
    } else if (this.playerLoggedIn == false) {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }

  liveDealerunch(data) {
    if(data['STATUS'] == 'FAILED'){

      this.gamelunchmessage = "Temporarily Unavailable";
      this.navigationsuccessPop = true;
    }else{
      
    let ezugiLunch = data.EZUGI_GAME_LAUNCH_URL;
    window.open(ezugiLunch, '_blank');
    }
  }

  //m8 start

  meightBet() {

    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    if (this.playerLoggedIn == true) {
      let logiName = sessionStorage.getItem('profile');
      this.meightservice.meightServiceData(logiName).subscribe(meightdata => {
        this.meightLunch(meightdata);
      });
    } else if (this.playerLoggedIn == false) {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }
  meightLunch(data) {
    let ua = window.navigator.userAgent.toLowerCase();
    this.meightObeject = data;
    if (this.meightObeject.STATUS == 'FAILED') {
      this.gamelunchmessage = this.meightObeject.STATUS;
      this.navigationsuccessPop = true;
    } else {
      if (ua.match(/ipad/i) !== null)
        return window.open(this.meightObeject.mobileurlsecure, '_blank');
      if (ua.match(/iphone/i) !== null)
        return window.open(this.meightObeject.mobileurlsecure, '_blank');
      if (ua.match(/android/i) !== null)
        return window.open(this.meightObeject.mobileurlsecure, '_blank');
      if (ua.match(/chrome/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/firefox/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/webkit/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/gecko/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      if (ua.match(/opera/gi) !== null)
        return window.open(this.meightObeject.weburlsecure, '_blank');
      return null



    }
  }

  cockFight() {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    if (this.playerLoggedIn == true) {
      let logiName = sessionStorage.getItem('profile');
      this.Cockfightservice.cockFightServiceData(logiName).subscribe(cockfightdata => {
        this.cockFightLunch(cockfightdata);
      });
    } else if (this.playerLoggedIn == false) {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }
  cockFightLunch(data) {
    let sabongLunch = data.SABONG_GAME_LAUNCH_URL;
    window.open(sabongLunch, '_blank');
  }
  comingsoon() {
    this.passwords.comingSoonPopOpen()

  }

  /*
      function openNav() {
        document.getElementById("mySidenav").style.width = "200px";
      }
      function closeNav() {
        document.getElementById("mySidenav").style.width = "0";
      }*/


  navigationPopClose() {
    this.navigationsuccessPop = false;
  }


  glodenracegamelunch() {
    if (this.playerLoggedIn == true) {
      this.router.navigate(['/virtuals']);
    } else if (this.playerLoggedIn == false) {
      this.gamelunchmessage = "Please login";
      this.navigationsuccessPop = true;
    }
  }
  switchLang(lang: string) {
    if (this.selectedlanguege == lang) {

    } else {
      localStorage.removeItem('locale');
      localStorage.setItem('locale', lang);
      this.translate.use(lang);
      this.selectedlanguege = localStorage.getItem('locale');
      location.reload();
    }
  }
  casinovivo(event) {
    if (this.playerLoggedIn) {
      if (event == 'betSoft') {
        this.router.navigate(['/betSoft']);
      } else if (event == 'nucleus') {
        this.router.navigate(['/nucleus']);
      }else if (event == 'spinomenal') {
        this.router.navigate(['/spinomenal']);
      }else if (event == 'tomHorn') {
        this.router.navigate(['/tomHorn']);
      }else if (event == 'platipus') {
        this.router.navigate(['/platipus']);
      }else {
      this.navigationsuccessPop = true;
      this.gamelunchmessage = "somathing went wrong";
    }
  }else {
    
    this.navigationsuccessPop = true;
    this.gamelunchmessage = "Please login";
  }
}
userNameValid(event){ 
  var k;  
   k = event.charCode;    
   return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 57)); 
} 
passwordValid(event) {
  var k;
  k = event.charCode; 
  return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k >= 33 && k <= 47 || k >= 60 && k <= 64 || (k >= 48 && k <= 58) || k >= 91 && k <= 96 || k >= 123 && k <= 126);
}
}
