import { Betslip } from '../specific/odds.model';
export class BetRequest {
    betType : number;
    betslip : Array<Betslip>;
    betAmount: number;
    acceptOddChange : boolean;
   /// captcha : any;
    isRemote:boolean;
    totalOdds:number;
    possiblePayout:number;
    possibleBonus:number;
    possibleWinnings:number;
    bonusPercentage:number;
    selections:number;
    totalAomunt:number;
    constructor(betType : number, betslip : Array<Betslip>, betAmt : any, oddChange : boolean, betcalCulation:any){
        this.betType = betType;
        this.betslip = betslip;
        this.betAmount = betAmt;
        this.acceptOddChange = true;
        //this.captcha = captcha;
        //captcha : any,
        this.isRemote=false;
        this.totalOdds = betcalCulation.totalOdds;
        this.possiblePayout=betcalCulation.possiblePayout;
        this.possibleBonus= betcalCulation.possibleBonus;
        this.possibleWinnings =betcalCulation.possibleWinnings;
        this.bonusPercentage=betcalCulation.bonusPercentage;
        this.selections=betcalCulation.selections;
        this.totalAomunt=betcalCulation.totalAomunt;
    }
}
