import { Action } from '@ngrx/store';
import { Countries } from '../../modules/login/countries';
import { Login } from '../../modules/login/login';
import { LoginOut } from '../../modules/login/logout';
import { registerForm } from '../../modules/register/register';
import { forgotResponse } from '../../modules/forgotpassword';
import { ResetPassword } from '../../modules/login/resetpassword';
import { PrintLeaguesRes } from '../../modules/common/printfeedleagues';
import { PrintFeedOddsRes } from '../../modules/common/printfeedodds';
import { AllGames } from 'src/app/skins/slotsmodel/allgames';
import { AllGameLunch } from 'src/app/skins/slotsmodel/allgamelunch';

export const RESET_STATE = "[login] RESET_STATE";
export const PLAYER_LOGGEDIN = "[login] PLAYER_LOGGEDIN";

export const LOGIN_START = "[login] LOGIN_START";
export const LOGIN_SUCCESS = "[login] LOGIN_SUCCESS";
export const LOGIN_FAIL = "[login] LOGIN_FAIL";

export const LOGOUT_START = "[login] LOGOUT_START";
export const LOGOUT_SUCCESS = "[login] LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "[login] LOGOUT_FAIL";

export const FORGOTPASSWORD_START = "[login] FORGOTPASSWORD_START";
export const FORGOTPASSWORD_SUCCESS = "[login] FORGOTPASSWORD_SUCCESS";
export const FORGOTPASSWORD_FAIL = "[login] FORGOTPASSWORD_FAIL";

export const RESETPASSWORD_START = "[login] RESETPASSWORD_START";
export const RESETPASSWORD_SUCCESS = "[login] RESETPASSWORD_SUCCESS";
export const RESETPASSWORD_FAIL = "[login] RESETPASSWORD_FAIL";

export const REGISTER_START = "[login] REGISTER_START";
export const REGISTER_SUCCESS = "[login] REGISTER_SUCCESS";
export const REGISTER_FAIL = "[login] REGISTER_FAIL";

export const REGISTER_GET_COUNTRY = "[login] REGISTER_GET_COUNTRY";
export const REGISTER_GET_COUNTRY_SUCCESS = "[login] REGISTER_GET_COUNTRY_SUCCESS";
export const REGISTER_GET_COUNTRY_FAIL = "[login] REGISTER_GET_COUNTRY_FAIL";

export const REGISTER_GENERATE_OTP = "[login] REGISTER_GENERATE_OTP";
export const REGISTER_GENERATE_OTP_SUCCESS = "[login] REGISTER_GENERATE_OTP_SUCCESS";
export const REGISTER_GENERATE_OTP_FAIL = "[login] REGISTER_GENERATE_OTP_FAIL";

export const PRINT_FEED_LEAGUES_START = "[login] PRINT_FEED_LEAGUES_START";
export const PRINT_FEED_LEAGUES_SUCCESS = "[login] PRINT_FEED_LEAGUES_SUCCESS";
export const PRINT_FEED_LEAGUES_FAIL = "[login] PRINT_FEED_LEAGUES_FAIL";

export const PRINT_ODDS_START = "[login] PRINT_ODDS_START";
export const PRINT_ODDS_SUCCESS = "[login] PRINT_ODDS_SUCCESS";
export const PRINT_ODDS_FAIL = "[login] PRINT_ODDS_FAIL";

export const REGISTER_STATE = "[login] REGISTER_STATE";


export const ALL_GAMES_START = "[login] ALL_GAMES_START";
export const ALL_GAMES_SUCCESS = "[login] ALL_GAMES_SUCCESS";
export const ALL_GAMES_FAIL = "[login] ALL_GAMES_FAIL";

export const ALL_GAMES_LUNCH_START = "[login] ALL_GAMES_LUNCH_START";
export const ALL_GAMES_LUNCH_SUCCESS = "[login] ALL_GAMES_LUNCH_SUCCESS";
export const ALL_GAMES_LUNCH_FAIL = "[login] ALL_GAMES_LUNCH_FAIL";
export class registermessage implements Action{
    readonly type = REGISTER_STATE;
    constructor(){ }
}
export class ResetState implements Action{
    readonly type = RESET_STATE;
    constructor(){ }
}
export class PlayerLoggedIn implements Action{
    readonly type = PLAYER_LOGGEDIN;
    constructor(public payload:{loggedIn:boolean}){ }
}

export class LoginStart implements Action{
    readonly type = LOGIN_START;
    constructor(public payload:Object){ }
}
export class LoginSuccess implements Action{
    readonly type = LOGIN_SUCCESS;
    constructor(public payload:Login){}
}
export class LoginFail implements Action{
    readonly type = LOGIN_FAIL;
    constructor(public payload:Object){}
}

export class LogoutStart implements Action{
    readonly type = LOGOUT_START;
    constructor(){ }
}
export class LogoutSuccess implements Action{
    readonly type = LOGOUT_SUCCESS;
    constructor(public payload:LoginOut){}
}
export class LogoutFail implements Action{
    readonly type = LOGOUT_FAIL;
    constructor(public payload:Object){}
}

export class ForgotPasswordStart implements Action{
    readonly type = FORGOTPASSWORD_START;
    constructor(public payload:Object){ }
}
export class ForgotPasswordSuccess implements Action{
    readonly type = FORGOTPASSWORD_SUCCESS;
    constructor(public payload:forgotResponse){}
}
export class ForgotPasswordFail implements Action{
    readonly type = FORGOTPASSWORD_FAIL;
    constructor(public payload:forgotResponse){}
}

export class ResetPasswordStart implements Action{
    readonly type = RESETPASSWORD_START;
    constructor(public payload:Object){ }
}
export class ResetPasswordSuccess implements Action{
    readonly type = RESETPASSWORD_SUCCESS;
    constructor(public payload:ResetPassword){}
}
export class ResetPasswordFail implements Action{
    readonly type = RESETPASSWORD_FAIL;
    constructor(public payload:object){}
}

export class RegisterStart implements Action{
    readonly type = REGISTER_START;
    constructor(public payload:registerForm){ }
}
export class RegisterSuccess implements Action{
    readonly type = REGISTER_SUCCESS;
    constructor(public payload:Login){}
}
export class RegisterFail implements Action{
    readonly type = REGISTER_FAIL;
    constructor(public payload:Object){}
}

export class RegisterGetCountry implements Action{
    readonly type = REGISTER_GET_COUNTRY;
    constructor(){}
}

export class RegisterGetCountrySuccess implements Action{
    readonly type = REGISTER_GET_COUNTRY_SUCCESS;
    constructor(public payload:Countries){}
}
export class RegisterGetCountryFail implements Action{
    readonly type = REGISTER_GET_COUNTRY_FAIL;
    constructor(public payload:Object){}
}

export class RegisterGenarateOTP implements Action{
    readonly type = REGISTER_GENERATE_OTP;
    constructor(public payload:Object){ }
}
export class RegisterGenarateOTPSuccess implements Action{
    readonly type = REGISTER_GENERATE_OTP_SUCCESS;
    constructor(public payload:Object){ }
}
export class RegisterGenarateOTPFail implements Action{
    readonly type = REGISTER_GENERATE_OTP_FAIL;
    constructor(public payload:Object){ }
}
export class PrintFeedOfleagues implements Action{
    readonly type = PRINT_FEED_LEAGUES_START;
    constructor(public payload:Object){
     }
}
export class PrintFeedOfleaguesSuccess implements Action{
    readonly type = PRINT_FEED_LEAGUES_SUCCESS;
    constructor(public payload:PrintLeaguesRes){ }
}
export class PrintFeedOfleaguesFail implements Action{
    readonly type = PRINT_FEED_LEAGUES_FAIL;
    constructor(public payload:Object){ }
}
export class PrintFeedOfOdds implements Action{
    readonly type = PRINT_ODDS_START;
    constructor(public payload:Object){
     }
}
export class PrintFeedOfOddsSuccess implements Action{
    readonly type = PRINT_ODDS_SUCCESS;
    constructor(public payload:PrintFeedOddsRes){ }
}
export class PrintFeedOfOddsFail implements Action{
    readonly type = PRINT_ODDS_FAIL;
    constructor(public payload:Object){ }
}
export class allGamesStart implements Action{
    readonly type = ALL_GAMES_START;
    constructor(){
     }
}
export class allGamesSuccess implements Action{
    readonly type = ALL_GAMES_SUCCESS;
    constructor(public payload:AllGames){ }
}
export class allGamesFail implements Action{
    readonly type = ALL_GAMES_FAIL;
    constructor(public payload:Object){ }
}
export class allGamesLunchStart implements Action{
    readonly type = ALL_GAMES_LUNCH_START;
    constructor(public payload:Object){
     }
}
export class allGamesLunchSuccess implements Action{
    readonly type = ALL_GAMES_LUNCH_SUCCESS;
    constructor(public payload:AllGameLunch){ }
}
export class allGamesLunchFail implements Action{
    readonly type = ALL_GAMES_LUNCH_FAIL;
    constructor(public payload:Object){ }
}
export type LoginActions = ResetState|PlayerLoggedIn|
LoginStart|LoginSuccess|LoginFail|
LogoutStart|LogoutSuccess|LogoutFail|
RegisterStart|RegisterSuccess|RegisterFail|
ForgotPasswordStart|ForgotPasswordSuccess|ForgotPasswordFail|
RegisterGetCountry|RegisterGetCountrySuccess|RegisterGetCountryFail|
ResetPasswordStart|ResetPasswordSuccess|ResetPasswordFail|
RegisterGenarateOTP|RegisterGenarateOTPSuccess|RegisterGenarateOTPFail|registermessage|
PrintFeedOfleagues|PrintFeedOfleaguesSuccess|PrintFeedOfleaguesFail|
PrintFeedOfOdds|PrintFeedOfOddsSuccess|PrintFeedOfOddsFail;