import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { WebsocketService } from './websocket.service';
import { ConditionalExpr } from '@angular/compiler';
import { environment } from '@app/env';
@Injectable({
  providedIn: 'root'
})
export class WebService {
  hostName = window.location.host;
  siteid = environment.skinId;
  folder = localStorage.getItem('locale');
  // hostName = '10.10.20.54:9090';
  // siteid = 'redsonbet-dev';
  // folder = 'PT';
  protocal = window.location.protocol;
  //socketUrl = CHAT_URL;
  //socketUrl="ws://"+this.hostName+"/ws";
  socketUrl: string;
  constructor(private wsService: WebsocketService) {
    // if (this.protocal == 'https:') {
    //   this.socketUrl = "wss://" + this.hostName + "/ws?" + this.siteid + "," + this.folder;
    // } else {
    //   this.socketUrl = "ws://" + this.hostName + "/ws?" + this.siteid + "," + this.folder;
     
    // }
    if (this.protocal == 'https:') {
      this.socketUrl = "wss://" + this.hostName + "/ws?";
    } else {
      this.socketUrl = "ws://" + this.hostName + "/ws?";

    }
  }
  send() {
    this.wsService.connect(this.socketUrl);
  }

}
