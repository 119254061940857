import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment} from '@app/env';
import { Countries } from '../../modules/login/countries';
import { Login } from '../../modules/login/login';
import { PlayerService } from '../player/player.service';
import { Store } from '@ngrx/store';
import * as appState from '../../appstates/appState';
import { PlayerLoggedIn, LogoutStart, registermessage } from '../../appstates/loginstates/loginActions';
import { PlayerGetProfile } from '../../appstates/playerstates/playerActions';
import { Observable } from 'rxjs';
import { CashierGetBalanceStart } from '../../appstates/cashierstates/cashierActions';
import { ResetPassword } from '../../modules/login/resetpassword';
import { GrGamesLunch } from '../../appstates/sblogin/sbloginAction';
import { PrintLeaguesRes } from '../../modules/common/printfeedleagues';
import { PrintFeedOddsRes } from '../../modules/common/printfeedodds';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private _jsonURL = 'assets/Print_json.json';
  private countries = 'assets/countries.json';
  constructor(private httpClient: HttpClient,
    private playerService: PlayerService,
    private store: Store<appState.AppState>,
    private loginservice:LoginService,
     private router: Router) { }


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'siteid': environment.skinId
    })
  };
  httpOptionsdata = {
    responseType: 'blob' as 'json',
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'siteid': environment.skinId
    })
  };
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'siteid': environment.skinId
  });
  onGetPlayerBalance(){
    this.store.dispatch(new CashierGetBalanceStart());
    this.store.dispatch(new GrGamesLunch());
  }
  sessinExpireToLogout(){
    this.store.dispatch(new LogoutStart());
  }
  onPlayerLoggedIn(value: boolean) {
    this.store.dispatch(new PlayerLoggedIn({ loggedIn: value }));
  }

  onLogin(postdata) {
    return this.httpClient.post<Login>(`${environment.appApi.baseUrl}${environment.appApi.login}`, postdata, this.httpOptions);
  }
  // onGenerateCaptchaStart():Observable<Blob>{
  //   return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.generateCaptcha}`,
  //    {},{headers: this.headers, responseType:'blob'});
  // }
  onLogOut() {
    return  this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.logout}`, {}, this.playerService.httpOptions());
    // sessionStorage.removeItem('accessToken');
    // sessionStorage.removeItem('wsession');
    // sessionStorage.removeItem('profile');
    // sessionStorage.removeItem('playermobilenumber');
    // sessionStorage.clear();
    // localStorage.clear();
    // this.onPlayerLoggedIn(false);
    // this.router.navigate(['/preMatch']);
    //return;
  }
  onRegister(postdata) {
    return this.httpClient.post<Login>(`${environment.appApi.baseUrl}${environment.appApi.register}`, postdata, this.httpOptions);
  }
  onGetRegisterCountry() {
    return this.httpClient.get(this.countries);
  }
  // onGetRegisterCountry() {
  //   return this.httpClient.post<Countries>(`${environment.appApi.baseUrl}${environment.appApi.getCountries}`, {}, this.httpOptions);
  // }
  onForgotPassword(postdata) {
    return this.httpClient.post(`${environment.appApi.baseUrl}${environment.appApi.fotgotPassword}`, postdata, this.httpOptions);
  }
  onResetPassword(postdata) {
    return this.httpClient.post<ResetPassword>(`${environment.appApi.baseUrl}${environment.appApi.resetPassword}`, postdata, this.httpOptions);
  }
  printFeedOddsData(postdata) {
    return this.httpClient.post<Blob>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.printodds}`, postdata, this.httpOptionsdata);
    //return this.httpClient.post<PrintFeedOddsRes>('http://10.10.20.54:9090/sbapi/pms/print/odds', postdata, this.httpOptions);
  }
  // public printFeedOddsData(postdata): Observable<any> {
  //   return this.httpClient.get(this._jsonURL);
  // }
  getMyprofile(){
    this.store.dispatch(new PlayerGetProfile());
  }
  registerMessage(){
    
    this.store.dispatch(new registermessage());
  }
  onRegisterGenarateOTPStart(postdata) {
    return this.httpClient.post(postdata, this.httpOptions);
  }
  printFeedLeaguesData(sportname){
    return this.httpClient.get<PrintLeaguesRes>(`${environment.sbBook.sbBaseUrl}${environment.sbBook.printleagues}/${sportname}`, this.httpOptions);
  }
}
