import { Component, ComponentFactoryResolver, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store'; 
import { SlotsService } from 'src/app/source/services/slots.service';
import * as appState from 'src/app/source/appstates/appState';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions'
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs'; 
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PlayerService } from 'src/app/source/services/player/player.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'; 


declare var device;
@Component({
  selector: 'app-livedealer',
  templateUrl: './livedealer.component.html',
  styleUrls: ['./livedealer.component.scss'],
})
export class livedealerComponent implements OnInit {
  playerName: string;
  slotsdata: any; 
  playerLoggedIn: boolean = false;
  private storeSub: Subscription;
  isMobile: boolean = false;
  showLogPopup: boolean = false;
  livedealerGames: any;
  wacgames: any;
  // liveCasinoGmList = [];

  tabGameName: any;
  casinogamessuccessPop: boolean = false;
  vituvalIdArry = [];
  gameIdArr = [];
  gameToken = "";
  gameId = "";
  liveCasinoGmList: any;
  gameName: string;
  auraGamesList: any;
  betGamesLottery: any = [{
    "id": "lucky-streek",
    "name": "Lucky Streek",
    "provider": "7Mojos Live",
    "categories": [
      "live-dealer"
    ],
    "media": {
      "icon": "assets/imgs/livecasino/lucky-streak.png",

    }
  }];
  openDiv: boolean = false;
  closeDiv: boolean = false;
  closeopenDiv: boolean = false;
  actTab: any;
  Tablegsports: any;
  Tablegames: any;
  wacGamesList: any;

  // livegames :boolean =  false;
  // virtualgames :boolean = false; 
  loader_game: any = '';
  windowView: boolean = false;
  mobileView: boolean = false;
  gameInLoader: boolean;
  WacSlotGames: any = [];
  tokendata: any;
  gamelunchurl: string;
  name: string;
  tokendataurl: any;
  url: string;
  hidelive: boolean = true;
  hideurl: boolean = true;
  openDiv1: boolean = false;
  evogam: boolean = false;
  headerdiv: boolean = false;
  urlSafe: SafeResourceUrl;
  opengam: any
  androiios: boolean = false;
  // windows: boolean = false;
  iphone: boolean = false;
  loaderpage:boolean =false
  private closeSub: Subscription;
  pragmaticeLivesection:boolean = false; 
  pragmaticLivecasino: any =[]
  setTimeClose:any;
  providerList: any;
  providerList2: any;
  pragmaticLCtab:boolean = true
  vivoGamestab:boolean = true
  EvolutionTablegames: any;
  EvolutionTablePremium: any;
  EzugiRegularGames: any;
  EzugiPremiumGames: any;
  Gamespage:boolean = true
  tableIdname: any;
  EzugiStansardperm: boolean = true
  EzugiPreperm: boolean =true
  EvoStansardperm: boolean =true
  EvoPreperm: boolean = true
  constructor(public sanitizer: DomSanitizer,   private router: Router, private componentFactoryResolver: ComponentFactoryResolver, private SlotsService: SlotsService, private store: Store<appState.AppState>, private playerService: PlayerService) {
    // this.store.dispatch(new cashierActions.CashierGetBalanceStart)
    // this.SlotsService.getlottery().subscribe(token => { this.getToken(token) });
 
    if (window.innerWidth >= 767) {
      this.windowView = true;
      this.mobileView = false;
    } else {
      this.windowView = false;
      this.mobileView = true;
    }
  }

  ngOnInit() {
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.playerLoggedIn) {
          if (loginState.playerLoggedIn.loggedIn) {
            this.playerLoggedIn = loginState.playerLoggedIn.loggedIn;
            this.getproviderList12()
          } else {
            this.playerLoggedIn = false;
            document.getElementById('margin_top').style.display = "none";
          }
        }
      })
    this.SlotsService.liveCasinoGames().subscribe(data => this.liveGamesList(data))
    // this.gamesService.getGames().subscribe(data => this.wacgameslive(data))
    // this.gamesService.getevo().subscribe(data => this.wacgameslive(data))
    setTimeout(()=>{
      if (this.playerLoggedIn == true) {
     let VivoGames = sessionStorage.getItem("vivogaming")
     console.log(VivoGames)
    //  if(VivoGames == "true") {
    //  }else if (VivoGames == "false"){
    //    this.router.navigate(['/home'])
    //  }
  } 
    },800)
    setInterval(()=>{
      if(this.playerLoggedIn){
          this.getproviderList12()
        }
      }, 300000)


      this.SlotsService.evoTableregular().subscribe(data => this.evoTablejsres(data))
    this.SlotsService.evoTablePremium().subscribe(data => this.evoTablePremiumres(data))
    this.SlotsService.EzugiStanded().subscribe(data => this.ezugiRegulargms(data))
    this.SlotsService.EzugiPremium().subscribe(data => this.ezugiPremiumgms(data))
  }
  evoTablejsres(data){
    this.EvolutionTablegames = data.gamesList
 }
 evoTablePremiumres(data){
    this.EvolutionTablePremium = data.gamesList
 }
 ezugiRegulargms(data){
   this.EzugiRegularGames =  data.gamesList
 }
 ezugiPremiumgms(data){
   this.EzugiPremiumGames =  data.gamesList
   console.log(this.EzugiPremiumGames )
 }
 GetTableGames(gamename:any){
  this.tabGameName = gamename
  // this.Gamespage = false
 }
  getproviderList12(){
    // alert("enter")
    console.log("livecasino")
    this.pragmaticLCtab = false
    this.vivoGamestab = false
    this.EzugiStansardperm = false
  this.EzugiPreperm = false
  this.EvoStansardperm = false
  this.EvoPreperm = false
        this.providerList2 = []
        this.providerList = []
        let loginName = localStorage.getItem('rrrUserName')
          var body = {
            role:0,
            loginName: loginName
           }
          console.log(body)
          // this.SlotsService.getpermissionList(body).subscribe(data => { 
          //      console.log(data)
          //      this.providerList =  data 
          //      for(let i=0; i<this.providerList.length; i++){ 
          //       if(this.providerList[i].status == false){
          //         console.log(this.providerList[i].name)
          //         if(this.providerList[i].name.toLowerCase() == "pragmaticplaylivecasino"){
          //           this.pragmaticLCtab = false
          //         }
          //         if(this.providerList[i].name.toLowerCase() == "vivogaming"){
          //           this.vivoGamestab = false
          //         }
          //         if(this.providerList[i].name.toLowerCase() == "ezugistandard"){
          //           this.EzugiStansardperm = false
          //         }
          //         if(this.providerList[i].name.toLowerCase() == "ezugipremium"){
          //           this.EzugiPreperm = false
          //         }
          //         if(this.providerList[i].name.toLowerCase() == "evolutionstandard"){
          //           this.EvoStansardperm = false
          //         }
          //         if(this.providerList[i].name.toLowerCase() == "evolutionpremium"){
          //           this.EvoPreperm = false
          //         }
          //       }else{
          //         console.log(this.providerList[i].name)
          //         if(this.providerList[i].name.toLowerCase() == "pragmaticplaylivecasino"){
          //           this.pragmaticLCtab = true
          //         }
          //         if(this.providerList[i].name.toLowerCase() == "vivogaming"){
          //           this.vivoGamestab = true
          //         }
          //         if(this.providerList[i].name.toLowerCase() == "ezugistandard"){
          //           this.EzugiStansardperm = true
          //         }
          //         if(this.providerList[i].name.toLowerCase() == "ezugipremium"){
          //           this.EzugiPreperm = true
          //         }
          //         if(this.providerList[i].name.toLowerCase() == "evolutionstandard"){
          //           this.EvoStansardperm = true
          //         }
          //         if(this.providerList[i].name.toLowerCase() == "evolutionpremium"){
          //           this.EvoPreperm = true
          //         }
          //       }
          //     }
               
          // })
  }
  livecasino123() {
    if (this.playerLoggedIn == true) {
      let logiName = sessionStorage.getItem('profile');
      let wsessionId = sessionStorage.getItem('wsession') 
      // this.SlotsService.liveDealerServiceData(wsessionId).subscribe(liveDealerdata => {
      //   this.liveDealerunch(liveDealerdata);
      // });
    } else if (this.playerLoggedIn == false) {
 
      // this.casinogamessuccessPop = true;
    }
  }
  livecasino12() {
    this.hidelive = false;
    this.openDiv1 = true;
    this.evogam = false
    this.headerdiv = true;
    window.scroll(0, 0)
  }
  vivogames12() {
    if (this.playerLoggedIn == true) {
      let ProName = sessionStorage.getItem('wsession')
      this.SlotsService.vivogame().subscribe((response) => { this.vivogamesurl(response); });
      // window.location.reload();
    } else if (this.playerLoggedIn == false) {
      // let ProName = sessionStorage.getItem('wsession')
      // this.SlotsService.vivogamebeforelogin().subscribe((response) => {
      //   console.log(response),
      //   this.vivogamesurl(response);
      // });
     
      // this.casinogamessuccessPop = true;
    }
  }
    
  vivogamesurl(data) {
    console.log(data.VIVO_GAME_LAUNCH_URL)
   
    if (this.urlSafe) {
      this.hideurl = false
    }
  }
  livereload() {

    window.location.reload();
  }
  liveGamesList(data) {
    console.log(data)
    console.log(data.livecasino)
    this.liveCasinoGmList = data.livecasino;
     this.liveCasinoGmList = data.livecasino.filter(Obj => Obj.provider !== "Vivo");
    console.log(this.liveCasinoGmList)

  } 
 
  openFawk() {
    this.SlotsService.getlottery().subscribe(slotdata => { this.getFawkGames(slotdata) });
    this.playerName = sessionStorage.getItem('profile');

  }
  getFawkGames(data) {
    if (this.playerLoggedIn) {
      if (this.isMobile) {
        window.open(data.FAWK_MOBILE_LAUNCH_URL)
      } else {
        window.open(data.FAWK_DESKTOP_LAUNCH_URL)

      }
    } else {
    }
  }
  liveDealer(gname) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.gameName = gname;
    if (this.playerLoggedIn == true) {
      let logiName = sessionStorage.getItem('wsession');
      console.log(logiName)
      this.SlotsService.liveDealerServiceData(logiName).subscribe(liveDealerdata => {
        this.liveDealerunch(liveDealerdata);
      });
    } else if (this.playerLoggedIn == false) {
      this.casinogamessuccessPop = true;
    }
  }
  liveDealerunch(data) {
    let ezugiLunch = data.EZUGI_GAME_URL + 'token=' + data.EZUGI_TOKEN + '&operatorId=' + data.EZUGI_OPERATOR_ID + '&clientType=html5&language=en' + this.gameName;
    // window.open(ezugiLunch, '_self');
    sessionStorage.setItem("gameurl", ezugiLunch)
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(ezugiLunch)
    if (this.urlSafe) {
      this.hideurl = false
      this.loaderpage = true
      setTimeout(()=>{
        this.loaderpage = false
      },2000)
    }
  }
  getEzugiGames(data) {
    console.log(data)
    if (this.playerLoggedIn) {
      window.open(data.EZUGI_GAME_LAUNCH_URL)
    } else {
    }
  }
  slotsGameLunchreal(id: any, providername: any) {
    // this.urlSafe = null;
    this.store.dispatch(new loginActions.ResetState());
    if (this.playerLoggedIn == true) {
      if (id == "lucky-streek") {
        this.SlotsService.getlivedealergames().subscribe(data => this.livedealerdata(data))
      } else {
        const body = {
          gameId: id,
          gameMode: 'real_play',
          provider: providername
        }
        this.store.dispatch(new loginActions.allGamesLunchStart(body));
      }
    } else if (this.playerLoggedIn == false) {
      this.casinogamessuccessPop = true;
    }
  }
  livedealerdata(data) {
    console.log(data.FRESH_DECK_LAUNCH_URL)
    console.log(data)
    if (this.playerLoggedIn == true) {

      console.log(this.playerLoggedIn)
      // window.open(data.FRESH_DECK_LAUNCH_URL) 
      sessionStorage.setItem("gameurl", data.FRESH_DECK_LAUNCH_URL)
      if (data.FRESH_DECK_LAUNCH_URL) {
        window.open(`${window.location.origin}/opentab`)
      } else if (data.FRESH_DECK_LAUNCH_URL == "") {
        this.router.navigate(['/'])
      }
    } else if (this.playerLoggedIn == false) {
      this.casinogamessuccessPop = true;

    }
    console.log(data)
  }
  receiveallGamesList(data) {
    console.log(data)
    this.Tablegsports = data.category[0].games;


    if (this.gameIdArr.length == 0) {
      this.gameIdArr.push(data.category[0].games)
      console.log(this.gameIdArr)
    } else {
      return;
    }
    // for(let i=0; i<data.category.length; i++){
    // console.log(data.category[1].games.length)

    this.Tablegames = data.category[1].games;

    if (this.vituvalIdArry.length == 0) {
      this.vituvalIdArry.push(data.category[1].games)
    } else {
      return;
    }

    // }

  }
  auraGames(data) {
    console.log(data.category)
    this.auraGamesList = data.category;
  }
  auraUrlForm(data, gId) {
    if (this.playerLoggedIn == true) {
      let launchAura;
      if (this.isMobile == false) {
        launchAura = `https://d.fawk.app/#/splash-screen/${data.FAWK_TOKEN}/${data.FAWK_OPERATOR_ID}?opentable=${gId}`;
      } else {
        launchAura = `https://m.fawk.app/#/splash-screen/${data.FAWK_TOKEN}/${data.FAWK_OPERATOR_ID}?opentable=${gId}`;
      }


      window.open(launchAura)
    } else if (this.playerLoggedIn == false) {
      this.casinogamessuccessPop = true;
      // this.casinomessage = 'Please Login';
    }
  }
  liveGames() {
    this.SlotsService.getlottery().subscribe(token => { this.getToken(token) });
    this.openDiv = true;
    this.closeDiv = false;
    this.closeopenDiv = false
  }
  vituvalGames() {
    this.SlotsService.getlottery().subscribe(token => { this.getToken(token) });
    this.openDiv = false;
    this.closeDiv = true;
    this.closeopenDiv = false

  }
  livedealer() {
    this.SlotsService.getlottery().subscribe(token => { this.getToken(token) });
    this.openDiv = false;
    this.closeDiv = false;
    this.closeopenDiv = true

  }


  getToken(token) {
    this.gameToken = token.FAWK_TOKEN;
    this.gameId = token.FAWK_OPERATOR_ID;
    if (this.gameToken && this.gameId)
      this.SlotsService.getfawkGames().subscribe(data => this.receiveallGamesList(data));

  }
  livedealerLunchreal(data) {
    if (this.playerLoggedIn) {
      window.open(data)
    } else {
      this.casinogamessuccessPop = true;
    }
  }
  casinogamessuccessPopClose() {
    this.casinogamessuccessPop = false;
  }
  wacgameslive(data) {
    console.log(data.GamesEzugiEvolution)
    this.WacSlotGames = data.GamesEzugiEvolution
  }
  wacgameslive1(data) {
    console.log(data.GamesEzugiEvolution
    )
    const wac_game = []
    this.wacGamesList = data.providers;
    for (let i = 0; i < data.providers.length; i++) {
      if (data.providers[i].games) {
        //       if(data.games == 'slot'){
        // //console.log(data.games)
        //       }
        wac_game.push(data.providers[i])
        this.wacgames = wac_game;
      }
      //console.log(this.wacgames)
    }


    for (let index = 0; index < this.wacgames.length; index++) {
      var element = this.wacgames[index];
      for (let i = 0; i < element.games.length; i++) {
        var cateValue = element.games[i];
        if (cateValue.category == "LIVE") {
          this.WacSlotGames.push(cateValue);
        }

      }
    }
    console.log(this.WacSlotGames)
  }
  evolution123() {
    // this.name = id;
    if (this.playerLoggedIn == true) {
      let ProName = sessionStorage.getItem("profile")
      let wsessionId = sessionStorage.getItem('wsession')
      // this.SlotsService.getEzugi(wsessionId).subscribe(data => this.evolutiongames(data))
    } else {
      // let ProName = "demouser"
      // this.SlotsService.getEzugi(ProName).subscribe(data => this.evolutiongames(data)) 
      // this.casinogamessuccessPop = true;
    }


  }
  evolutiongames(data) {
    console.log(data.EZUGI_GAME_LAUNCH_URL)
    // window.open(data.EZUGI_GAME_LAUNCH_URL)     
    let evourl = `${data.EZUGI_GAME_URL}token=${data.EZUGI_TOKEN}&operatorId=${data.EZUGI_OPERATOR_ID}&[language=en&clientType=html5&openTable=${this.name}&homeUrl=https://demo.rapoker.club/home]`
    let evourl_loby = `${data.EZUGI_GAME_URL}token=${data.EZUGI_TOKEN}&operatorId=${data.EZUGI_OPERATOR_ID}&[language=en&clientType=html5&openTable=1000000`
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(evourl_loby)
    if (this.urlSafe) {
      this.hideurl = false 
      this.loaderpage = true
      setTimeout(()=>{
        this.loaderpage = false
      },3000)
    }
    // window.open(evourl1,"_self")
    console.log(evourl)
    // let otherGames = 'all'
    // sessionStorage.setItem("gameurl", evourl)
    // sessionStorage.setItem('urlweareGame', otherGames)
    if (evourl) {
      // let otherGames = 'all'
      // sessionStorage.setItem('urlevo', otherGames)
      // window.open(`${window.location.origin}/opentab`,"_self")
    }
    // else if (evourl == "") {
    //   this.router.navigate(['/'])
    // }
    // window.open(evourl, '_blank');  
  }
  subtabClose(){
    this.urlSafe = false;
    this.hideurl = true

  }
  connect() {
    var bodydata = []
    var ws = new WebSocket('wss://dga.pragmaticplaylive.net/ws');
    ws.onopen = function () {
      ws.send(JSON.stringify({

        type: 'available',
        // casinoId: "ppcdk00000012980"
        casinoId: "ppcda00000006808"
      })); 
    };

    var array = []
    ws.onmessage = function (e) { 
      const trade = JSON.parse(e.data);
      // bodydata.push(trade) 
      if (trade.hasOwnProperty("tableKey")) {
        let i = 0;
        let cnt = trade.tableKey.length 
        for (i; i < cnt; i++) {
          const request = {
            type: 'subscribe',
            key: trade.tableKey[i],
            currency:"ARS",
            casinoId: "ppcda00000006808"
          }
          ws.send(JSON.stringify(request))
        } 
      }
      if (trade.hasOwnProperty("tableId")) {
        Messagess(trade)          
      }          
    };
    this.pragmaticLivecasino = bodydata
    console.log(this.pragmaticLivecasino)
    var array = []
    const Messagess = (data) => {  
      if (array.includes(data.tableId)) {
     clearInterval(this.setTimeClose)
        ws.close()
    this.closeSetTime()
      } else {
        array.push(data.tableId)
        bodydata.push(data) 
      }   
    }  
    ws.onclose = function (e) { 
      console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason); 
  };  
    ws.onerror = function (err) {
      console.error('Socket encountered error: ', 'Closing socket'); 
      ws.close();
    };
  }
  closeSetTime(){ 
    this.setTimeClose =setInterval(() =>{ 
      this.connect();   
    }, 60000)
  } 
  pragmaticLiveCasinores(){
    this.pragmaticeLivesection = true
    this.connect()
   this.tabGameName = ""
  //  this.Gamespage = false
  }
  Pragmaticgamesres(name,id){
    if (this.playerLoggedIn == true) {
      this.SlotsService.getpragmatichit(id).subscribe(data => this.pragmaticres12(data))
    } else if (this.playerLoggedIn == false) { 
    }
  }

  pragmaticres12(data){
    console.log(data)
    let url = data.gameURL
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(url)
    if (this.urlSafe) {
     window.scrollTo({
       top: 0,
       left: 0,
       behavior: "smooth",
     });
  }
}
backclick(){
  this.pragmaticeLivesection = false
  this.tabGameName = ""
  this.Gamespage = true
}

EvoTableRegularlanuch(tableid:any) {
  if (this.playerLoggedIn == true) {
    this.tableIdname =  tableid
    let logiName = sessionStorage.getItem('profile');
    let wsessionId = sessionStorage.getItem('wsession')
    console.log(logiName)
    // this.SlotsService.liveDealerServiceData(wsessionId).subscribe(liveDealerdata => {
    //   this.EvoTableRegularlanuchres(liveDealerdata);
    // });
  } else if (this.playerLoggedIn == false) { 
  }
}
EvoTableRegularlanuchres(data) { 
  let ezugiLunch12 = `${data.EZUGI_GAME_URL}token=${data.EZUGI_TOKEN}&operatorId=${data.EVO_STANDARD_OPERATOR_ID}&language=en&clientType=html5&openTable=${ this.tableIdname}&homeUrl=https://1234clp.czargaming.com/home`
  // let ezugiLunch = data.EZUGI_GAME_URL + 'token=' + data.EZUGI_TOKEN + '&operatorId=' + data.EZUGI_OPERATOR_ID + '&clientType=html5&language=es' ;
  console.log(ezugiLunch12)
  this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(ezugiLunch12)
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}
EvoTablePremiumlanuch(tableid:any) {
  if (this.playerLoggedIn == true) {
    this.tableIdname =  tableid
    let logiName = sessionStorage.getItem('profile');
    let wsessionId = sessionStorage.getItem('wsession')
    console.log(logiName)
    // this.SlotsService.liveDealerServiceData(wsessionId).subscribe(liveDealerdata => {
    //   this.EvoTablePremiumlanuchres(liveDealerdata);
    // });
  } else if (this.playerLoggedIn == false) { 
  }
}
EvoTablePremiumlanuchres(data) { 
  let ezugiLunch12 = `${data.EZUGI_GAME_URL}token=${data.EZUGI_TOKEN}&operatorId=${data.EVO_PREMIUM_OPERATOR_ID}&language=en&clientType=html5&openTable=${ this.tableIdname}&homeUrl=https://1234clp.czargaming.com/home`
  // let ezugiLunch = data.EZUGI_GAME_URL + 'token=' + data.EZUGI_TOKEN + '&operatorId=' + data.EZUGI_OPERATOR_ID + '&clientType=html5&language=es' ;
  console.log(ezugiLunch12)
  this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(ezugiLunch12)
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

EzugiRegularlanunch(id:any) {
  if (this.playerLoggedIn == true) {
    this.tableIdname = id
    let logiName = sessionStorage.getItem('profile');
    let wsessionId = sessionStorage.getItem('wsession')
    console.log(logiName)
    // this.SlotsService.liveDealerServiceData(wsessionId).subscribe(liveDealerdata => {
    //   this.Ezugiregularlanuchres(liveDealerdata);
    // });
  } else if (this.playerLoggedIn == false) { 
  }
}
Ezugiregularlanuchres(data) { 
  let ezugiLunch = `${data.EZUGI_GAME_URL}token=${data.EZUGI_TOKEN}&operatorId=${data.EZUGI_OPERATOR_ID}&language=en&clientType=html5&openTable=${ this.tableIdname}&homeUrl=https://1234clp.czargaming.com/home`
  // let ezugiLunch = data.EZUGI_GAME_URL + 'token=' + data.EZUGI_TOKEN + '&operatorId=' + data.EZUGI_OPERATOR_ID + '&clientType=html5&language=es' ;
  console.log(ezugiLunch)
  sessionStorage.setItem("gameurl", ezugiLunch)
  this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(ezugiLunch)
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}
EzugiPremiumLanuch(id:any) {
  if (this.playerLoggedIn == true) {
    this.tableIdname = id
    let logiName = sessionStorage.getItem('profile');
    let wsessionId = sessionStorage.getItem('wsession')
    console.log(logiName)
    // this.SlotsService.liveDealerServiceData(wsessionId).subscribe(liveDealerdata => {
    //   this.EzugiPremiumlanuchres(liveDealerdata);
    // });
  } else if (this.playerLoggedIn == false) {
 
  }
}
EzugiPremiumlanuchres(data) { 
  let ezugiLunch = `${data.EZUGI_GAME_URL}token=${data.EZUGI_TOKEN}&operatorId=${data.EZUGI_PREMIUM_OPERATOR_ID}&language=en&clientType=html5&openTable=${ this.tableIdname}&homeUrl=https://1234clp.czargaming.com/home`
  // let ezugiLunch = data.EZUGI_GAME_URL + 'token=' + data.EZUGI_TOKEN + '&operatorId=' + data.EZUGI_OPERATOR_ID + '&clientType=html5&language=es' ;
  console.log(ezugiLunch)
  sessionStorage.setItem("gameurl", ezugiLunch)
  this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(ezugiLunch)
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}

}

