import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, from, Subscription, interval } from 'rxjs';
import { sportMenuModel, Datum } from '../../source/modules/specific/sportMenu.model';
import { sideSportService } from '../../source/services/sports/sportMenu.service';
import { CommondataService } from '../../source/services/sports/commondata.service';
import { filterSport, filterSportData } from '../../source/modules/specific/filterBySport.model';
import { bySCL, bySCLdata } from '../../source/modules/specific/bySportCountryLeague.model';
import { Store } from '@ngrx/store';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { ProfileState } from 'src/app/source/appstates/playerstates/playerState';

import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import * as appState from 'src/app/source/appstates/appState';
import { PreMatchComponent } from '../pre-match/pre-match.component';
import { PasswordsComponent } from '../passwords/passwords.component';
import { Router } from '@angular/router';
import { SlotsService } from 'src/app/source/services/slots.service';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { slotsData } from 'src/app/source/modules/slotsmodel/slotsdata';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import * as playerActions from 'src/app/source/appstates/playerstates/playerActions';

declare var $: any;
@Component({
  selector: 'app-sports-menu',
  templateUrl: './sports-menu.component.html',
  styleUrls: ['./sports-menu.component.css'],
})
export class SportsMenuComponent implements OnInit {
  public width_1: number;
  CountriesOpen: boolean = false;
  LeaguesOpen: boolean = false;
  testNGiF: boolean = true;

  casinogamesearch;
  allSlotsList: RootObject;
  topSlotsList: RootObject;
  tableSlotsList: RootObject;
  allSlotsGames: Array<Game>;
  topSlotsGames: Array<Game>;
  tableSlotsGames: Array<Game>;
  casinogamessuccessPop: boolean = false;
  game: Array<Game>;
  sportMenu: sportMenuModel;
  DataRes: Array<Datum>;
  folder:string=localStorage.getItem('locale');
  bySport: filterSport;
  loginSub: Subscription;
  bySportRes: Array<filterSportData>;
  sportMenuErrorMessage: string;
  bySportErrorMessage: string;
  BySCLerrorMessage: string;
  BySCL: bySCL;
  BySCLdata: Array<bySCLdata>;
  jnone: boolean = true;
  rootObject: RootObject;
  sportName: string
  loaderdata: boolean = false;
  casinomessage: string = "";
  slotsdata: slotsData;
  tokendata: any = null;
  keyname: string;
  values: any;
  showdata: boolean = false;
  topcpplayererrormessage: string;
  logoutstore: Subscription;
  playerstore: Subscription;
  bettokenstoreSub: Subscription;
  cpploader: boolean = false;
  p: number = 1;
  leagueid:any;
  selectnum: number = 5;
  showbutton: boolean = false;
  lengthofdata: number;
  playerLOggedIn: boolean = false;
  private screenWidth$ = new BehaviorSubject<number>
    (window.innerWidth);
  countryName: string;
  loderCountry: boolean = false;
  loderLeague: boolean = false;
selectsportname:any = {};
SelectedCountryName:any = {};

  /*@HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 150) {
      let element_2 = document.getElementById('sportMenu');
      element_2.classList.add('stickySide');
    } else {
      let element_2 = document.getElementById('sportMenu');
      element_2.classList.remove('stickySide');
    }
  }*/

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth$.next(event.target.innerWidth);
  }
  getScreenWidth(): Observable<number> {
    return this.screenWidth$.asObservable();
  }
  @ViewChild(PasswordsComponent, { static: false }) passwords: PasswordsComponent;

  constructor(private slotsservice: SlotsService, private router: Router, private store: Store<appState.AppState>, private _menuServe: sideSportService, private CommondataService: CommondataService) {
    this._menuServe.sportMenuData().subscribe(sportData => this.sportMenuList(sportData));
 
    let leagueid = sessionStorage.getItem("League");
        if(router.url== '/sports'){
        this._menuServe.sendSportCountryLeague(sessionStorage.getItem("SportName"), sessionStorage.getItem("Country"), sessionStorage.getItem("League")).subscribe(spCountryLeague => this.sportCountryLeague(spCountryLeague));
        interval(2000 * 60).subscribe(x => {
          this._menuServe.sendSportCountryLeague(sessionStorage.getItem("SportName"), sessionStorage.getItem("Country"), sessionStorage.getItem("League")).subscribe(spCountryLeague => this.sportCountryLeague(spCountryLeague));
        });
      }
    


    this.slotsservice.TopSlotsJson().subscribe(topSlots => { this.topSlotsGamesList(topSlots) });
    this.slotsservice.TableGamesJson().subscribe(tableSlots => { this.tableSlotsGamesList(tableSlots) });
    this.slotsservice.AllSlotsJson().subscribe(allSlots => { this.allSlotsGamesList(allSlots) });
  }


  sportMenuList(sportData) {
    this.sportMenu = sportData;
    if (this.sportMenu.Status == 200) {
      this.DataRes = this.sportMenu.data;
      this.CommondataService.sportslist(this.sportMenu);
    } else {
      this.sportMenuErrorMessage = this.sportMenu.message;
    }
  }



  ngOnInit() {
    this.loginSub = this.store
    .select("loginState")
    .subscribe((loginState: LoginState) => {
      if (loginState.playerLoggedIn) {
        this.playerLOggedIn = loginState.playerLoggedIn.loggedIn;
      }
    });
    $(document).ready(function () {

      $('.menuLines').click(function () {
        $('.sportUL').show()
        $('.menuHide').show()
        $('.menuLines').hide()
      })

      $('.menuHide').click(function () {
        $('.sportUL').hide()
        $('.menuHide').hide()
        $('.menuLines').show()
      })

    });
  

    this.playerstore = this.store.select("playerState").subscribe(
      (playerState: ProfileState) => {
        if (playerState.TopCPPlayersres) {
          if (playerState.TopCPPlayersres["success"] === true) {
            this.cpploader = false;
            this.showbutton = true;
            this.topcpplayererrormessage = null;
            this.values = playerState.TopCPPlayersres["values"];
            this.lengthofdata = playerState.TopCPPlayersres["values"].keyvalues.length;
          } else if (playerState.TopCPPlayersres["success"] === false) {
            this.cpploader = false;
            this.values = null;
            this.showbutton = false;
            this.topcpplayererrormessage = playerState.TopCPPlayersres['description']
          }
          if (playerState.TopCPPlayersErrorres) {
            this.cpploader = false;
            this.values = null;
            this.showbutton = false;
            this.topcpplayererrormessage = playerState.TopCPPlayersErrorres.message;
          }
        }
      }
    )
    //    this.width_1 = document.getElementById("sportMenu").clientWidth;

  }
  sideMenuClose() {
    this.jnone = false;
    if ($(window).width() < 992) {
      $('.j_menu').addClass('col-md-1').removeClass('col-md-2');
      $('.j_none').addClass('d_none');
      $('.j_side').addClass('col-md-11').removeClass('col-md-10');
    }
  }
  sideMenuOpen() {
    this.jnone = false;
    if ($(window).width() < 992) {
      $('.j_menu').removeClass('col-md-1').addClass('col-md-2');
      $('.j_none').removeClass('d_none');
      $('.j_side').removeClass('col-md-11').addClass('col-md-10');
    }
  }

  clickSport(data) {
    this.bySportRes = null;
    this.BySCLdata=null;
    this.sportName = data;
    if(data in this.selectsportname){
      delete this.selectsportname[data];
    }else{
      this.selectsportname={};
       this.selectsportname[data] = this.sportName;
       sessionStorage.setItem("SportName", this.sportName);
       this.loaderdata = true;
       this.CountriesOpen = true;
       this._menuServe.filterBySport(this.sportName).subscribe(countryData => this.countryList(countryData));
    }
  }
  countryList(countryData) {
    this.bySport = countryData;
    if (this.bySport.Status == 200) {
      this.bySportErrorMessage = null;
      this.loaderdata = false;
      this.bySportRes = this.bySport.data;
    } else {
      this.bySportRes = null;
      this.loaderdata = false;
      this.bySportErrorMessage = this.bySport.Message;
    }

  }
  selectedcountry(sport, country) {
    this.BySCLdata=null;
    this.countryName = country;
    if(country in this.SelectedCountryName){
      delete this.SelectedCountryName[country];
    }else{
      this.loderCountry = true;
      this.LeaguesOpen = true;
      this.SelectedCountryName={};
      this.SelectedCountryName[country]=this.countryName;
      this._menuServe.filterByCountryLeague(sport, country).subscribe(spCountryData => this.sportCountryList(spCountryData),
        err => {
          this.loderCountry = false;
          this.BySCLerrorMessage = this.BySCL.message;
        });
    }
 


  }
  // @ViewChild(PreMatchComponent, {static:false})  preMatch:PreMatchComponent;
  upComing() {
    // this.child.showAll();
  }
  sportCountryList(spCountryData) {
    this.BySCL = spCountryData;
    if (this.BySCL.Status == 200) {
      this.loderCountry = false;
      this.sportMenuErrorMessage = null;
      this.BySCLdata = this.BySCL.data;
    } else {
      this.loderCountry = false;
      this.BySCLdata = null;
      this.BySCLerrorMessage = this.BySCL.message;
    }
  }

  selectLeague(sport, country, league) {
    if ($(window).width() < 768) {
      $('.sportUL').hide();
      $('.menuHide').hide()
      $('.menuLines').show()
    }
    this.loderLeague = true;
    this.router.navigate(['/sports']);
    sessionStorage.setItem("sportName", sport);
    sessionStorage.setItem("Country", country);
    sessionStorage.setItem("League", league);
    this.leagueid = league;
    this._menuServe.sendSportCountryLeague(sessionStorage.getItem("sportName"), sessionStorage.getItem("Country"), sessionStorage.getItem("League")).subscribe(spCountryLeague => this.sportCountryLeague(spCountryLeague),
      err => { });
  }

  sportCountryLeague(spCountryLeague) {
    this.loderLeague = false;
    this.CommondataService.CountryMatches(spCountryLeague);
  }
  comingsoon() {
    this.passwords.comingSoonPopOpen()
  }

  hitService() {
    let profile = sessionStorage.getItem('profile');
    // this.slotsservice.httpClient(profile).subscribe(data => { this.slotslunch(data) });
  };
  slotsinlogout(data) {
    if (this.playerLOggedIn == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      if (this.tokendata == null) {
        this.hitService();
      } else if (this.tokendata != null) {
        window.open(this.slotsdata.HABANERO_GAMING_URL + 'brandid=' + this.slotsdata.BRAND_ID + '&keyname=' + data + '&token=' + this.slotsdata.TOKEN + '&mode=real&locale=en', "_blank");
        return;
      }
    } else if (this.playerLOggedIn == false) {
      this.casinomessage = "Please login";
      this.casinogamessuccessPop = true;
    }
  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
      return window.open(this.slotsdata.HABANERO_GAMING_URL + 'brandid=' + this.slotsdata.BRAND_ID + '&keyname=' + this.keyname + '&token=' + this.slotsdata.TOKEN + '&mode=real&locale=en', "_blank");

    }
  }
  casinogamessuccessPopClose() {
    this.casinomessage = "";
    this.casinogamessuccessPop = false;
  }
  printfeedleagues() {
    // sessionStorage.setItem('SportName', 'Soccer');
    this.router.navigate(['/oddsprint']);
    //this.store.dispatch(new loginActions.PrintFeedOfleagues('Soccer'));
    //this._mainServe.printFeedLeaguesData(this.sName).subscribe(leagues => this.printfeedres(leagues));
  }
  topcpplayer() {
    this.cpploader = true;
    this.store.dispatch(new playerActions.PlayerGetTopCPPlayers());
  }
  onChange() {
    this.cpploader = false;
    this.showbutton = false;
    this.selectnum = this.lengthofdata;
  }
  allSlotsGamesList(allSlots) {
    this.allSlotsList = allSlots;
    console.log(this.allSlotsList);
    if (this.allSlotsList) {
      this.allSlotsGames = this.allSlotsList.Games;
    }
  }
  topSlotsGamesList(topSlots) {
    this.topSlotsList = topSlots;
    if (this.topSlotsList) {
      this.topSlotsGames = this.topSlotsList.Games;
    }
  }
  tableSlotsGamesList(tableSlots) {
    this.tableSlotsList = tableSlots;
    if (this.tableSlotsList) {
      this.tableSlotsGames = this.tableSlotsList.Games;
    }
  }
  slotsdatachange(data) {
    if (data.length != 0) {
      this.showdata = true;
    } else {
      this.showdata = false;
    }
  }
}
