import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { Subscription } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { CashierGetBalanceStart } from 'src/app/source/appstates/cashierstates/cashierActions';
import {Component, OnInit, OnDestroy} from '@angular/core';

@Component({
  selector: 'app-depositresponse',
  templateUrl: './depositresponse.component.html',
  styleUrls: ['./depositresponse.component.css']
})
export class DepositresponseComponent implements OnInit, OnDestroy {
  result: string;
  depositmsg: string;
  depositresponse: boolean = false;
  constructor(
    private router: Router, private store: Store<appState.AppState>,
    private route: ActivatedRoute) {

      this.store.dispatch(new CashierGetBalanceStart());
  }
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.result = params.result;
      if (this.result == 'success') {
        this.depositresponse = true;
        this.depositmsg = "success";
      } else {
        this.depositresponse = true;
        this.depositmsg = "error";
      }
    });
    this.store.dispatch(new CashierGetBalanceStart());
    //this.store.dispatch(new PlayerGetProfile());
  }

  navigate() {
    this.store.dispatch(new CashierGetBalanceStart());
    if (this.result == 'success') {
      this.depositresponse = false;
      this.router.navigate(["/transaction"]);
    } else {
      this.depositresponse = false;
      this.router.navigate(["/balance"]);
    }
  }
  ngOnDestroy() { }

}
