export class Betslip {
    name: string;
    matchId: number;
    type: string;
    oddTitle: string;
    oddstype: string;
    betString: any;
    odd: any;
    oddId: number;
    decimalOdd: number;
    betAmount: number;
    mktId: number;
    country:string;
    datendtime:any;
    flag : boolean;
    constructor(matchdata:oddsData, tittle: string, country:string){
        this.flag = false;
        this.type = 'EU';
        this.name = matchdata.match_name;
        this.matchId = matchdata.match_id;
        this.oddId = matchdata.homeodds.id;
        this.oddstype = matchdata.homeodds.oddstype;
        this.country = country;
        this.datendtime = matchdata.start_date + "-" + matchdata.start_time;
        if(tittle == matchdata.homeodds.home_title){
        this.oddTitle = matchdata.homeodds.home_title;
        this.mktId = matchdata.homeodds.home_mkt_id;
        this.decimalOdd = matchdata.homeodds.home_eu;
        this.odd = matchdata.homeodds.home_eu;
        this.betString = this.datendtime+  " | " + this.country+  " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
        }else if(tittle == matchdata.homeodds.neut_title){
            this.oddTitle = matchdata.homeodds.neut_title;
            this.mktId = matchdata.homeodds.neut_mkt_id;
            this.decimalOdd = matchdata.homeodds.neut_eu;
            this.odd = matchdata.homeodds.neut_eu;
            this.betString = this.datendtime+  " | " + this.country+  " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
        }else if(tittle == matchdata.homeodds.away_title){
            this.oddTitle = matchdata.homeodds.away_title;
            this.mktId = matchdata.homeodds.away_mkt_id;
            this.decimalOdd = matchdata.homeodds.away_eu;
            this.odd = matchdata.homeodds.away_eu;
            this.betString = this.datendtime+  " | " + this.country+  " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
        }
   }
}
export class oddsData {
    match_id: number;
    match_name: string;
    start_date: string;
    start_time: string;
    homeodds:homeodds;
}
export class homeodds {
    id: number;
    oddstype: string;
    home_mkt_id: number;
    home_title: string;
    home_eu: number;
    neut_mkt_id: number;
    neut_title: string;
    neut_eu: number;
    away_mkt_id: number;
    away_title: string;
    away_eu: number;
    markets: number
    
}

export class OddsBetslip {
    name: string;
    matchId: number;
    type: string;
    oddTitle: string;
    oddstype: string;
    betString: any;
    odd: any;
    oddId: number;
    decimalOdd: number;
    betAmount: number;
    mktId: number;
    country:string;
    datendtime:any;
    flag : boolean;
    constructor(selectedodd:Selection, oddstype:string, tittle: string, matchname:string, matchid:number, dateTime:any, country:string){
        this.flag = false;
        this.type = 'EU';
        this.name =matchname;
        this.matchId = matchid;
        this.oddId = selectedodd.id;
        this.oddstype = oddstype;
        this.country = country;
        this.datendtime = dateTime;
        if(tittle == selectedodd.home_title){
        this.oddTitle = selectedodd.home_title;
        this.mktId = selectedodd.home_mkt_id;
        this.decimalOdd = selectedodd.home_eu;
        this.odd = selectedodd.home_eu;
        this.betString = this.datendtime+  " | " + this.country+  " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
        }else if(tittle == selectedodd.neut_title){
            this.oddTitle = selectedodd.neut_title;
            this.mktId = selectedodd.neut_mkt_id;
            this.decimalOdd = selectedodd.neut_eu;
            this.odd = selectedodd.neut_eu;
            this.betString = this.datendtime+  " | " + this.country+  " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
        }else if(tittle == selectedodd.away_title){
            this.oddTitle = selectedodd.away_title;
            this.mktId = selectedodd.away_mkt_id;
            this.decimalOdd = selectedodd.away_eu;
            this.odd = selectedodd.away_eu;
            this.betString = this.datendtime+  " | " + this.country+  " | " + this.name + " | " + this.oddstype + " | " + this.oddTitle + " @ " + this.odd;
        }
   }
}

    export interface Selection {
        id: number;
        home_title: string;
        home_eu: number;
        home_mkt_id: number;
        neut_title?: any;
        neut_eu?: any;
        neut_mkt_id?: any;
        away_title: string;
        away_eu: number;
        away_mkt_id: number;
        row_id: number;
        odd_selections: number;
    }





