import { Component, OnInit } from '@angular/core';
import { SlotsService } from 'src/app/source/services/slots.service';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import { slotsData, casinogmaelunch, KeyVsValue } from 'src/app/source/modules/slotsmodel/slotsdata';
import { RootObject, Game } from 'src/app/source/modules/slotsmodel/slotsvalue';
import { buildRootObject, buildGame } from 'src/app/source/modules/slotsmodel/builsslots';
import { environment } from '@app/env';
import { CoreEnvironment } from '@angular/compiler/src/compiler_facade_interface';
import * as cashierActions from 'src/app/source/appstates/cashierstates/cashierActions';
import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { CasinoGames, Casino } from 'src/app/source/modules/goldenraceinterface/goldenrace';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-bottom-slots',
  templateUrl: './bottom-slots.component.html',
  styleUrls: ['./bottom-slots.component.css']
})
export class BottomSlotsComponent implements OnInit {
  private profilestoreSubslots: Subscription;
  private bettokenstoreSub: Subscription;
  private logoutstore: Subscription;
  casinogames: CasinoGames;
  slotsdata: slotsData;
  buildRootObject: buildRootObject;
  buildGame: buildGame;
  allSlotsList: RootObject;
  topSlotsList: RootObject;
  tableSlotsList: RootObject;
  allSlotsGames: Array<Game>;
  topSlotsGames: Array<Game>;
  tableSlotsGames: Array<Game>;
  Casinresponse: Array<Casino>;
  isLogin: boolean = false;
  KeyVsValue: KeyVsValue;
  balackjackplayerContextId: string;
  baccaratplayerContextId: string;
  europeanrouletteplayerContextId: string;
  OasispokerplayerContextId: string;
  sessionid: any;
  casinogmaelunch: casinogmaelunch;
  imagespath = environment.slotsiamgepath;
  casinogamessuccessPop:boolean=false;
  casinomessage:string="";
  keyname:string;
  tokendata:any=null;
  constructor(private route: ActivatedRoute, private router: Router, private slotsservice: SlotsService, private store: Store<appState.AppState>) {
    this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    this.slotsservice.TopSlotsJson().subscribe(topSlots => {this.topSlotsGamesList(topSlots)});
  }


  ngOnInit() {
    let token = sessionStorage.getItem('accessToken');
    if (token != null) {
      this.isLogin = true;
    } else if (token == null) {
      this.isLogin = false;
    }
    this.logoutstore = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.loginResponse) {
          if (loginState.loginResponse.success === true) {
            this.isLogin = true;
          } else if (loginState.loginResponse.success === false) {
            this.isLogin = false;
          }
        }
      }
    )
    this.bettokenstoreSub = this.store.select("tokenState").subscribe(
      (tokenState: tokenState) => {
        if (tokenState.sBookLogin) {
          if (tokenState.sBookLogin.accessToken != null) {
            this.isLogin = true;
          } else if (tokenState.sBookLogin.accessToken == null) {
            this.isLogin = false;
          }
        }
      }
    );
    if (this.isLogin == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
    //  this.hitService();
    } else if (this.isLogin == false) {
    }
  }
  topSlotsGamesList(topSlots){
    this.topSlotsList = topSlots;
    if (this.topSlotsList) {
      this.topSlotsGames = this.topSlotsList.Games;
    }
  }






  hitService() {
    let profile = sessionStorage.getItem('profile');
    // this.slotsservice.httpClient(profile).subscribe(data => { this.slotslunch(data) });
  };
  slotsinlogout(data) {
    if (this.isLogin == true) {
      this.store.dispatch(new cashierActions.CashierGetBalanceStart());
      this.keyname = data;
      if (this.tokendata == null) {
        this.hitService();
      } else if(this.tokendata != null){
        window.open(this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+data+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en', "_blank");
        return;
      }
    } else if (this.isLogin == false) {
      this.casinomessage="Please login";
      this.casinogamessuccessPop=true;
    }
  }
  slotslunch(data) {
    if (data) {
      this.slotsdata = data;
      this.tokendata = this.slotsdata.TOKEN;
      return window.open(this.slotsdata.HABANERO_GAMING_URL+'brandid='+this.slotsdata.BRAND_ID+'&keyname='+this.keyname+'&token='+this.slotsdata.TOKEN+'&mode=real&locale=en', "_blank");

    }
  }
  casinogamessuccessPopClose(){
      this.casinomessage="";
      this.casinogamessuccessPop=false;
  }
}
