
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as appState from 'src/app/source/appstates/appState';
import * as loginActions from 'src/app/source/appstates/loginstates/loginActions';
import { Subscription, interval } from 'rxjs';
import { LoginState } from 'src/app/source/appstates/loginstates/loginState';
import { Countries, Country } from 'src/app/source/modules/login/countries';
import { tokenState } from '../../source/appstates/sblogin/sbloginState';
import { environment } from '@app/env';
import { map, take } from 'rxjs/internal/operators';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit, OnDestroy {
  formRegister: FormGroup;
  errorMessageRegi: string;
  selectedcountry:string='4';
  phonevalue: any;
  undefiend: any;
  selecteddata: any;
  emailvalue: any;
  forgotform: FormGroup;
  registerloader: boolean = false;
  private storeSub: Subscription;
  private tokenstoreSub: Subscription;
  private countries: Countries;
  country: Country[];
  registerPopup: boolean = false;
  otpRequesterrorMessage: string;
  registerpopmessage: string;
  registersuccessPop: boolean = false;
  urlof: string; reID: any;
  reType: any;
  selectedcurrency: string='ARS';
  currencyvalues = [
    { name: 'ARS' },
    { name: 'BRL' },
    { name: 'UYU' },
    { name: 'CRC' },
    { name: 'CLP' },
    { name: 'BOV' },
    { name: 'COP' },
    { name: 'MXN' },
    { name: 'PYG' },
    { name: 'USD' },
    { name: 'EUR' },
    { name: 'RP' },
    { name: 'TRY' },
    { name: 'TND' }
  ];
  countryvalues = [
    { id: '+66', value: "764", name: 'Thailand' },
    { id: '+60', value: "458", name: 'Malaysia' },
    { id: '+62', value: "360", name: 'Indonesia' },
    { id: '+84', value: "704", name: 'Vietnam' },
    { id: '+91', value: "356", name: 'India' }
  ];
  casinomessage: string;
  errorMessage: string;
  constructor(private route: ActivatedRoute, private router: Router, private store: Store<appState.AppState>) {

    this.store.dispatch(new loginActions.ResetState());
  }

  ngOnInit() {
 
    this.urlof = this.router.url.substr(0, this.router.url.indexOf("?"));
    if (this.urlof == '/register') {
    this.errorMessage = '';

      this.openRegisterPop();
      this.route.queryParams.subscribe(params => {
        this.reID = params.ID;
        this.reType = params.type;
      });

      if (this.reID != null) {
        this.store.dispatch(new loginActions.ResetState());
      }
    }
    this.store.dispatch(new loginActions.ResetState());

    this.formRegister = new FormGroup({
      "register": new FormGroup({
        "login": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(15), Validators.pattern('[a-zA-Z0-9]*')]),
        "nickname": new FormControl(null),
         "email": new FormControl(null, [Validators.required,  Validators.email, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]),
        //"firstName": new FormControl(null),
        //"lastName": new FormControl(null),
        //  "otp": new FormControl(null, Validators.required),
        "campaignCode": new FormControl(this.reID),
        "referralType": new FormControl(this.reType),
        "password": new FormControl(null, [Validators.required,Validators.minLength(6), Validators.maxLength(15), Validators.pattern(/^\S*$/)]),
        "currency": new FormControl('USD'),
        "address": new FormGroup({
          // "city": new FormControl(null),
          "phone": new FormControl(null, [Validators.required,  Validators.pattern('[4-9]\\d{9}')]),
          //"country": new FormControl(),
          // "country": new FormControl(null),
        })
      }),
      "acceptTerms": new FormControl(null, [Validators.required, Validators.requiredTrue])
    })


    // this.formRegister = new FormGroup({
    //   "register": new FormGroup({
    //     "login": new FormControl(null, [Validators.required, Validators.minLength(4)]),
    //     "nickname": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z0-9]*')]),
    //     "email": new FormControl(null, [Validators.required, Validators.email]),
    //     "firstName": new FormControl(null, [Validators.required, Validators.minLength(4), Validators.pattern('[a-zA-Z]*')]),
    //     "lastName": new FormControl(null, [Validators.required, Validators.pattern('[a-zA-Z]*')]),
    //     "password": new FormControl(null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]),
    //     "address": new FormGroup({
    //       "city": new FormControl(null,  [Validators.required, Validators.pattern('[a-zA-Z]*')]),
    //       "phone": new FormControl(null, [Validators.required, Validators.pattern('[4-9]\\d{9}')]),
    //       "country": new FormControl(),
    //      // "country": new FormControl(null, Validators.required),
    //     })
    //   }),
    //   "acceptTerms": new FormControl(true, Validators.requiredTrue)
    // })
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.countries) {
          //if (loginState.countries.success === true) {
            this.countries = loginState.countries;
            this.country = this.countries.values;
          // } else if (loginState.countries.success === false) {
          //   this.errorMessageRegi = loginState.loginResponse.description;
          // }
        }
if(this.registerPopup){
  if (loginState.loginResponse) {
 
console.log(loginState )
console.log(loginState.loginResponse.success)

      if (loginState.loginResponse.success === true) {
        this.registerPopup = false;
        
        this.registersuccessPop = true;
        this.casinomessage = "Hi You Have Successfully Registered";
      }else if (loginState.loginResponse.success === false) {
      this.registerloader = false;
      this.errorMessageRegi = loginState.loginResponse.description;
      console.log(this.errorMessageRegi)
    }
  }
if(loginState){
  if (loginState.loginErrorResponse) {
    this.registerloader = false;
    this.errorMessageRegi = loginState.loginErrorResponse.message;
    console.log(this.errorMessageRegi)

  }
}

}
      }
    )
    this.tokenstoreSub = this.store.select("tokenState").subscribe(
      (tokenState: tokenState) => {
        if (tokenState.sBookLogin) {
          if (tokenState.sBookLogin.accessToken != null) {
            this.formRegister.reset();
            this.registerPopup = false;
          } else if (tokenState.sBookLogin.accessToken == null) {
            this.registerloader = false;
          }
        }
        if (tokenState.sBookLoginErrorResponse) {
          this.registerloader = false;
          this.errorMessageRegi = tokenState.sBookLoginErrorResponse.message;
          console.log(this.errorMessageRegi)

        }
      }
    )
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if(loginState){

          if (loginState.messageforsuccess == true) {
            this.registerloader = false;
          this.registerPopup = false;
  
            this.registersuccessPop = true;
          } else if (loginState.messageforsuccess == false) {
  
            this.registerloader = false;
          }
        }


      }
    )
    this.store.dispatch(new loginActions.RegisterGetCountry());
  }
  generateOTP($event) {
    this.storeSub = this.store.select("loginState").subscribe(
      (loginState: LoginState) => {
        if (loginState.OtpRequestResponse) {
          if (loginState.OtpRequestResponse.success == true) {
            this.otpRequesterrorMessage = "";
            $event.target.disabled = true;
            let numbers = interval(1000);
            let takeFourNumbers = numbers.pipe(take(60));
            takeFourNumbers.subscribe(x => {
              $event.target.innerHTML = x;
              if (x == 59) {
                $event.target.disabled = false;
                $event.target.innerHTML = "Resend OTP";
              }
            });
          } else if (loginState.OtpRequestResponse.success == false) {
            this.otpRequesterrorMessage = loginState.OtpRequestResponse.description;
          }
        }

        if (loginState.OtpRequestErrorResponse) {
          this.otpRequesterrorMessage = loginState.OtpRequestErrorResponse.message;
        }
      }
    )
    this.store.dispatch(new loginActions.RegisterGenarateOTP({ language: 'en', mobile: this.formRegister.get('register.address.phone').value }));
  }
  onFormSubmit() {

    this.registerloader = true;
    this.store.dispatch(new loginActions.ResetState());
    this.formRegister.value.register.nickname = this.formRegister.value.register.login;
    this.store.dispatch(new loginActions.RegisterStart(this.formRegister.value.register));
    //  if(this.formRegister.value.register.address.phone==null){
    //     this.store.dispatch(new loginActions.RegisterStart(this.formRegister.value.register));
    //   }else if(this.formRegister.value.register.address.phone.length==10){
    //     this.formRegister.value.register.address.phone = '+234'+this.formRegister.value.register.address.phone;
    //   this.store.dispatch(new loginActions.RegisterStart(this.formRegister.value.register));
    //   }else{
    //     this.formRegister.value.register.address.phone;
    //     this.store.dispatch(new loginActions.RegisterStart(this.formRegister.value.register));
    //   }

  }
  ngOnDestroy() {
    if (this.storeSub) {
      this.storeSub.unsubscribe();
    }
    if (this.tokenstoreSub) {
      this.tokenstoreSub.unsubscribe();
    }
  }
  closeRegisterPop() {
    this.formRegister.reset();
    this.store.dispatch(new loginActions.ResetState());
    this.registerPopup = false;
  }
  openRegisterPop() {
    this.registerPopup = true;
  }
  registersuccessPopClose() {
    this.store.dispatch(new loginActions.ResetState());
    this.registersuccessPop = false;
  }
  onChange(data) {
    this.selectedcountry = data;
    // for (let currencydata of this.country) {
    //   if (this.selectedcountry == currencydata.id) {
    //     this.selectedcurrency = currencydata.isd;
    //   }
    // }
  }
  userNameValid(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || (k >= 48 && k <= 57));
  }
  passwordValid(event) {
    var k;
    k = event.charCode; 
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k >= 33 && k <= 47 || k >= 60 && k <= 64 || (k >= 48 && k <= 57) || k >= 91 && k <= 96 || k >= 123 && k <= 126);
  }
  emailValid(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k >= 33 && k <= 47 || k >= 60 && k <= 64 || (k >= 48 && k <= 58) || k >= 91 && k <= 96 || k >= 123 && k <= 126);
  }
  phonenum(event) {
    var k;
    k = event.charCode;
    return (k >= 48 && k <= 57);
  }
}
