import { Action } from '@ngrx/store';
import {BetHistory} from '../../modules/BetHistory/bet-history';

export const PLAYER_GET_HISTORY = "[BetHistory] PLAYER_GET_HISTORY";
export const PLAYER_GET_HISTORY_SUCCESS = "[BetHistory] PLAYER_GET_HISTORY_SUCCESS";
export const PLAYER_GET_HISTORY_FAIL = "[BetHistory] PLAYER_GET_HISTORY_FAIL";

export class PlayerGetHistory implements Action{
    readonly type = PLAYER_GET_HISTORY;
    constructor(public payload:Object){ 
    }
}
export class PlayerGetHistorySuccess implements Action{
    readonly type = PLAYER_GET_HISTORY_SUCCESS;
    constructor(public payload:BetHistory){ }
}
export class PlayerGetHistoryFail implements Action{
    readonly type = PLAYER_GET_HISTORY_FAIL;
    constructor(public payload:Object){ }
}

export type PlayerBetHistoryActions = PlayerGetHistory|PlayerGetHistorySuccess|PlayerGetHistoryFail