import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: "root"
})
export class CommondataService {
    live = new Subject<string>();
    matchData = new Subject<any>();
    oddtypedata = new Subject<any>();
    odd = new Subject<string>();
    isLoggedI = new Subject<boolean>();
    couponresdata = new Subject<any>();
    sportslistdata = new Subject<any>();
    predata = new Subject<any>();
    topdata = new Subject<any>();

    CountryMatches(data) {
        this.matchData.next(data);
    }

    getSportMatchData() {
        return this.matchData.asObservable();
    }

    homeOdd = new Subject<any>();

    getHomeOdd(bet) {
        this.homeOdd.next(bet);
        console.log(bet)
    }
    OddTypeChange(oddtype) {
        this.oddtypedata.next(oddtype)
    }
    get Isselectedoddtype() {
        return this.oddtypedata.asObservable();
    }
    setLive(event) {
        this.live.next(event);
    }

    get live$() {
        return this.live.asObservable();
    }

    OddData(bet) {
        this.odd.next(bet);
        console.log(bet);
      }

  
      couponData(data){
        this.couponresdata.next(data);
      }
      setIsLoogedIn(loggedIn: boolean) {
        this.isLoggedI.next(loggedIn);
      }
      get isLoogedIn$() {
         return this.isLoggedI.asObservable();
      }
      sportslist(sportsdata) {
        this.sportslistdata.next(sportsdata);
      }
      get sportslist$() {
         return this.sportslistdata.asObservable();
      }
      prematchdata(data){
        this.predata.next(data);
      }
      get data$() {
        return this.predata.asObservable();
     }
     toptenleagues(data){

        this.topdata.next(data);
     }
     get topdata$() {
        return this.topdata.asObservable();
     }
}