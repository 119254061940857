import * as playerActions from './playerActions';
import { Profile } from '../../modules/player/profile';
import { TournamentsList } from '../../modules/player/tournamentsList';
import { Stats } from '../../modules/player/stats';
import { UpDatePasswordModel } from '../../modules/player/updatepassword';
import { GameHistory } from '../../modules/player/gameHistory';
import { RemoteGamesHistory } from '../../modules/remotegamehistory/remotegamehistory';
import { HttpClientError } from '../../modules/login/httpClientError';
import { UpDateProfileModel } from '../../modules/player/updateprofile';
export interface ProfileState {
        profile: Profile;
        tournamentList: TournamentsList;
        stats: Stats;
        updatepasswordmodel: UpDatePasswordModel;
        GameHistory: GameHistory;
        GameHistoryError: HttpClientError;
        remotegameshistory: RemoteGamesHistory;
        remotegameshistoryError: HttpClientError;
        UpDateProfileModel: UpDateProfileModel;
        UpDateProfileModelError: HttpClientError;
        updatepasswordmodelError: HttpClientError;
        TopCPPlayersres: object;
        TopCPPlayersErrorres: HttpClientError;
        Casinovivoplayer: object;
        CasinovivoplayerError: HttpClientError;
}
const initialState: ProfileState = {
        profile: null,
        tournamentList: null,
        stats: null,
        updatepasswordmodel: null,
        GameHistory: null,
        GameHistoryError: null,
        remotegameshistory: null,
        remotegameshistoryError: null,
        UpDateProfileModel: null,
        UpDateProfileModelError: null,
        updatepasswordmodelError: null,
        TopCPPlayersres: null,
        TopCPPlayersErrorres: null,
        Casinovivoplayer: null,
        CasinovivoplayerError: null
}
export function playerReducer(state: ProfileState = initialState, action: playerActions.PlayerActions) {
        switch (action.type) {
                case playerActions.RESET_STATE:
                        return {
                                ...state,
                                profile: null,
                                tournamentList: null,
                                stats: null,
                                updatepasswordmodel: null,
                                GameHistory: null,
                                GameHistoryError: null,
                                remotegameshistory: null,
                                remotegameshistoryError: null,
                                UpDateProfileModel: null,
                                UpDateProfileModelError: null,
                                updatepasswordmodelError: null,
                                TopCPPlayersres: null,
                                TopCPPlayersErrorres: null,
                                Casinovivoplayer: null,
                                CasinovivoplayerError: null
                        }
                        break;
                case playerActions.PLAYER_GET_PROFILE:
                        return {
                                ...state,
                                profile: null
                        }
                        break;
                case playerActions.PLAYER_GET_PROFILE_SUCCESS:
                        return {
                                ...state,
                                profile: { ...state, ...action.payload }
                        }
                        break;
                case playerActions.PLAYER_GET_PROFILE_FAIL:
                        return {
                                ...state,
                                profile: null
                        }
                        break;
                case playerActions.PLAYER_UPDATE_PROFILE:
                        return {
                                ...state,
                                UpDateProfileModel: null,
                                UpDateProfileModelError: null
                        }
                        break;
                case playerActions.PLAYER_UPDATE_PROFILE_SUCCESS:
                        return {
                                ...state,
                                UpDateProfileModel: { ...state.UpDateProfileModel, ...action.payload },
                                UpDateProfileModelError: null
                        }
                        break;
                case playerActions.PLAYER_UPDATE_PROFILE_FAIL:
                        return {
                                ...state,
                                UpDateProfileModelError: { ...state.UpDateProfileModelError, ...action.payload },
                                UpDateProfileModel: null
                        }
                        break;
                case playerActions.PLAYER_UPDATE_PASSWORD:
                        return {
                                ...state,
                                updatepasswordmodel: null,
                                updatepasswordmodelError: null

                        }
                        break;
                case playerActions.PLAYER_UPDATE_PASSWORD_SUCCESS:
                        return {
                                ...state,
                                updatepasswordmodel: { ...state.updatepasswordmodel, ...action.payload },
                                updatepasswordmodelError: null
                        }
                        break;
                case playerActions.PLAYER_UPDATE_PASSWORD_FAIL:
                        return {
                                ...state,
                                updatepasswordmodel: null,
                                updatepasswordmodelError: { ...state.updatepasswordmodelError, ...action.payload }
                        }
                        break;
                case playerActions.PLAYER_GET_TOURNAMENTS_LIST:
                        return {
                                ...state,
                                tournamentList: null
                        }
                        break;
                case playerActions.PLAYER_GET_TOURNAMENTS_LIST_SUCCESS:
                        return {
                                ...state,
                                tournamentList: { ...state.tournamentList, ...action.payload }
                        }
                        break;
                case playerActions.PLAYER_GET_TOURNAMENTS_LIST_SUCCESS:
                        return {
                                ...state,
                                tournamentList: null
                        }
                        break;
                case playerActions.PLAYER_GET_STATS:
                        return {
                                ...state,
                                stats: null
                        }
                        break;
                case playerActions.PLAYER_GET_STATS_SUCCESS:
                        return {
                                ...state,
                                stats: { ...state.stats, ...action.payload }
                        }
                        break;
                case playerActions.PLAYER_GET_STATS_FAIL:
                        return {
                                ...state,
                                stats: null
                        }
                        break;
                case playerActions.PLAYER_GET_PLAYERLEVELS:
                        return {
                                ...state
                        }
                        break;
                case playerActions.PLAYER_GET_PLAYERLEVELS_SUCCESS:
                        return {
                                ...state
                        }
                        break;
                case playerActions.PLAYER_GET_PLAYERLEVELS_FAIL:
                        return {
                                ...state
                        }
                        break;
                case playerActions.PLAYER_GET_GAME_HISTORY:
                        return {
                                ...state,
                                GameHistory: null,
                                GameHistoryError: null
                        }
                        break;
                case playerActions.PLAYER_GET_GAME_HISTORY_SUCCESS:
                        return {
                                ...state,
                                GameHistory: { ...state.GameHistory, ...action.payload },
                                GameHistoryError: null
                        }
                        break;
                case playerActions.PLAYER_GET_GAME_HISTORY_FAIL:
                        return {
                                ...state,
                                GameHistory: null,
                                GameHistoryError: { ...state.GameHistoryError, ...action.payload },
                        }
                        break;
                case playerActions.PLAYER_GET_REMOTE_GAME_HISTORY:
                        return {
                                ...state,
                                remotegameshistory: null,
                                remotegameshistoryError: null
                        }
                        break;
                case playerActions.PLAYER_GET_REMOTE_GAME_HISTORY_SUCCESS:
                        return {
                                ...state,
                                remotegameshistory: { ...state.remotegameshistory, ...action.payload },
                                remotegameshistoryError: null
                        }
                        break;
                case playerActions.PLAYER_GET_REMOTE_GAME_HISTORY_FAIL:
                        return {
                                ...state,
                                remotegameshistory: null,
                                remotegameshistoryError: { ...state.remotegameshistoryError, ...action.payload },
                        }
                        break;
                case playerActions.PLAYER_GET_TOPCPPLAYERS:
                        return {
                                ...state,
                                TopCPPlayersres: null,
                                TopCPPlayersErrorres: null
                        }
                        break;
                case playerActions.PLAYER_GET_TOPCPPLAYERS_SUCCESS:
                        return {
                                ...state,
                                TopCPPlayersres: { ...state.TopCPPlayersres, ...action.payload },
                                TopCPPlayersErrorres: null
                        }
                        break;
                case playerActions.PLAYER_GET_TOPCPPLAYERS_FAIL:
                        return {
                                ...state,
                                TopCPPlayersres: null,
                                TopCPPlayersErrorres: { ...state.TopCPPlayersErrorres, ...action.payload },
                        }
                        break;
                case playerActions.PLAYER_GET_CASINOPLAYVIVO:
                        return {
                                ...state,
                                Casinovivoplayer: null,
                                CasinovivoplayerError: null
                        }
                        break;
                case playerActions.PLAYER_GET_CASINOPLAYVIVO_SUCCESS:
                        return {
                                ...state,
                                Casinovivoplayer: { ...state.Casinovivoplayer, ...action.payload },
                                CasinovivoplayerError: null
                        }
                        break;
                case playerActions.PLAYER_GET_CASINOPLAYVIVO_FAIL:
                        return {
                                ...state,
                                Casinovivoplayer: null,
                                CasinovivoplayerError: { ...state.CasinovivoplayerError, ...action.payload },
                        }
                        break;
                default:
                        return state;
                        break;
        }
}