export const environment = {
  production: true,
  // window: window.location.host,
  skinId: 'usdmaster',
   slotsiamgepath: 'http://baghabetp4m.czargaming.com/i/skin/habanero',
  //slotsiamgepath: 'http://casino.playvivo.com/i/skin/habanero',
  
  // skinId: 'playvivo-dev',
  // slotsiamgepath: 'http://africa-test.czargaming.com/i/skin/habanero',
  // skinId: 'redsonbet-dev',
  // slotsiamgepath: 'http://africa-test.czargaming.com/i/skin/habanero',
  
  sbBook: {
    //sbBaseUrl:'http://zbet-dev.czargaming.com/sbapi',
    sbBaseUrl: '/sbapi',
    //sbBaseUrl:'',
    cupancheck: '/bets/coupon',
    emial: '/sendmail/email',
    betprofile: '/bets/betprofile',
    history: '/bets/getbets',
    result: '/sports/result',
    sblogin: '/auth/remote',
    getBets: '/bets/getbets',
    betSlip: '/bets/betslip',
    matchNO: '/sports/matches/no',
    bonus: '/bets/bonus',
    sportMenu : "pms",
    sportName : "pms/sport",
    sportOddsMatchId : "pms/odds",
    todayMatches : "pms/timescope",
    toptenleagues : "pms/top10leagues",
    // generateOTP: '/api/player/generateOTP',
    printleagues:'/pms/print',
      // printodds:'/pms/print/odds',
    printodds: '/pms/print1/odds/pdfreport',
    //spotsDate: '/sports/prematch',
    //matchNO: '/sports/matches/no',
   // league: '/sports/sportleagues',
    //markets: '/sports/markets/',
    //country:'/sports/country',
    //matchesdata:'/sports/country/matches',
    //leaguebasedmatchdata:'/sports/leagues',
    anonymousbet:'/bets/anonymousbet'

  },
  appApi: {
    //baseUrl:"http://zbet-dev.czargaming.com",
    //baseUrl:"http://africa-dev.czargaming.com/api",
    baseUrl: '',
    livedealer: "/api/Vivo/freshDeck/handler",
    ezugi: "/rest/ezugi/session",
    vivourl : "api/Vivo/handler",
    lotteryUrl: "/rest/fawk/handler/",
    pragmatictoken: "/rest/pp/ppToken",

    cockfight: '/rest/sabong/handler',
    //emial: '/rest/bet/email/ticket',
    heblounch: 'rest/habanero/session',
    casino_blackajack:'/api/player/launchCasinoGame?name=CASINO_BLACKJACK_NGN&real=1&walletCCode=NGN',
    casino_baccarat:'/api/player/launchCasinoGame?name=CASINO_BACCARAT_NGN&real=1&walletCCode=NGN',
    casino_europeanroulette:'/api/player/launchCasinoGame?name=CASINO_EUROPEANROULETTE_NGN&real=1&walletCCode=NGN',
    casino_Oasispoker:'/api/player/launchCasinoGame?name=CASINO_OASISPOKER_NGN&real=1&walletCCode=NGN',
    goldenrace: '/rest/xpress/handler',
    meight: 'rest/m8/handler',
    login: '/api/player/login',
    logout: '/api/player/logout',
    register: '/api/player/register',
    getCountries: '/api/player/getCountries',
    fotgotPassword: "/api/player/forgotPassword",
    resetPassword: "/api/player/resetPassword",
    //generateCaptcha: '/api/player/generateCaptcha',
    player: {
      getProfile: "/api/player/getProfile",
      updateProfile: "/api/player/updateProfile",
      updatePassword: "/api/player/updatePassword",
      verifyAccount: "/api/player/verifyAccount",
      resendToken: "/api/player/resendToken",
      getLaunchURLS: "/api/player/getLaunchURLS",
      tournamentsList: "/api/player/getPokerTournamentsList",
      playerStats: "/api/player/getStats",
      playerCurrencies: "/api/player/getCurrencies",
      games: "/api/player/getGames",
      playerLevels: "/api/cp/playerLevels",
      topcpplayer:"/api/player/getTopCPPlayers",
      casinoviovo:"/api/vivo/handler"
    },
    cashier: {
      slideplay: '/api/cashier/deposit',
      cashoutotp:'/api/cashier/generateCashOutOTP',
      balance: '/api/cashier/balance',
      resetPlayMoneyBalance: '/api/cashier/resetPlayMoneyBalance',
      getPaymentSystems: '/api/cashier/getPaymentSystems?pgid=',
      depositSystems: '/api/cashier/depositSystems',
      withdrawSystems: '/api/cashier/getCashOutInfo',
      withDrawCashout: '/api/cashier/cashout',
      deposit: '/api/cashier/buy',
      getOpenWithdrawRequests: '/api/cashier/getOpenWithdrawRequests',
      cancelWithdrawRequest: '/api/cashier/cancelWithdrawRequest',
      withdraw: '/api/cashier/withdraw',
      addBankAccount: '/api/cashier/addBankAccount',
      getBankAccounts: '/api/cashier/getBankAccounts',
      deleteBankAccounts: '/api/cashier/deleteBankAccounts',
    },
    history: {
      transaction: '/api/history/transaction',
      cashout:'/api/cashier/getOpenWithdrawRequests',
      //game: "/api/history/game",
      game: "/api/history/casinoGames",
      remotegame:"/api/history/remoteGames"
    }
  },
};
